import Images from '@Theme/Images'
import Styles from './style.module.scss'

export default function Rotation({
  width = '24px',
  type = 'white',
  className=''
}) {

  return (
    <img style={{ width }} className={`${Styles.loader} ${className}`} src={type === 'blue' ? Images.LOADER : Images.LOADER_V2} alt='' />
  )
}