import { API } from "configs";

export function uploadTemplate(file, year, month, pksID) {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const response = await API.post(`/payroll/upload/${pksID}`, formData, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: { year, month }
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}