import React from "react";
import Styles from "./style.module.scss";
import Avatar from "@Assets/Images/ava-default.png";
export default function ProfileEmployee({
  name = "Placeholder",
  idEmployee = "123456789",
  image = Avatar,
  onClick = () => {},
}) {
  return (
    <div className={Styles.wrapperProfileEmployee} onClick={onClick}>
      <img src={image} alt="profile" />
      <div className={Styles.text}>
        <h2>{name}</h2>
        <span>{idEmployee}</span>
      </div>
    </div>
  );
}
