import Styles from './style.module.scss'

export default function MonthButton({
  isActive,
  children,
  onClick=()=> {}
}) {

  return (
    <button onClick={onClick} className={`${Styles.month} ${Styles[isActive ? 'active' : '']}`}>
      {children}
    </button>
  )
}