import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import Table from "@Molecule/Table";
import ProfileEmployee from "@Atom/ProfileEmployee";
import TabHeader from "@Atom/TabHeader";
import { ModalContext } from "Context/ModalContetx";
import Toast from "@Atom/Toast";
import { getDetailChart } from "@Services/organization/getDetailChart";
import { fileBaseUrl } from "configs";
import { deletMember } from "@Services/organization/deleteMember";
import { useNavigate, useLocation } from "react-router-dom";
import InputField from "@Atom/InputField";
import { GraphValue } from "Context/GraphContext";
import Button from "@Atom/Button";
import { moverMember } from "@Services/organization/moveMember";
import AddMembers from "../AddMembers";
import Lottie from "lottie-react";
import LoadingJSON from "@Assets/json/loading.json";

export default function DetailChart({ isOpen, id }) {
  const { setIsOpenModalDetail } = useContext(ModalContext);

  const { nodes } = useContext(GraphValue);
  const toListDropdown = nodes.filter((el) => el.isSpv !== true);
  const listDropdown = toListDropdown.map((c) => c.data.department);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([{}, {}]);
  const [isChecked, setIsChecked] = useState([]);
  const [selectChartName, setSelectChartName] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isHeight, setIsHeight] = useState(false);
  const [getNewChartId, setGetNewChartId] = useState(null);
  const [isOpenAddMembers, setIsOpenAddMembers] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);

  const currentChartId = data?.data?.id;
  const fetchDetailChart = useCallback(async () => {
    try {
      const response = await getDetailChart(id, limit, page);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [id, limit, page]);

  useEffect(() => {
    if (isOpen) {
      fetchDetailChart();
    }
  }, [fetchDetailChart, isOpen]);

  const [isOpenToast, setIsOpenToast] = useState(false);
  const handleCopyClipBoard = (email) => {
    navigator.clipboard.writeText(email);
    setIsOpenToast(true);
  };

  setTimeout(() => {
    isOpenToast && setIsOpenToast(false);
  }, 1000);

  const [isEdit, setIsEdit] = useState(false);

  const [isOpenMove, setIsOpenMove] = useState(false);

  const [successDelete, setSuccessDelete] = useState(false);
  const [isErrorDelete, setIsErrorDelete] = useState(null);
  const [isSuccessMoveMember, setIsSuccessMoveMember] = useState(false);
  const [isErrorMoveMember, setIsErrorMoveMember] = useState(null);

  setTimeout(() => {
    if (successDelete || isErrorDelete) {
      setSuccessDelete(false);
      setIsErrorDelete(false);
    }
  }, 5000);

  const handleDeleteMember = useCallback(async () => {
    const idMembersDelete = new URLSearchParams();
    isChecked?.forEach((m, i) => {
      idMembersDelete.append(`members[${i}]`, m.id);
    });
    setIsLoadingPost(true);
    try {
      const res = await deletMember(id, idMembersDelete);
      if (res.status === 200) {
        setSuccessDelete(true);
        navigate(pathname, { replace: true });
        fetchDetailChart();
        setIsLoadingPost(false);
      }
    } catch (error) {
      console.log(error);
      setIsErrorDelete(true);
      setIsLoadingPost(false);
    }
  }, [id, isChecked, navigate, pathname, fetchDetailChart]);

  // setData(
  //   data.data.users.filter((u)=>u.idNumber !== isEdit)
  // )

  const handleMoveMember = useCallback(async () => {
    const dataToSend = new URLSearchParams();
    dataToSend.append("oldChartID", currentChartId);
    dataToSend.append("newChartID", +getNewChartId);
    isChecked.forEach((m, index) => {
      dataToSend.append(`members[${index}]`, m.id);
    });
    setIsLoadingPost(true);
    setIsOpenMove(false);
    try {
      const res = await moverMember(dataToSend);
      if (res.status === 201) {
        setIsSuccessMoveMember(true);
        navigate(pathname, { replace: true });
        fetchDetailChart();
        setIsLoadingPost(false);
      }
    } catch (error) {
      console.log(error);
      setIsErrorMoveMember(true);
      setIsLoadingPost(false);
    }
  }, [
    currentChartId,
    fetchDetailChart,
    getNewChartId,
    isChecked,
    navigate,
    pathname,
  ]);

  useEffect(() => {
    if (selectChartName) {
      const getNewChartId = nodes.filter(
        (ol) => ol.data.department === selectChartName
      );
      setGetNewChartId(getNewChartId[0].id);
    }
  }, [nodes, selectChartName]);

  const [chartTopName, setChartTopName] = useState("");

  useEffect(() => {
    const getTopChart = nodes.filter((node) => node.id === data?.data?.chartTo);
    setChartTopName(getTopChart[0]?.data?.department);
  }, [nodes, data]);

  useEffect(() => {
    setTimeout(() => {
      if (isSuccessMoveMember || isErrorMoveMember) {
        setIsErrorMoveMember(false);
        setIsSuccessMoveMember(false);
      }
    }, 5000);
  }, [isErrorMoveMember, isSuccessMoveMember]);

  const template = useMemo(() => {
    return {
      data: data?.data?.users?.map((da) => ({
        id: da?.idNumber,
        name: da?.name || "-",
        email: da?.email,
        position: da?.contracts?.position || "-",
        profilePicture: da?.imageURL || "https://placehold.co/24x24",
        telephone: da?.phoneNumber,
      })),
      totalData: data?.total,
      columns: [
        {
          name: "name",
          title: <TabHeader text="Nama" />,
          renderData: (row) => (
            <ProfileEmployee
              name={row?.name}
              idEmployee={row.position}
              image={fileBaseUrl + row.profilePicture}
            />
          ),
        },
        {
          name: "email",
          title: <TabHeader text="Email" />,
          renderData: (row) => (
            <div className={Styles.emailChartDetail}>
              <Icon
                icon={"copy"}
                color={"#2E3192"}
                size={"14"}
                className={Styles.clipBoard}
                onClick={() => handleCopyClipBoard(row.email)}
              />
              <span>{row.email}</span>
            </div>
          ),
        },
        {
          name: "telephone",
          title: <TabHeader text="No Hp" />,
        },
        {
          name: "",
          title: "",
          renderData: () => (
            <div className={Styles.ctaBtn}>
              <Icon
                icon={"arrow-2"}
                size={"16"}
                color={"#2E3192"}
                className={Styles.icon}
                onClick={() =>
                  isChecked.length === 0 ? () => {} : setIsOpenMove(!isOpenMove)
                }
              />
              <Icon
                icon={"trash"}
                size={"16"}
                color={"#D42701"}
                className={Styles.icon}
                onClick={() =>
                  isChecked.length === 0 ? () => {} : handleDeleteMember()
                }
              />
            </div>
          ),
        },
      ],
    };
  }, [
    data?.data?.users,
    data?.total,
    handleDeleteMember,
    isChecked.length,
    isOpenMove,
  ]);

  return (
    <>
      <AddMembers
        isOpenAdd={isOpenAddMembers && !isOpen}
        onCloseAdd={() => {
          setIsOpenAddMembers(!isOpenAddMembers);
          setIsOpenModalDetail(true);
        }}
      />

      <Modal isOpen={isOpenMove}>
        <div
          className={Styles.modalMoveMember}
          style={{ height: isHeight ? "360px" : "auto" }}
        >
          <div className={Styles.header}>
            <div>
              <Icon
                icon={"arrow-chevron-left"}
                color={"#3E4856"}
                size={"16"}
                onClick={() => {
                  setIsOpenMove(false);
                }}
              />
              <h3>Pindahkan Member ke Chart Lain</h3>
            </div>
            <Icon
              icon={"close-circle"}
              color={"#3E4856"}
              size={"12"}
              onClick={() => {
                setIsOpenMove(false);
              }}
              className={Styles.close}
            />
          </div>
          <div>
            <InputField
              isLabel
              textLabel="Pilih Nama Chart"
              placeholder="Chart belum dipilih"
              isDropdown
              dataMapDropdown={listDropdown}
              setValue={(e) => setSelectChartName(e)}
              value={selectChartName}
              onClick={() => setIsHeight(!isHeight)}
            />
          </div>
          <div className={Styles.btnSave}>
            <Button
              text="Simpan"
              isDisabled={!selectChartName}
              onClick={() => handleMoveMember()}
            />
          </div>
        </div>
      </Modal>

      <Modal isOpen={isOpenMove ? !isOpen : isOpen}>
        {isLoadingPost ? (
          <div className={Styles.viewLoadingCreate}>
            <Lottie
              animationData={LoadingJSON}
              loop
              className={Styles.loadingElement}
              alt="loading"
            />
          </div>
        ) : (
          <>
            <Toast
              isopen={isSuccessMoveMember || isErrorMoveMember}
              color={isSuccessMoveMember ? "green" : "red"}
              text={
                isSuccessMoveMember
                  ? "Member berhasil di pindahkan"
                  : "Gagal memindahkan member"
              }
            />

            <Toast
              isopen={isOpenToast}
              color="green"
              text="Teks Berhasil Di Salin"
            />
            <Toast
              isopen={successDelete || isErrorDelete}
              color={successDelete ? "green" : "red"}
              text={
                successDelete
                  ? "Member berhasil di hapus"
                  : "Member gagal di hapus"
              }
              onClose={() => {
                setSuccessDelete(false);
                setIsErrorDelete(false);
              }}
            />
            <div className={Styles.containerDetailChart}>
              <div className={Styles.wrapHeader}>
                <div className={Styles.header}>
                  <h3>Detail</h3>
                  <div className={Styles.closeAndEdit}>
                    <div className={Styles.edit}>
                      <Icon
                        onClick={() => setIsEdit(!isEdit)}
                        icon={"edit-2"}
                        color={"#2E3192"}
                        size={"14"}
                      />
                      <span onClick={() => setIsEdit(!isEdit)}>Edit Chart</span>
                      {isEdit && (
                        <div style={{ paddingLeft: "8px" }}>
                          <Button
                            text="Tambah Member"
                            isLeftIcon
                            isAddButton
                            style={{
                              background: "white",
                              padding: "4px 8px",
                            }}
                            colorIcon={"#2E3192"}
                            onClick={() => {
                              setIsOpenAddMembers(true);
                              setIsOpenModalDetail(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <Icon
                      icon={"close-circle"}
                      color={"#2E3192"}
                      size={"12"}
                      className={Styles.close}
                      onClick={() => setIsOpenModalDetail(!isOpen)}
                    />
                  </div>
                </div>
                <div className={Styles.report}>
                  <div>
                    <span>Chart</span>
                    <strong>{data?.data?.chartName}</strong>
                  </div>
                  <div>
                    <span>Reporting To</span>
                    <strong>{chartTopName}</strong>
                  </div>
                </div>
              </div>

              <Table
                className={Styles.table}
                isDetailChart
                data={template.data}
                columns={template.columns}
                page={page}
                limit={limit}
                totalData={template.totalData || 0}
                setLimit={setLimit}
                setPage={setPage}
                totalPage={Math.ceil(template.totalData / limit)}
                withPagination={isEdit}
                withNumbering={!isEdit}
                widthNumber="10px"
                withCheckbox={isEdit}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
