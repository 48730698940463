import { API } from "configs";

export function getDetailChart(id, limit = 10, page = 1) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/orgchart/get-chart/${id}?limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
