import { API } from "configs";

export function addMember(chartId, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.post(`/orgmember/${chartId}`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
