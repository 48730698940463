import Icon from "@Atom/Icon";
import PayrollSectionCard from "@Atom/PayrollSectionCard";
import LoadingAnimationPage from "@Molecule/LoadingAnimationPage";
import Styles from './style.module.scss';

export default function BPJSSection({
  bpjsData = [],
  isLoadingScheme
}) {
  const columns = [
    {
      name: 'parameter',
      label: 'Parameter'
    },
    {
      name: 'description',
      label: 'Description'
    },
    {
      name: 'rate',
      label: 'Rate',
      align: 'right'
    },
    {
      name: 'payer',
      label: 'Payer'
    },
    {
      name: 'information',
      label: 'Information'
    },
    {
      name: 'multiplier',
      label: 'Multiplier'
    },
    {
      name: 'income',
      label: '(+) Income'
    },
    {
      name: 'outcome',
      label: '(-) Income'
    },

    {
      name: 'thp',
      label: '(-) THP'
    }
  ]


  return (
    <PayrollSectionCard
      title='BPJS'
    >
      <SectionTable
        data={bpjsData}
        columns={columns}
        isLoading={isLoadingScheme}
      />
    </PayrollSectionCard>
  )
}

export const SectionTable = ({
  data,
  columns,
  withBorderBottom = false,
  withDelete,
  onDelete = () => { },
  isLoading
}) => {

  return (
    <div className={Styles.tableWrapper}>
      <table className={`${Styles.table} ${Styles[withBorderBottom ? 'bordered' : '']}`}>
        <thead>
          <tr>
            {columns?.map((column, i) => (
              <th key={i} className={Styles[column?.align || '']}>
                {column?.label}
              </th>
            ))}
            {
              withDelete
              &&
              <th></th>
            }
          </tr>
        </thead>
        <tbody>
          {
            !isLoading
              ?
              data?.map((row, i) => (
                <tr key={i}>
                  {
                    columns?.map((column, iC) => (
                      <td style={{ backgroundColor: row?.backgroundColor || '#fff', textAlign: column?.align || 'left' }} key={iC}>{row[column?.name || '']}</td>
                    ))
                  }
                  {
                    withDelete
                    &&
                    <td>
                      <div className={Styles.deleteWrapper}>
                        <button className={Styles.deleteButton} disabled={row?.preventDelete} onClick={() => onDelete(row)}>
                          <Icon icon={'trash'} size={20} />
                        </button>

                      </div>
                    </td>
                  }
                </tr>
              ))
              :
              <tr>
                <td colSpan={9}>
                  <LoadingAnimationPage height="250px" />
                </td>
              </tr>
          }
        </tbody>




      </table>
    </div>

  )
}