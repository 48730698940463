import { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import useOutsideClick from '@Hooks/useOutsideClick'
import Icon from '@Atom/Icon'
import InputTitle from '@Atom/InputTitle'
import Rotation from '@Atom/Rotation'

export default function SelectDropdown({
  value,
  setValue = () => { },
  options,
  width = 'auto',
  height = '44px',
  placeholder = 'Select',
  title = '',
  required = false,
  textAlign = 'left',
  placeholderColor = '',
  isLoading = false,
}) {
  const [expand, setExpand] = useState(false)
  const ref = useRef()
  const outsideClick = useOutsideClick(ref)

  useEffect(() => {
    if (expand && outsideClick) {
      setExpand(false)
    }
  }, [expand, outsideClick])

  return (
    <div className={Styles.wrapper}>
      {
        !!title
        &&
        <InputTitle
          title={title}
          required={required}
        />
      }
      <div
        ref={ref}
        className={`${Styles.container} ${Styles[expand ? 'expand' : '']}`}
        onClick={() => setExpand(!expand)}
        style={{
          width,
          height,
          textAlign
        }}
      >
        <span style={{ color: !value && placeholderColor ? placeholderColor : '' }} className={Styles[!value ? 'placeholder' : '']}>{value || placeholder}</span>
        {
          isLoading
          &&
          <Rotation type='blue' width='18px' />
        }
        <Icon icon={'arrow-chevron-left'} size={16} className={Styles[expand ? 'up' : 'down']} color={'#BBC3CE'} />
        {
          expand
          &&
          <div className={Styles.options}>
            {
              !isLoading
                ?
                (
                  options?.length
                    ?
                    options?.map((option, i) => (
                      <div
                        key={i} className={`${Styles.option} ${Styles[value === option ? 'active' : '']}`}
                        onClick={() => {
                          setValue(option)
                          setExpand(false)
                        }}
                      >
                        {option}
                      </div>
                    ))
                    :
                    <div className={Styles.optionsLoader}>
                      No Option
                    </div>
                )

                :
                <div className={Styles.optionsLoader}>
                  <Rotation
                    type='blue'
                    size='36px'
                  />
                </div>
            }
          </div>
        }
      </div>
    </div>
  )
}