import { API } from "configs";

export function deleteFileEmployee(fileId, idNumber = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.delete(
        `/file/delete-file/${fileId}?idNumber=${idNumber}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
