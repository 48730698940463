import { View, Image } from "@react-pdf/renderer";
import CustomText from "./text";
import PAYDAY from "@Assets/Images/payday.png";

const TermOfPayment = ({ top, ...props }) => {
  return (
    <View
      style={{
        backgroundColor: "#F3F6FF",
        borderRadius: 8,
        paddingTop: 10 / 2,
        paddingBottom: 10 / 2,
        paddingLeft: 16 / 2,
        paddingRight: 16 / 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 16 / 2,
        }}
      >
        <Image source={PAYDAY} style={{ height: 32 / 2, width: 32 / 2 }} />
        <CustomText size={16 / 2} color="#404040">
          Term Of Payment
        </CustomText>
      </View>
      <View>
        <CustomText size={20 / 2} color="#0a0a0a" type="bold">
          {`TOP ${top || 0} ${top > 1 ? "Days" : "Day"}`}
        </CustomText>
      </View>
    </View>
  );
};

export default TermOfPayment;
