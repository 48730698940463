import ErrorBubble from '@Atom/ErrorBubble'
import Icon from '@Atom/Icon'
import Modal from '@Atom/Modal'
import MultiSelectDropdown from '@Atom/MultiSelectDropdown'
import Rotation from '@Atom/Rotation'
import SelectDropdown from '@Atom/SelectDropdown'
import { addComponentSalary } from '@Services/payroll/addComponentSalary'
import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'

export default function AddParameterModal({
  options = [],
  show,
  onClose,
  onSuccess,
  pksID
}) {
  const [paramsNames, setParamsNames] = useState([])

  const [params, setParams] = useState([])

  const [error, setError] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const defaultParams = useMemo(() => {
    const temp = options?.map(obj => {
      const found = params?.find(p => p?.id === obj?.id)
      return found || obj
    })

    return temp.filter((el) => {
      return paramsNames.some((f) => {
        return f === el.parameter
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, paramsNames])

  useEffect(() => {
    setParams(defaultParams)
  }, [defaultParams])

  const handleSave = async () => {
    try {
      setIsSubmitting(true)
      await addComponentSalary(params?.map(obj => {
        return {
          prm_pks: pksID,
          prm_component: obj?.id,
          component_function: obj?.description,
          component_info: obj?.information
        }
      }))
      setIsSubmitting(false)
      onSuccess()
      onClose()

    } catch (err) {
      setIsSubmitting(false)
      setError(err?.response?.data?.error || 'Something went wrong')
    }
  }

  const alertFound = useMemo(() => {

    const found = params?.find(obj => !obj?.information)

    if (!params?.length) {
      return 'Pilih komponen penambah terlebih dahulu'
    } else if (found) {
      return `Pilih informasi parameter ${found?.parameter} terlebih dahulu`
    } else {
      return ''
    }
  }, [params])


  useEffect(() => {
    if (!alertFound) {
      setError('')
    }
  }, [alertFound])



  return (
    <Modal isOpen={show} onClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Tambah Parameter Pendapatan/Tunjangan</h3>
          <button onClick={onClose}>
            <Icon icon={'close-circle'} size={10} />
          </button>
        </div>
        <div className={Styles.content}>
          <MultiSelectDropdown
            title='Pilih Parameter'
            value={paramsNames}
            setValue={setParamsNames}
            options={options?.map(obj => obj?.parameter)}
            maxOptionsHeight='250px'
          />
          {
            params?.map((param, i) => (
              <div key={i} className={Styles.param}>
                <div className={Styles.pHeader}>
                  <span>{param?.parameter}</span>
                  <button
                    onClick={() => setParamsNames(paramsNames?.filter(obj => obj !== param?.parameter))}
                  ><Icon icon={'trash'} size={20} /></button>
                </div>
                <div className={Styles.pCard}>
                  <div className={Styles.descList}>
                    <span>Description</span>
                    <span>:</span>
                    <span>{param?.description}</span>
                  </div>
                  <div className={Styles.descList}>
                    <span>Informasi Parameter</span>
                    <span>:</span>
                    <SelectDropdown
                      placeholder='Belum dipilih'
                      textAlign='right'
                      placeholderColor='#3E4856'
                      options={param?.description?.toLowerCase() === 'pengurang'
                        ?
                        [
                          'pengurang bruto & thp',
                          'hanya pengurang thp'
                        ]
                        :
                        [
                          'penambah bruto dan thp',
                          'hanya penambah thp',
                          'hanya penambah bruto'
                        ]
                      }
                      height='24px'
                      value={param?.information}
                      setValue={(newVal) => setParams(params?.map(obj => {
                        return {
                          ...obj,
                          information: obj?.id === param?.id ? newVal : obj?.information
                        }
                      }))}
                    />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        {
          error
          &&
          <ErrorBubble
            errorText={error}
            noTip
          />
        }
        <div className={Styles.actions}>
          <button onClick={onClose}>Kembali</button>
          <button onClick={() => {
            if (alertFound) {
              setError(alertFound)
            } else {
              handleSave()
            }
          }}
            disabled={isSubmitting}
          >
            {!isSubmitting
              ?
              <span>Simpan</span>
              :
              <Rotation type='white' width='18px' />
            }
          </button>
        </div>
      </div>
    </Modal>
  )
}