import Skeleton, { CircleSkeleton } from "@Atom/Skeleton";
import React from "react";
import Styles from "./style.module.scss";
export default function SkeletonPersonalData() {
  return (
    <div className={Styles.containerDetailEmployee}>
      <div className={Styles.wrapperDetailEmployee}>
        <div className={Styles.nameDetail} style={{ width: "10%" }}>
          <Skeleton />
        </div>
        <div className={Styles.mainContent}>
          <section className={Styles.sideMenu}>
            <div className={Styles.profileName}>
              <CircleSkeleton height="77px" />
            </div>
            <div className={Styles.leader}>
              <Skeleton />
              <div className={Styles.nameAndImage}>
                <div>
                  <CircleSkeleton height="32px" />
                </div>
                <Skeleton height="20px" />
              </div>
            </div>
            <div className={Styles.lineSeparator}></div>
            {[1, 2, 3, 4, 5]?.map((_, idx) => (
              <div className={Styles.menus} key={idx}>
                <Skeleton />
              </div>
            ))}
          </section>

          <div className={Styles.divider}></div>
          <div className={Styles.wrapperSkeletonpersonalData}>
            <div className={Styles.between}>
              <div className={Styles.tabs}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <div is-btn="true">
                <Skeleton />
              </div>
            </div>
            <div className={Styles.listData}>
              <div className={Styles.header}>
                <Skeleton />
              </div>
              {Array.from({ length: 10 }).map((_, idx) => (
                <div className={Styles.flexView} key={idx}>
                  <Skeleton />
                  <Skeleton />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
