import Skeleton from "@Atom/Skeleton";
import React, { Fragment } from "react";
import Styles from "./style.module.scss";
export default function SkeletonTable() {
  return (
    <div className={Styles.wrapperSkeletonTable}>
      <div className={Styles.header}>
        <Skeleton height="40px" />
      </div>
      {Array.from({ length: 10 }).map((_, idx) => (
        <Fragment key={idx}>
          <Skeleton />
        </Fragment>
      ))}
      <div className={Styles.header}>
        <Skeleton height="40px" />
      </div>
    </div>
  );
}
