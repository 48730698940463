import Styles from "./style.module.scss";

export default function Skeleton({ height = "20px", width='100%' }) {
  return (
    <div
      className={Styles.wrapperLoadSkeleton}
      style={{ height, width }}
    ></div>
  );
}
export const CircleSkeleton = ({ height = "20px" }) => {
  return (
    <div className={Styles.circleSkeleton} style={{ height: height }}></div>
  );
};

export const BoxSkeleton = ({ height = "40px" }) => {
  return <div className={Styles.boxSkeleton} style={{ height: height }}></div>;
};
