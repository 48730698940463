import React, { useContext, useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import InputField from "@Atom/InputField";
import Button from "@Atom/Button";
import { ModalContext } from "Context/ModalContetx";
import { getDataByNames } from "@Services/employee/getAllEmployee";
import { makeRandomNumber } from "helpers/makeRandomNumber";
import Images from "@Theme/Images";
export default function AddFieldChart({
  id,
  isOpen = false,
  handleSubmit = () => {},
}) {
  const { isOpenField, setIsOpenField } = useContext(ModalContext);
  const [isOpenColorPicker, setIsOpenColorPicker] = useState(true);
  const [chartName, setChartName] = useState("");

  const [inputDataMembers, setInputDataMembers] = useState([
    {
      id: makeRandomNumber(6),
      name: "",
      otherData: {
        position: "",
        imageURL: "",
      },
    },
  ]);

  const handleAddInput = () => {
    fetchListAutoComplete();
    setInputDataMembers([
      ...inputDataMembers,
      { id: makeRandomNumber(6), name: "" },
    ]);
  };

  const handleDeleteInput = (id) => {
    setInputDataMembers(inputDataMembers.filter((member) => member.id !== id));
  };

  const [names, setNames] = useState([
    {
      id: "",
      name: "",
      imageURL: "",
      position: "",
    },
  ]);

  const fetchListAutoComplete = async () => {
    try {
      const response = await getDataByNames("a");
      if (response.status === 200) {
        const getNames = response.data.data.map((item) => ({
          id: item.idNumber,
          name: item.name,
          imageURL:
            item.imageURL === "" ||
            item.imageURL === "-" ||
            item.imageURL === "_"
              ? Images.AVA_DEFAULT
              : item.imageURL,
          position: item.contracts[0]?.position || "-",
        }));

        setNames(getNames);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchListAutoComplete();
  }, []);

  const debounceRef = useRef({});

  const handleChangeInput = async (id, value) => {
    setIsChoose(false);
    setInputDataMembers((prev) =>
      prev.map((member) =>
        member.id === id ? { ...member, name: value } : member
      )
    );

    if (!debounceRef.current) debounceRef.current = {};
    clearTimeout(debounceRef.current[id]);
    debounceRef.current[id] = setTimeout(async () => {
      try {
        const response = await getDataByNames(value);
        if (response.status === 200) {
          const getNames = response.data.data.map((item) => ({
            id: item.idNumber,
            name: item.name,
            imageURL:
              item.imageURL === "" ||
              item.imageURL === "-" ||
              item.imageURL === "_"
                ? Images.AVA_DEFAULT
                : item.imageURL,
            position: item.contracts[0]?.position || "-",
          }));

          setNames(getNames);
        }
      } catch (error) {
        console.log(error);
      }
    }, 500);
  };

  const onClose = () => {
    setIsOpenField(!isOpenField);
  };

  const reffPicker = useRef(null);
  const [colorPicker, setColorPicker] = useState("#2E3192");
  const [isOpenDropdown, setIsOpenDropdown] = useState(null);
  const handleOpenDropdown = (id) => {
    if (isOpenDropdown === id) {
      setIsOpenDropdown(null);
    } else {
      setIsOpenDropdown(id);
    }
  };

  const handleDataToSend = () => {
    const filteredMembers = inputDataMembers.filter((el) => el.name !== "");
    const sendData = filteredMembers.map((member) => ({
      id: member.otherData.id,
      name: member.name,
      imageURL: `dummy${member.otherData.imageURL}`, // edit if an images already
      position: member.otherData.position,
    }));
    handleSubmit(chartName, colorPicker, sendData);

    setTimeout(() => {
      setInputDataMembers((prev) =>
        prev.map((el) => ({
          ...el,
          name: "",
          id: makeRandomNumber(6),
          otherData: { position: "", imageURL: "" },
        }))
      );
      setChartName("");
    }, 500);
  };

  const [isChoose, setIsChoose] = useState(false);
  const handleClickName = async (id, value) => {
    setIsChoose(true);
    setInputDataMembers((prev) =>
      prev.map((member) =>
        member.id === id
          ? { ...member, name: value.name, otherData: value }
          : member
      )
    );
  };
  return (
    <Modal isOpen={isOpen ? isOpen : isOpenField === id}>
      <div
        className={Styles.containerAddField}
        style={{ maxHeight: "90%", overflowY: "auto" }}
      >
        <div className={Styles.header}>
          <span>
            Tambah <span>Chart</span>
          </span>
          <Icon
            icon={"close-circle"}
            className={Styles.iconHead}
            size={"12"}
            onClick={onClose}
          />
        </div>
        <div className={Styles.inputField}>
          <div className={Styles.input}>
            <InputField
              isLabel
              textLabel="Nama Chart"
              isRequired
              placeholder="Masukkan nama divisi/posisi"
              setValue={(e) => setChartName(e)}
              value={chartName}
            />
            <div
              className={Styles.colorPicker}
              style={{ position: "relative" }}
            >
              <div
                className={Styles.wrapPicker}
                style={{
                  outlineColor: colorPicker,
                  border: colorPicker === "#ffffff" ? "1px solid #BBC3CE" : "",
                }}
              >
                <div
                  className={Styles.picker}
                  onClick={() => {
                    setIsOpenColorPicker(!isOpenColorPicker);
                    reffPicker.current?.click();
                  }}
                  style={{ background: colorPicker }}
                />
              </div>
              <input
                type="color"
                ref={isOpenColorPicker ? reffPicker : null}
                defaultValue={"#2E3192"}
                style={{
                  position: "absolute",
                  zIndex: "-1",
                  opacity: "0",
                  top: "1rem",
                }}
                onChange={(e) => setColorPicker(e.target.value)}
              />
              <Icon
                icon={"arrow-down"}
                className={Styles.chevronPicker}
                color={"black"}
                size={20}
                is-open={isOpenColorPicker ? "false" : "true"}
              />
            </div>
          </div>

          {inputDataMembers.map((el, idx) => (
            <div className={Styles.fieldMemberOther} key={el.id}>
              <InputField
                placeholder="Masukkan nama karyawan"
                value={el.name}
                setValue={(e) => handleChangeInput(el.id, e)}
                isLabel={idx === 0}
                textLabel="Member"
                onClick={() => setIsOpenDropdown(el.id)}
                isAutoComplete={isOpenDropdown === el.id}
                closeAutoComplete={() => handleOpenDropdown(el?.id)}
                setAutoComplete={(val) => {
                  handleClickName(el?.id, val);
                }}
                listAutoComplete={names}
                onBlur={() => {
                  setTimeout(() => {
                    handleOpenDropdown(false);
                  }, 200);
                  clearTimeout();
                }}
              />
              <Icon
                icon={"trash"}
                className={idx !== 0 ? Styles.deleteIcon : ""}
                color={idx === 0 ? "#CDD2DB" : "black"}
                size={16}
                onClick={() => idx !== 0 && handleDeleteInput(el.id)}
                style={{
                  position: "relative",
                  top: idx === 0 ? "10px" : "unset",
                }}
              />
            </div>
          ))}
          <div className={Styles.btnAddMembers} onClick={handleAddInput}>
            <span>Tambah Member</span>
            <Icon icon={"add-circle"} size={16} className={Styles.iconAdd} />
          </div>
        </div>
        <div className={Styles.footField}>
          <Button text="Kembali" onClick={onClose} />
          <Button
            text="Lanjut"
            onClick={() => {
              onClose();
              handleDataToSend();
            }}
            isDisabled={!chartName || !inputDataMembers[0].name || !isChoose}
          />
        </div>
      </div>
    </Modal>
  );
}
