import Modal from '@Atom/Modal'
import Styles from './style.module.scss'
import { useMemo, useState } from 'react'
import Icon from '@Atom/Icon'
import Input from '@Atom/Input'

export default function AssignTicketModal({
  show,
  onClose,
  data,
  onSuccess = () => { },
  isTransfer = false
}) {
  // eslint-disable-next-line no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isSuccess, setIsSuccess] = useState(false)
  const [handler, setHandler] = useState('')

  const options = useMemo(() => {
    if (isTransfer) {
      return [
        {
          picture: '',
          name: 'test1',
          label: <div className={`${Styles.benefit} ${Styles.label}`}>
            <span>Benefit</span>
          </div>
        },
        {
          picture: '',
          name: 'test2',
          label: <div className={`${Styles.ops} ${Styles.label}`}>
            <span>Ops</span>
          </div>
        },
        {
          picture: '',
          name: 'test3',
          label: <div className={`${Styles.hcga} ${Styles.label}`}>
            <span>HCGA</span>
          </div>
        },
        {
          picture: '',
          name: 'test4',
          label: <div className={`${Styles.it} ${Styles.label}`}>
            <span>IT</span>
          </div>
        },
        {
          picture: '',
          name: 'test5',
          label: <div className={`${Styles.service} ${Styles.label}`}>
            <span>Service</span>
          </div>
        },
      ]
    } return [
      {
        picture: '',
        name: 'test',
        label: <div>
          <span>Benefit</span>
        </div>
      }
    ]
  }, [isTransfer])

  return (
    <Modal isOpen={show} onClose={isSubmitting ? () => { } : (isSuccess ? onSuccess : onClose)}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.icon}>
            <Icon icon={'document-forward-outline'} size={28} />
          </div>
          <div className={Styles.title}>
            <h3>{isTransfer ? 'Transfer' : 'Assign'} Ticket</h3>
            <span>{isTransfer ? 'Alihkan ticket kepada departemen lain.' : 'Tugaskan penanggung jawab untuk mengurus pengaduan'}</span>
          </div>
        </div>
        <div className={Styles.content}>
          <Input
            title={isTransfer ? 'Pilih Manager' : 'Pilih Penanggung Jawab'}
            value={handler}
            setValue={setHandler}
            isUserAutocomplete
            placeholder='Belum dipilih'
            dropdownOptions={
              options
            }
          />
        </div>
        <div className={Styles.actions}>
          <button onClick={onClose}>Batal</button>
          <button>Simpan</button>
        </div>
      </div>
    </Modal>
  )
}