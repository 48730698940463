import Button from '@Atom/Button'
import Icon from '@Atom/Icon'
import InputField from '@Atom/InputField'
import Modal from '@Atom/Modal'
import { useState } from 'react'
import Styles from './style.module.scss'

export default function SetLeaveModal({
  show,
  onClose = () => { }
}) {

  const [form, setForm] = useState({
    amount: '',
    description: ''
  })

  const handleChangeForm = (name, value) => {
    setForm(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  return (
    <Modal isOpen={show} onClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.top}>
            <h3>Atur Cuti Karyawan</h3>
            <button onClick={onClose}>
              <Icon icon={'close-circle'} size={10} />
            </button>
          </div>
          <span>Atur cuti untuk menambahkan jatah cuti karyawan</span>
        </div>
        <div className={Styles.content}>
          <InputField
            isLabel
            textLabel='Jumlah Cuti'
            isRequired
            value={form?.amount}
            setValue={(newVal) => handleChangeForm('amount', newVal)}
            isNumber
          />
          <InputField
            isLabel
            textLabel='Deskripsi'
            isRequired
            value={form?.description}
            setValue={(newVal) => handleChangeForm('description', newVal)}
            isTextArea
          />
        </div>
        <div className={Styles.actionsWrapper}>
          <Button
            text='Simpan'
          />
        </div>
      </div>
    </Modal>
  )
}