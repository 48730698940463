import Organization from "@Organism/Employee/Organization";
import { getAllChart } from "@Services/organization/getAllChart";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = () => {
  const fetchAllChart = getAllChart();
  return defer({
    getAllChart: fetchAllChart,
  });
};

export default function OrganizationPage() {
  const { getAllChart } = useLoaderData();
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Await
        resolve={getAllChart}
        errorElement={<p>Error...</p>}
        children={(val) => <Organization data={val} />}
      ></Await>
    </Suspense>
  );
}
