import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Tabs from "@Molecule/Tabs";
import SearchBar from "@Atom/SearchBar";
import Table from "@Molecule/Table";
import useWindowSize from "@Hooks/useWindowSize";
import TabHeader from "@Atom/TabHeader";
import DateListTable from "@Atom/DateListTable";
import Icon from "@Atom/Icon";
import DropdownMoreCircle from "@Atom/DropdownMoreCircle";
import ModalUploadEmployee from "@Atom/ModalUploadEmployee";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileEmployee from "@Atom/ProfileEmployee";
import moment from "moment";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";
import { fileBaseUrl } from "configs";
import Modal from "@Atom/Modal";
import Button from "@Atom/Button";
import FaqImage from "@Assets/Icon/qustionLogo.svg";
import { deleteEmployee } from "@Services/employee/deleteEmployee";
import { changeStatusEmployee } from "@Services/employee/changeStatusEmployee";
import IsEmptyEmployee from "@Atom/IsEmptyEmployee";
import Toast from "@Atom/Toast";
import Avatar from "@Assets/Images/ava-default.png";
import Lottie from "lottie-react";
import LoadingJSON from "@Assets/json/loading.json";
export default function ListEmployee({
  dataActiveEmployee,
  dataInActiveEmployee,
  isEmpty = false,
}) {
  moment.locale("id");
  const navigate = useNavigate();
  const query = useQuery();
  const limitQuery = query.get("limit") || 10;
  const searchQuery = query.get("search") || "";
  const pageQuery = query.get("page") || 1;
  const { pathname } = useLocation();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState(searchQuery);
  const debouncedTrigger = useDebounce(searchTerm, 500);

  const [tabsActive, setTabsActive] = useState(
    +sessionStorage.getItem("tabsActive") || 0
  );

  useEffect(() => {
    sessionStorage.setItem("tabsActive", tabsActive);
  }, [tabsActive]);

  const listTabs = ["Karyawan Aktif", "Karyawan Tidak Aktif"];
  const { width } = useWindowSize();
  useEffect(() => {
    setPage(+pageQuery || 1);
    setLimit(+limitQuery || 10);
    setSearchTerm(searchQuery || "");
  }, [limitQuery, pageQuery, searchQuery]);

  useEffect(() => {
    navigate(
      `${pathname}?q=query&limit=${limit}&page=${page}&search=${debouncedTrigger}`,
      {
        replace: true,
      }
    );
  }, [limit, navigate, page, pathname, debouncedTrigger]);

  const dataToShow = useMemo(() => {
    if (tabsActive === 0) {
      return dataActiveEmployee?.data;
    } else {
      return dataInActiveEmployee?.data;
    }
  }, [dataActiveEmployee?.data, dataInActiveEmployee?.data, tabsActive]);
  const [openChoose, setOpenChoose] = useState();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState();

  const [sort, setSort] = useState({
    column: "name",
    type: "ASC" || "DESC",
  });
  const sortedData = useMemo(() => {
    const sorted = [...dataToShow?.data].sort((a, b) => {
      if (sort.column) {
        if (sort.type === "ASC") {
          return a.name?.localeCompare(b.name);
        } else {
          return b.name?.localeCompare(a.name);
        }
      }
      return 0;
    });

    return sorted;
  }, [dataToShow?.data, sort]);

  const template = useMemo(() => {
    return {
      data: sortedData?.map((da) => ({
        id: da?.contracts?.idNumber,
        employeeID: da?.employeeID,
        name: da?.name,
        department:
          da?.contracts?.[da?.contracts?.length - 1]?.department || "-",
        startDate: da?.contracts?.[da?.contracts?.length - 1]?.startDate || "-",
        endDate: da?.contracts?.[da?.contracts?.length - 1]?.endDate || "-",
        contractType: da?.contracts?.[da?.contracts?.length - 1]?.contractType,
        position: da?.contracts?.[da?.contracts?.length - 1]?.position,
        profilePicture: da?.imageURL,
      })),
      totalData: dataToShow?.total,
      columns: [
        {
          clickExpand: true,
          name: "name",
          width: width > 851 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Nama"
              column={"name"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <ProfileEmployee
              onClick={
                width > 851
                  ? () => navigate("detail/" + row?.employeeID)
                  : () => {}
              }
              name={row?.name}
              idEmployee={row.employeeID}
              image={
                row.profilePicture === "" || row.profilePicture === "-"
                  ? Avatar
                  : fileBaseUrl + row.profilePicture
              }
            />
          ),
        },
        {
          name: "startDate",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Tanggal Kontrak" />,
          renderData: (row) => (
            <DateListTable
              dateIn={row.startDate}
              dateOut={row.endDate === "-" ? "-" : row.endDate}
            />
          ),
          hideOnMobile: true,
        },
        {
          name: "department",
          width: width > 851 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Departement"
              column={"department"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          hideOnMobile: true,
        },
        {
          name: "position",
          width: width > 851 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Posisi"
              column={"position"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          hideOnMobile: true,
        },
        {
          name: "contractType",
          width: width > 851 ? "max-content" : "200px",
          textAlign: "end",
          title: (
            <TabHeader
              text="Jenis Kontrak"
              column={"contractType"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          hideOnMobile: true,
        },
        {
          name: "action",
          renderData: (row) => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <DropdownMoreCircle
                id={row?.employeeID}
                setIsOpenModalArchive={() => setIsOpenModalArchive(true)}
                // setIsModalDelete={() => setIsOpenModalDelete(true)}
                openChoose={openChoose}
                setOopenChoose={setOpenChoose}
                tabs={tabsActive}
              ></DropdownMoreCircle>
            </div>
          ),
        },
      ],
    };
  }, [
    sortedData,
    dataToShow?.total,
    width,
    sort,
    navigate,
    openChoose,
    tabsActive,
  ]);
  const [selectedName, setSelectedName] = useState({});

  useEffect(() => {
    if (openChoose) {
      const selectedNames = template.data.find(
        (el) => el?.employeeID === openChoose
      );
      setSelectedName(selectedNames);
    }
  }, [template.data, openChoose]);

  const [isOpenModalEmployee, setIsModalEmployee] = useState(false);
  const handleOpenModalUpload = () => {
    setIsModalEmployee(!isOpenModalEmployee);
  };
  const [isOpenModalArchive, setIsOpenModalArchive] = useState();

  const handleCloseModal = () => {
    setIsOpenModalArchive(false);
    setIsOpenModalDelete(false);
  };

  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const [isChangeStatusFail, setIsChangeStatusFail] = useState(false);

  const [isDeleteEmployee, setIsDeleteEmployee] = useState(false);
  const [isDeleteEmployeeFail, setIsDeleteEmployeeFail] = useState(false);

  const [isLoadingHit, setIsLoadingHit] = useState(false);
  const handleDeleteEmployee = async () => {
    handleCloseModal();
    try {
      setIsLoadingHit(true);
      const response = await deleteEmployee(selectedName?.id);
      if (response.status === 200) {
        setIsDeleteEmployee(true);
        setIsLoadingHit(false);
        navigate(pathname, { replace: true });
      }
    } catch (error) {
      console.log(error);
      setIsLoadingHit(false);
      setIsDeleteEmployeeFail(true);
    }
  };
  const handleChangeStatusEmployee = async () => {
    handleCloseModal();
    try {
      setIsLoadingHit(true);
      const response = await changeStatusEmployee(selectedName?.id);
      if (response.status === 200) {
        setIsChangeStatus(true);
        setIsLoadingHit(false);
        navigate(pathname, { replace: true });
      }
    } catch (error) {
      setIsChangeStatusFail(true);
      console.log(error);
      setIsLoadingHit(false);
    }
  };
  const [file, setFile] = useState();
  const [isOpenAddMobile, setIsOpeAddnMobile] = useState(false);
  useEffect(() => {
    setIsChangeStatus(false);
    setIsChangeStatusFail(false);
  }, [tabsActive]);
  setTimeout(() => {
    setIsChangeStatus(false);
    setIsChangeStatusFail(false);
    setIsDeleteEmployee(false);
    setIsDeleteEmployee(false);
  }, 5000);

  useEffect(() => {
    setSearchTerm("");
  }, [tabsActive]);

  return (
    <>
      <Toast
        isopen={isDeleteEmployeeFail}
        color="red"
        text={`${selectedName.name} Gagal Di Hapus`}
        onClose={() => setIsDeleteEmployeeFail(false)}
      />

      <Toast
        isopen={isDeleteEmployee}
        color="green"
        text={`${selectedName.name} Berhasil Di Hapus`}
        onClose={() => setIsDeleteEmployee(false)}
      />

      <Toast
        isopen={isChangeStatusFail}
        color="red"
        text={
          tabsActive === 0
            ? `${selectedName.name} Gagal Di non aktifkan`
            : `${selectedName.name} Gagal Di Aktifkan`
        }
        onClose={() => setIsChangeStatusFail(false)}
      />

      <Toast
        isopen={isChangeStatus}
        color="green"
        text={
          tabsActive === 0
            ? `${selectedName.name} Berhasil Di non aktifkan`
            : `${selectedName.name} Berhasil Di Aktifkan`
        }
        onClose={() => setIsChangeStatus(false)}
      />

      <Modal isOpen={isLoadingHit}>
        <div className={Styles.viewLoadingCreate}>
          <Lottie
            animationData={LoadingJSON}
            loop
            className={Styles.loadingElement}
            alt="loading"
          />
        </div>
      </Modal>
      <ModalUploadEmployee
        isOpen={isOpenModalEmployee}
        onClose={handleOpenModalUpload}
        setFile={setFile}
        file={file}
      />
      {isEmpty && !searchQuery ? (
        <IsEmptyEmployee onClick={handleOpenModalUpload} />
      ) : (
        <>
          <Modal isOpen={!!isOpenModalDelete || isOpenModalArchive}>
            <div className={Styles.containerModal}>
              <div className={Styles.wrapperModalDropdown}>
                <div className={Styles.headerModal}>
                  <img src={FaqImage} alt="faq" className={Styles.icon} />
                  <h2>
                    {isOpenModalDelete
                      ? "Hapus"
                      : tabsActive === 1
                      ? "Aktifkan"
                      : "Non Aktifkan"}{" "}
                    Karyawan {selectedName?.name}
                  </h2>
                  <div className={Styles.closeModal} onClick={handleCloseModal}>
                    <Icon icon={"close-circle"} color={"#000"} size={12} />
                  </div>
                </div>
                <div className={Styles.desc}>
                  {tabsActive === 0 ? (
                    <p className={Styles.paragraph}>
                      Apakah anda yakin ingin menghapus data karyawan ini?
                      Setelah anda menghapus data ini anda tidak bisa
                      mengembalikan data yang ada.
                    </p>
                  ) : (
                    <p className={Styles.paragraph}>
                      Apakah anda yakin ingin mengaktifkan kembali karyawan ini?
                    </p>
                  )}
                </div>
                <div className={Styles.wrapperButton}>
                  <Button
                    bgColor={"white"}
                    color={"#2E3192"}
                    text="Kembali"
                    onClick={handleCloseModal}
                  />
                  <Button
                    text={
                      isOpenModalDelete
                        ? "Ya, Hapus"
                        : tabsActive === 0
                        ? "Ya, Arsipkan"
                        : "Ya, Aktifkan"
                    }
                    onClick={
                      isOpenModalDelete
                        ? () => handleDeleteEmployee()
                        : () => handleChangeStatusEmployee()
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>
          <section
            className={Styles.containerListEmployee}
            style={{ height: "100%" }}
          >
            <div className={Styles.wrapperListEmployee}>
              <div className={Styles.headerList} style={{ height: "100%" }}>
                <h2 className={Styles.headerText}>Daftar Karyawan</h2>
                <Tabs
                  setIsActive={setTabsActive}
                  isActive={tabsActive}
                  listTabs={listTabs}
                />
                <div className={Styles.fieldTable}>
                  <div className={Styles.filtering}>
                    <SearchBar setValue={setSearchTerm} value={searchTerm} />
                    {width >= 851 && (
                      <div className={Styles.filter}>
                        <span>Filter</span>
                        <Icon
                          icon={"setting-4"}
                          color={"#2E3192"}
                          size={"16"}
                          className={Styles.iconFilter}
                        />
                      </div>
                    )}
                    <div className={Styles.wrapFilterMobile}>
                      {width <= 851 && (
                        <div className={Styles.filter}>
                          <span>Filter</span>
                          <Icon
                            icon={"setting-4"}
                            color={"#2E3192"}
                            size={"16"}
                            className={Styles.iconFilter}
                          />
                        </div>
                      )}
                      <div
                        className={Styles.btnUploading}
                        onClick={
                          width >= 851
                            ? () => handleOpenModalUpload()
                            : () => setIsOpeAddnMobile(!isOpenAddMobile)
                        }
                      >
                        <span>
                          {" "}
                          {width >= 851 ? "Unggah" : "Tambah"} Karyawan
                        </span>
                        <Icon
                          icon={"document-upload"}
                          color={"#2E3192"}
                          size={"16"}
                          className={Styles.icon}
                        />
                        {isOpenAddMobile && (
                          <div className={Styles.dropdownAddEmployee}>
                            <div onClick={handleOpenModalUpload}>
                              <Icon
                                icon={"document-upload"}
                                className={Styles.icon}
                              />
                              <span>Unggah Data</span>
                            </div>
                            <div
                              onClick={() => navigate("/people/add-employee")}
                            >
                              <Icon
                                icon={"add-circle"}
                                className={Styles.icon}
                              />
                              <span>Tambah Manual</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {width > 851 && (
                    <div
                      className={Styles.btnAdd}
                      onClick={() => navigate("/people/add-employee")}
                    >
                      <Icon
                        icon={"add-circle"}
                        color={"#2E3192"}
                        size={"16"}
                        className={Styles.iconAdd}
                      />
                      <span>Tambah Karyawan</span>
                    </div>
                  )}
                </div>
                <Table
                  className={Styles.table}
                  data={template.data}
                  columns={template.columns}
                  page={page}
                  limit={limit}
                  totalData={template.totalData || 0}
                  setLimit={setLimit}
                  setPage={setPage}
                  totalPage={Math.ceil(template.totalData / limit) || 0}
                  withPagination
                  withNumbering
                  expandComponent={(row) => (
                    <div className={`${Styles.wrapperExpand} expandedRow`}>
                      <div className={Styles.list}>
                        <span className={Styles.textLeft}>Tanggal Kontrak</span>
                        <DateListTable
                          dateIn={row?.startDate}
                          dateOut={row?.endDate}
                        />
                      </div>
                      <div className={Styles.list}>
                        <span className={Styles.textLeft}>Departement</span>
                        <span className={Styles.textRight}>
                          {row.department}
                        </span>
                      </div>
                      <div className={Styles.list}>
                        <span className={Styles.textLeft}>Posisi</span>
                        <span className={Styles.textRight}>{row.position}</span>
                      </div>
                      <div className={Styles.list}>
                        <span className={Styles.textLeft}>Jenis Kontrak</span>
                        <span className={Styles.textRight}>
                          {row.contractType}
                        </span>
                      </div>
                      <span
                        className={Styles.detail}
                        onClick={() => navigate(`detail/${row.id}`)}
                      >
                        Lihat Detail
                      </span>
                    </div>
                  )}
                />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
