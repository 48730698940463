import { API } from "configs";

export function getPayrollScheme(
  pksID,
  year = '',
  month = ''
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/payroll/scheme/${pksID}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: { year, month }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}