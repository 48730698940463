import React from "react";
import Styles from "./style.module.scss";
export default function TagStatus({ text = "Status", color = "blue" }) {
  return (
    <div className={Styles.wrapperTag}>
      <div className={Styles.tag} color={color}>
        <span className={Styles.text}> {text} </span>
      </div>
    </div>
  );
}
