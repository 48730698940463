import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Modal from "@Atom/Modal";
import { rupiahFormat } from "helpers/rupiahFormat";
import Styles from "./style.module.scss";

export default function ModalLoan({
    data,
    isOpen,
    onClose,
}){

    return (
        <Modal isOpen={isOpen}>
            <div className={Styles.container}>
                <div className={Styles.header}>
                    <span>{`Detail Pinjaman`}</span>
                    <div className={Styles.iconClose} onClick={onClose}>
                        <Icon
                            size={"16px"}
                            icon={"close-circle"}
                        />
                    </div>
                </div>
                <div className={Styles.body}>
                    <div className={Styles.cardBox}>
                        <div className={Styles.contentWrapper}>
                            {data?.details?.list?.map((value) => (
                                <div className={Styles.childContent}>
                                    <span>{value?.title}</span>
                                    <span>{typeof value?.value === "number" ? rupiahFormat(value?.value) : value?.value}</span>
                                </div>
                            ))}
                            <div className={Styles.divider}/>
                            <div className={Styles.totalWrapper}>
                                <span>{"Total Pencairan"}</span>
                                <span>{rupiahFormat(data?.details?.total)}</span>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.historyBox}>
                        <div className={Styles.title}>
                            <span>{"Riwayat Cicilan"}</span>
                        </div>
                        <div className={Styles.historyContentWrapper}>
                            {data?.history?.map((value, idx) => (
                                <div className={Styles.historyChildContent}>
                                    <div className={Styles.dateWrapper}>
                                        <div className={`${Styles.linkedNumber} ${value?.status !== "Lunas" ? Styles.notPaid: ''}`}>
                                            <span>{idx + 1}</span>
                                            {data?.history?.length > idx+1 && (<div className={`${Styles.verticalDivier} ${value?.status !== "Lunas" ? Styles.notPaid: ''}`}/>)}
                                        </div>
                                        <span>{value?.date}</span>
                                    </div>
                                    <div className={Styles.installmentText}>
                                        <span >{value?.value}</span>
                                    </div>
                                    <div className={`${Styles.paidWrapper} ${value?.status !== "Lunas" ? Styles.notPaid : ""}`}>
                                        <span>{value?.status}</span>
                                        <Icon icon={"check-circle"} color={"#22970F"} size={"20px"}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={Styles.footer}>
                    <Button
                        icon={"download"}
                        isLeftIcon
                        text="Unduh"
                    />
                </div>
            </div>
        </Modal>
    )
}