import SideBar from "@Molecule/SideBar";
import React, { useEffect } from "react";
import { Outlet, redirect, useLocation, useNavigate } from "react-router-dom";
import "moment/locale/id";

export const mainLoader = (route) => {
  const accessToken = localStorage.getItem("accessToken");
  const params = new URLSearchParams(route?.request?.url);
  const limit = params?.get("l") || 10;
  const page = params?.get("p") || 1;
  const search = params?.get("s") || "";

  if (!accessToken) {
    throw redirect("/login");
  }
  if (accessToken && !localStorage?.getItem('clientCode')) {
    throw redirect(`/select-client?p=${page}&l=${limit}&s=${search}`);
  }

  return null;
}

export default function MainLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const lastPathname = sessionStorage.getItem("savedPathname");
    if (lastPathname && lastPathname !== pathname) {
      navigate(lastPathname);
    } 

    if(pathname === '/'){
      navigate('/people', {replace: true});
    }
  }, [navigate, pathname]);

  setTimeout(() => {
    sessionStorage.removeItem("savedPathname");
  }, 500);

  if (!localStorage?.getItem('accessToken')) {
    return <Outlet />
  }
  return (
    <SideBar>
      <Outlet />
    </SideBar>
  );
}
