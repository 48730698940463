import Icon from '@Atom/Icon';
import PayrollSectionCard from '@Atom/PayrollSectionCard';
import Table from '@Molecule/Table';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import Styles from './style.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPayrollHistory } from '@Services/payroll/getPayrollHistory';
import { priceFormat } from '@Helpers/priceFormat';
import ReleaseLoadingModal from '@Molecule/_modal/ReleaseLoadingModal';
import Snackbar from '@Atom/Snackbar';
import { releaseSlip } from '@Services/payroll/releaseSlip';

export default function CalculationHistorySection({
  history = [],
  monthIndex = 0,
  year = '',
  pksID = '',
  fixedMonth = '01'
}) {


  return (
    <PayrollSectionCard
      title='Riwayat Kalkulasi Payroll'
    >
      <div className={Styles.batches}>
        {
          history?.map((batch, i) => (
            <BatchCard
              key={i}
              title={`Batch ${i + 1}`}
              details={batch}
              monthIndex={monthIndex}
              year={year}
              pksID={pksID}
              fixedMonth={fixedMonth}
            />
          ))
        }
      </div>


    </PayrollSectionCard>
  )
}

const BatchCard = ({
  title = 'Hasil Data Payroll',
  details,
  monthIndex = 0,
  year = '',
  pksID = '',
  fixedMonth = ''
}) => {

  const [expand, setExpand] = useState(false)

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [data, setData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [releaseCount, setReleaseCount] = useState(0)
  const [releaseDate, setReleaseDate] = useState('')

  const [isReleaseSuccess, setIsReleaseSuccess] = useState(false)

  const [releaseType, setReleaseType] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [releaseError, setReleaseError] = useState('')
  const onRelease = async () => {
    setReleaseType('loading')
    try {
      await releaseSlip(pksID, year, fixedMonth, details?.batchId, localStorage?.getItem('clientCode'))
      setIsReleaseSuccess(true)
      setReleaseType('')
    } catch (err) {
      setReleaseType('error')
      setReleaseError(err?.response?.data?.error || 'Something went wrong')
    }
  }

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getPayrollHistory(pksID, year, fixedMonth, page, limit, details?.batchId)
      const resData = response?.data?.response
      setData(resData?.data?.map(obj => {
        return {
          name: obj?.nama,
          nrk: obj?.nrk,
          position: obj?.posisi,
          umk: obj?.umk ? priceFormat(obj?.umk, ',') : '-',
          baseSalary: obj?.gapok ? priceFormat(obj?.gapok, ',') : '-',
          salary: obj?.penghasilan ? priceFormat(obj?.penghasilan, ',') : '-',
          bruto: obj?.bruto ? priceFormat(obj?.bruto, ',') : '-',
          deduction: obj?.pengurang ? priceFormat(obj?.pengurang, ',') : '-',
          bpjs: obj?.bpjs_tko ? priceFormat(obj?.bpjs_tko, ',') : '-',
          thp: obj?.thp ? priceFormat(obj?.thp, ',') : '-',
          flag: obj?.flag_calculate_final,
          hold: obj?.hold,
        }
      }))
      setReleaseCount(resData?.totalRelease)
      setReleaseDate(resData?.releaseDate)
      setTotalPage(resData?.totalPage)
      setTotalData(resData?.totalData)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err, 'ini error')
    }
  }, [details?.batchId, fixedMonth, limit, page, pksID, year])

  useEffect(() => {
    fetchData()
  }, [fetchData])


  const columns = [
    {
      name: "nrk",
      title: 'NRK',
      width: '150px',
      tdClassName: Styles.second,
      thClassName: Styles.second,
    },
    {
      name: "name",
      title: <div className={Styles.name}>
        <span>Nama</span>
        <div className={Styles.border} />
      </div>,
      width: '250px',
      tdClassName: Styles.third,
      thClassName: Styles.third,
      renderData: (row) => (
        <div className={Styles.name}>
          <span>{row?.name}</span>
          <div className={Styles.border} />
        </div>
      )
    },
    {
      name: "position",
      title: 'Posisi',
      width: '260px'
    },
    {
      name: "umk",
      title: 'UMK',
      width: '200px',
      align: 'right',
    },
    {
      name: "baseSalary",
      title: 'Gaji Pokok',
      width: '200px',
      align: 'right'
    },
    {
      name: "salary",
      title: 'Penghasilan',
      width: '200px',
      align: 'right'
    },
    {
      name: "bruto",
      title: 'Bruto',
      width: '200px',
      align: 'right'
    },
    {
      name: "deduction",
      title: 'Pengurang',
      width: '200px',
      align: 'right'
    },
    {
      name: "bpjs",
      title: 'BPJS TKO',
      width: '200px',
      align: 'right'
    },
    {
      name: "thp",
      title: 'THP',
      width: '200px',
      align: 'right'
    },
    {
      name: "flag",
      title: 'Flag Calulate Final',
      width: '200px',
      align: 'left'
    },
    {
      name: "hold",
      title: 'Hold',
      width: '200px',
      align: 'left'
    },
  ]


  return (
    <div className={Styles.batchCard}>
      <div className={Styles.header}>
        <div className={Styles.left}>
          <div className={Styles.leftTitle}>
            <span>{title}</span>
            <p>Dibuat pada {moment(details?.createdAt)?.format('LLL')?.replace(' pukul', ',')} WIB</p>
          </div>
          {
            (!!releaseDate || releaseCount) &&
            <div className={Styles.releaseDate}>
              <span>Release Date:</span>
              <span>{releaseDate ? moment(new Date())?.format('LLL')?.replace(' pukul', ',')?.replace('.', ':') : '-'}</span>
            </div>
          }
        </div>
        <div className={Styles.right}>
          <button onClick={onRelease} className={Styles.column}>
            <div>
              <span>Release e-Payslip</span>
              <p>Jml Release : {releaseCount}</p>
            </div>
            <Icon icon={'document-upload-outline'} size={16} />
          </button>
          <button onClick={() => navigate(`${pathname}/${details?.batchId}?y=${year}&mi=${monthIndex}&m=${fixedMonth}&pi=${pksID}`)}>
            <span>Lihat Detail</span>
            <Icon icon={'maximize'} size={16} />
          </button>
          <button onClick={() => setExpand(!expand)}>
            <Icon className={Styles[expand ? 'up' : 'down']} icon={'arrow-chevron-left'} size={16} />
          </button>
        </div>
      </div>
      {
        expand
        &&
        <div className={Styles.content}>
          <Table
            className={Styles.table}
            data={data}
            columns={columns}
            page={page}
            limit={limit}
            totalData={totalData || 0}
            setLimit={setLimit}
            setPage={setPage}
            totalPage={totalPage}
            withPagination
            withNumbering
            isNumberSticky
            widthNumber='20px'
            isLoading={isLoading}
          />

        </div>
      }
      {
        !!releaseType
        &&
        <ReleaseLoadingModal
          type={releaseType}
          onClose={() => setReleaseType('')}
          onRetry={onRelease}
        />
      }

      <Snackbar
        show={isReleaseSuccess}
        type={'success'}
        autoClose
        onClose={() => {
          setIsReleaseSuccess(false)
        }}
        text={`Release e-Payslip ${title} Berhasil`}
        closeDuration={5}
        width='356px'
      />
    </div>

  )
}