import { API } from "configs";

export function getAttendancesList({page = 1, limit = 5, type = 'DAILY', date, search, cutOff}){
    return new Promise(async (resolve, reject) => {
      try{
        const { data } = await API.get(`/attendance?limit=${limit}&page=${page}&type=${type}&date=${date}&search=${search}&cutOff=${cutOff}`,
          {
            headers: {
              Authorization: `${localStorage.getItem("accessToken")}`,
            },
          }
        )
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }