import React from "react";
import Styles from "./index.module.scss";
export default function Modal({ isTransparent = false, isSlider = false, children, isOpen, onClose = () => { } }) {

  if (isSlider) {
    return (
      <div className={`${Styles.slider} ${Styles[isOpen ? 'show': '']}`}>
        {children}
        <div className={`${Styles.overlay} ${Styles[isTransparent ? 'transparent' : '']}`} onClick={onClose} />
      </div>
    )
  }
  return (
    // <div>
    isOpen && (
      <div className={`${Styles.background} `}>
        {children}
        <div className={`${Styles.outside} ${Styles[isTransparent ? 'transparent' : '']}`} onClick={onClose} />
      </div>
    )

    // </div>
  );
}
