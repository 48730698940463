import { API } from "configs";

export function editFileEmployee(id, idNumber = "", payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.put(
        `/file/update-file/${id}?idNumber=${idNumber}`,
        payload,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
