import React, { useMemo } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
export default function Button({
  text = "Button",
  onClick = () => {},
  isAddButton,
  isLeftIcon,
  isDisabled,
  isBorder = false,
  isOutlined,
  isNoBorder,
  icon,
  iconOnly,
  colorIcon,
  style,
  iconSize,
  ...props
}) {
  const handleIconColor = useMemo(() => {
    if (colorIcon) return colorIcon;
    if (isOutlined) {
      if (isDisabled) return "#A9B3C1";
      else return "#2E3192";
    } else {
      if (isDisabled) return "#A9B3C1";
      else return "#FFFFFF";
    }
  }, [colorIcon, isDisabled, isOutlined]);

  return (
    <div
      className={Styles.wrapperButton}
      isleft={isLeftIcon?.toString()}
      isdisabled={isDisabled?.toString()}
      onClick={isDisabled ? () => {} : onClick}
      icononly={iconOnly?.toString()}
      style={style}
      is-border={isBorder?.toString()}
      isnoborder={isNoBorder?.toString()}
      isoutlined={isOutlined?.toString()}
      {...props}
    >
      {iconOnly ? "" : <span> {text} </span>}
      {(isAddButton || icon) && (
        <Icon
          icon={icon ? icon : "add-circle"}
          size={iconSize ? iconSize : "16"}
          className={Styles.icon}
          color={handleIconColor}
        />
      )}
    </div>
  );
}
