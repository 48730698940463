import React, { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpenField, setIsOpenField] = useState(false);
  const [isOpenFieldMember, setIsOpenFieldmember] = useState(false);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        isOpenField,
        setIsOpenField,
        isOpenFieldMember,
        setIsOpenFieldmember,
        isOpenModalDetail,
        setIsOpenModalDetail,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
