import AddEmployee from "@Organism/Employee/AddEmployee";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
const getFormEmployee = ()=> {};

export const editEmployeeLoader = () => {
  return defer({
    employeeFormPackage: getFormEmployee(),
  });
};
export default function EditEmployee() {
  const { employeeFormPackage } = useLoaderData();
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Await
        resolve={employeeFormPackage}
        errorElement={<h1>Error Get Data</h1>}
        children={(data) => <AddEmployee getData={data} />}
      ></Await>
    </Suspense>
  );
}
