import React from "react";
import Style from "./style.module.scss";
import Icon from "@Atom/Icon";
export default function Checkbox({
  isDisabled = false,
  setIsChecked = () => {},
  isChecked = false,
}) {
  const handleChecked = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div
      className={Style.wrapperCheckbox}
      ischecked={isChecked?.toString()}
      onClick={isDisabled ? () => {} : (e)=> {
        e.stopPropagation()
        handleChecked()
      }}
      isdisabled={isDisabled?.toString()}
    >
      {isChecked && <Icon icon={"check"} color={isChecked && "white"} />}
    </div>
  );
}
