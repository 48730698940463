import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Styles from "./style.module.scss";
import "@xyflow/react/dist/style.css";
import {
  addEdge,
  Background,
  Controls,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import CardChart from "@Atom/Organizations/CardCharts/CardChart";
import dagre from "dagre";
import AddFieldChart from "@Atom/Organizations/AddFieldChart";
import AddMembers from "@Atom/Organizations/AddMembers";
import { GraphValue } from "Context/GraphContext";
import Button from "@Atom/Button";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { ModalContext } from "Context/ModalContetx";
import DetailChart from "@Atom/Organizations/DetailChart";
import Toast from "@Atom/Toast";
import { makeRandomNumber } from "helpers/makeRandomNumber";
import { createOrgChart } from "@Services/organization/createOrgChart";
import Modal from "@Atom/Modal";
import Lottie from "lottie-react";
import LoadingJSON from "@Assets/json/loading.json";

const position = { x: 0, y: 0 };

export default function Organization({
  data,
  isForModal = false,
  setGetNodeId = () => {},
}) {
  // return (
  //   <TableEmptyHandler
  //     textButton="Buat Struktur Organisasi"
  //     description="Buat struktur organisasi sekarang"
  //   />
  // );

  const [nodeId, setNodeId] = useState(0);
  useEffect(() => {
    sessionStorage.setItem("nodeId", nodeId);
    setGetNodeId(nodeId);
  }, [nodeId, setGetNodeId]);

  const [nodeLevel, setNodeLevel] = useState(0);

  const connectionLineStyle = {
    strokeWidth: 1,
    stroke: "black",
  };

  const initialNodes = useMemo(() => {
    const dataToMap = data?.data?.response;
    return dataToMap?.map((n) => ({
      id: n.id.toString(),
      chartTo: n?.chartTo === "" ? "" : n.chartTo,
      position,
      data: {
        members: n?.users.map((u) => ({
          id: u?.idNumber,
          name: u?.name,
          position: u.contracts.position,
          imageURL: u.imageURL === "" || u.imageURL === "-" ? "" : u.imageURL,
        })),
        department: n?.chartName,
        typePositionBottom: "source",
        isPinPositionBottom: true,
        typePositionTop: "target",
        isPinPositionTop: !!n.chartTo,
        chartColor: n?.chartColor,
        isForModal: isForModal,
      },
      chartLevel: n?.chartLevel,
      isSpv: n.isSpv,
      type: "typeChart",
    }));
  }, [data?.data?.response, isForModal]);

  const initialEdges = initialNodes
    ?.filter((item) => item.chartTo !== "")
    ?.map((e) => ({
      id: makeRandomNumber(5).toString(),
      target: e.id.toString(),
      source: e.chartTo,
      type: "step",
    }));

  const nodeTypes = {
    typeChart: CardChart,
  };

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 300;
  const nodeHeight = 200;
  // const nodeHeight = 150;

  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes?.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges?.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const newNodes = nodes?.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      const newNode = {
        ...node,
        targetPosition: isHorizontal ? "left" : "top",
        sourcePosition: isHorizontal ? "right" : "bottom",
        position: {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        },
      };

      return newNode;
    });
    return { nodes: newNodes, edges };
  };

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const handleAddNodes = (name, chartColor, data) => {
    const newId = makeRandomNumber(6).toString();
    let nodesTemp = [...nodes];
    let edgesTemp = [...edges];
    if (!nodeId) {
      nodesTemp.push({
        id: newId,
        position,
        chartTo: "",
        data: {
          members: data,
          department: name,
          typePositionBottom: "source",
          isPinPositionBottom: true,
          typePositionTop: "target",
          isPinPositionTop: false,
          chartColor: chartColor,
        },
        type: "typeChart",
        positionType: position,
        chartLevel: "1",
        isSpv: data.members?.length < 2,
      });
    } else {
      nodesTemp.push({
        id: newId,
        position,
        chartTo: nodeId,
        data: {
          members: data,
          department: name,
          typePositionBottom: "source",
          isPinPositionBottom: true,
          typePositionTop: "target",
          isPinPositionTop: true,
          chartColor: chartColor,
        },
        type: "typeChart",
        positionType: position,
        chartLevel: (nodeLevel + 1).toString(),
        isSpv: data.members?.length < 2,
      });
      edgesTemp.push({
        id: makeRandomNumber(5).toString(),
        target: newId,
        source: nodeId,
        type: "step",
      });
    }
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodesTemp,
      edgesTemp
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  };

  const handleAddMembers = (data) => {
    setNodes((prev) =>
      prev.map((el) =>
        el.id === nodeId
          ? {
              ...el,
              data: { ...el.data, members: [...el.data.members, ...data] },
            }
          : el
      )
    );
  };

  const { isOpenField, setIsOpenField, isOpenModalDetail } =
    useContext(ModalContext);

  const [successCreateChart, setSuccessCreateChart] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleCreateChart = async () => {
    const filteredData = nodes.filter(
      (element) => !initialNodes.some((selected) => selected.id === element.id)
    );
    const dataToSend = filteredData.map((el) => ({
      chartName: el.data.department,
      chartTo: el.chartTo,
      isSpv: el?.data.members?.length < 2,
      chartLevel: el.chartLevel,
      chartColor: el.data.chartColor,
      positionX: el.position.x,
      positionY: el.position.y,
      members: el.data.members.map((member) => member.id.toString()),
    }));
    setLoading(true);
    try {
      const response = await createOrgChart({ charts: dataToSend });
      if (response.status === 201) {
        setSuccessCreateChart("successCreateChart");
        setLoading(false);
      }
    } catch (error) {
      setSuccessCreateChart("error");
      console.log(error);
      setLoading(false);
    }
  };
  // console.log(nodes, "NODES");
  // console.log(edges, "EDGES");

  useEffect(() => {
    const filterNodesId = nodes.filter((n) => n.id === nodeId);
    const chartLevelClick = +filterNodesId[0]?.chartLevel;
    setNodeLevel(chartLevelClick);
  }, [nodeId, nodes]);

  const textSuccessPost =
    successCreateChart === "successCreateChart" ||
    successCreateChart === "successAddMembers";

  const isSideActive = sessionStorage.getItem("sideBarActive");

  return (
    <>
      <Modal isOpen={loading}>
        <div className={Styles.viewLoadingCreate}>
          <Lottie
            animationData={LoadingJSON}
            loop
            className={Styles.loadingElement}
            alt="loading"
          />
        </div>
      </Modal>
      <Toast
        isopen={textSuccessPost}
        text={
          textSuccessPost
            ? "Perubahan berhasil di simpan"
            : "Perubahan gagal di simpan"
        }
        color={textSuccessPost ? "green" : "red"}
        onClose={() => setSuccessCreateChart(false)}
      />
      {nodes?.length === 0 ? (
        <>
          <TableEmptyHandler
            textButton="Buat Struktur Organisasi"
            description="Buat struktur organisasi sekarang"
            isAddButton
            onClick={() => setIsOpenField(true)}
          />
          <AddFieldChart isOpen={isOpenField} handleSubmit={handleAddNodes} />
        </>
      ) : (
        <GraphValue.Provider value={{ nodes, edges, nodeId }}>
          {!isForModal && (
            <>
              <DetailChart id={nodeId} isOpen={isOpenModalDetail} />
              <AddFieldChart id={nodeId} handleSubmit={handleAddNodes} />
              <AddMembers id={nodeId} handleSubmit={handleAddMembers} />
            </>
          )}
          {!isForModal && (
            <div className={Styles.headerOrg}>
              <h1 is-expanded={!!isSideActive?.toString()}>
                Struktur Organisasi
              </h1>
              <Button text="Simpan" onClick={() => handleCreateChart()} />
            </div>
          )}
          <div
            style={{
              background: "#dfe3eb",
              height: "100vh",
              width: "100vw",
            }}
          >
            <div className={Styles.wrapperChart}>
              <div className={Styles.mainChart}>
                <div
                  className={Styles.contain}
                  is-for-modal={isForModal.toString()}
                >
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    onConnect={isForModal ? () => {} : onConnect}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onNodeClick={(_, nodes) => {
                      setNodeId(nodes.id);
                    }}
                    connectionLineStyle={connectionLineStyle}
                    fitView
                  >
                    <Controls
                      position="bottom"
                      style={{ bottom: isForModal ? "60px" : "80px" }}
                    />
                    <Background
                      variant="lines"
                      gap={12}
                      size={1}
                      bgColor="#FAFAFC"
                    />
                  </ReactFlow>
                </div>
              </div>
            </div>
          </div>
        </GraphValue.Provider>
      )}
    </>
  );
}
