import { API } from "configs";

export function deletMember(chartId, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.put(
        `/orgmember/setInactive/${chartId}`,
        payload,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
