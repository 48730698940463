import { API } from "configs";

export function verifyOtp(hash = "", otp='', phoneNumber = "",) {
  return new Promise(async (resolve, reject) => {
    try {
      const form = new URLSearchParams()
      form?.append('hash', hash)
      form?.append('otp', otp)
      form?.append('phoneNumber', phoneNumber)
      const response = await API.post(`/auth/verifyOtp`, form, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
