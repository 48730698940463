import React from "react";
import Styles from "./style.module.scss";
import Skeleton from "@Atom/Skeleton";
export default function SkeletonDataFamilies() {
  return (
    <div className={Styles.wrapperSkeletonDataFamilies}>
      <div className={Styles.viewerCol}>
        <Skeleton height="30px" />
        {Array.from({ length: 6 }).map((_, idx) => (
          <div className={Styles.flexView}>
            <Skeleton />
            <Skeleton />
          </div>
        ))}
      </div>
      <div className={Styles.viewerCol}>
        <Skeleton height="30px" />
        {Array.from({ length: 6 }).map((_, idx) => (
          <div className={Styles.flexView}>
            <Skeleton />
            <Skeleton />
          </div>
        ))}
      </div>
    </div>
  );
}
