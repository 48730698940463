import HamburgerMenu from "@Assets/Icon/hamburgerMenu.svg";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
import useWindowSize from "@Hooks/useWindowSize";
import Images from "@Theme/Images";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import { fileBaseUrl } from "configs";
export default function TopBar({
  onClick = () => {},
  currentActiveMenu,
  textBreadcrumbs = ["list 1", "list 2"],
  setShowClients = () => {},
}) {
  const { width } = useWindowSize();
  const profilePicture = useMemo(() => {
    if (
      localStorage?.getItem("profilePicture") &&
      localStorage?.getItem("profilePicture") !== "null"
    ) {
      return fileBaseUrl + localStorage?.getItem("profilePicture");
    } else {
      return Images.AVA_DEFAULT;
    }
  }, []);
  const username = localStorage?.getItem("username");
  const role = localStorage?.getItem("role");
  const navigate = useNavigate();

  const [expandProfile, setExpandProfile] = useState(false);
  const ref = useRef();

  const outsideClick = useOutsideClick(ref);

  useEffect(() => {
    if (outsideClick && expandProfile) {
      setExpandProfile(false);
    }
  }, [expandProfile, outsideClick]);

  return (
    <div className={Styles.warpperTopBar}>
      <div className={Styles.topBarBetween}>
        {width < 768 ? (
          <div className={Styles.hamburgerMenu} onClick={onClick}>
            <img src={HamburgerMenu} alt="icon-menu" />
          </div>
        ) : currentActiveMenu ? (
          <div className={Styles.wrapperBreadcrumbs}>
            <div>
              {textBreadcrumbs.map((item, idx) => (
                <Fragment key={idx}>
                  <span
                    is-active={(currentActiveMenu === idx)?.toString()}
                    className={Styles.text}
                  >
                    {item}
                  </span>
                  {idx !== textBreadcrumbs.length - 1 && (
                    <Icon icon={"arrow-down-2"} color={"#3E4856"} size={14} />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className={Styles.wrapperIconTopBar}>
          <div className={Styles.clientName}>
            <div className={Styles.left}>
              <Icon icon={"building"} size={16} />
              <span>{localStorage?.getItem("clientName")}</span>
            </div>
            <div className={Styles.right} onClick={() => setShowClients(true)}>
              <Icon icon={"edit-2"} size={20} />
            </div>
          </div>
          <Icon icon={"menu"} className={Styles.iconTopBar} />
          <Icon icon={"notification"} className={Styles.iconTopBar} />
          <div
            ref={ref}
            className={Styles.profileTopBar}
            onClick={() => setExpandProfile(!expandProfile)}
          >
            <div>
              <img
                src={profilePicture}
                alt="profile"
                height={"36px"}
                width={"36px"}
              />
            </div>
            <div>
              <Icon icon={"arrow-down"} className={Styles.iconArrowProfile} />
            </div>
            {expandProfile && (
              <div
                onClick={(e) => e.stopPropagation()}
                className={Styles.profileOptions}
              >
                <div className={Styles.top}>
                  <img src={profilePicture} alt="" />
                  <div>
                    <span>{username || "-"}</span>
                    <p>{role || "-"}</p>
                  </div>
                </div>
                <div className={Styles.divider} />
                <div className={Styles.bottom}>
                  {[
                    {
                      text: "Profile",
                      hidden: true,
                      onClick: () => {},
                    },
                    {
                      text: "Setting",
                      hidden: true,
                      onClick: () => {},
                    },
                    {
                      text: "Logout",
                      hidden: false,
                      onClick: () => {
                        localStorage?.clear();
                        sessionStorage?.clear();
                        navigate("/login");
                      },
                    },
                  ]
                    ?.filter((obj) => !obj?.hidden)
                    ?.map((option, i) => (
                      <div key={i} onClick={option?.onClick}>
                        {option?.text}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
