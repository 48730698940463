import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import LoadingAnimationPage from "@Molecule/LoadingAnimationPage";
import FeedbackListLayout from "@Organism/Feedback/FeedbackList";
import { getFeedbackList } from "@Services/feedback/getFeedbackList";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const feedbackListLoader = (route) => {
  const url = new URL(route?.request?.url);
  const searchParams = url.searchParams
  const search = searchParams.get('s') || '';
  const limit = searchParams.get('l') || '10';
  const page = searchParams.get('p') || '1';

  return defer({
    packageList: getFeedbackList(page, limit, search)
  })
}
export default function FeedbackListPage() {
  const { packageList } = useLoaderData()

  return (
    <Suspense
      fallback={
        <LoadingAnimationPage />
      }
    >
      <Await
        resolve={packageList}
        errorElement={
          <AsyncErrorPage />
        }
      >
        {(packageList) => (
          <FeedbackListLayout
            packageList={packageList?.data?.response}
          />

        )}
      </Await>
    </Suspense>
  )
}