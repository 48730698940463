import { useEffect, useMemo } from 'react'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'

export default function Snackbar({
  position = {
    top: '80px',
    right: '36px'
  },
  text = '',
  show = false,
  onClose = () => { },
  autoClose = false,
  closeDuration = 5,
  type = 'success',
  width = 'auto'
}) {

  const template = useMemo(() => {
    switch (type) {
      case 'success':
        return {
          bgColor: '#22970F',
          icon: 'tick-circle',
        }
      default:
        return {}
    }
  }, [type])

  useEffect(() => {
    if (autoClose && show) {
      setTimeout(onClose, closeDuration * 1000)
    }
  }, [autoClose, closeDuration, onClose, show])


  return (
    <div
      style={{
        top: show ? position?.top : '-500px',
        right: position?.right,
        backgroundColor: template?.bgColor,
        width
      }}
      className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon={template?.icon} size={20} color={'#fff'} />
      </div>
      <span>{text}</span>
      <button onClick={onClose}>
        <Icon icon={'close-circle'} size={10} />
      </button>
    </div>
  )
}