import { API } from "configs";

export function login(employeeID = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const form = new URLSearchParams()
      form?.append('employeeID', employeeID)
      const response = await API.post(`/auth/login`, form, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
