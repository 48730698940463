import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import PayrollSchemeLayout from "@Organism/Payroll/PayrollScheme";
import { getPKSByClientCode } from "@Services/payroll/getPKSByClientCode";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const payrollSchemeLoader = () => {
  return defer({
    packagePKSList: getPKSByClientCode(localStorage?.getItem('clientCode') || '')
  })
}

export default function PayrollSchemePage() {
  const { packagePKSList } = useLoaderData()

  return (
    <Suspense
      fallback={
        <PayrollSchemeLayout
          isLoading
        />
      }
    >
      <Await
        resolve={packagePKSList}
        errorElement={
          <AsyncErrorPage />
        }

      >
        {(packagePKSList) => (
          <PayrollSchemeLayout
            pksList={packagePKSList?.data?.response}
          />

        )}

      </Await>

    </Suspense>
  )
}