import { API } from "configs";

export function downloadTemplate(
  pksID,
  year,
  month,
  isDownloadAll,
  totalWorkDays
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/payroll/template/${pksID}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: { year, month, isDownloadAll, totalWorkDays }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}