import { API } from "configs";

export function getCorps(limit = 10, page = 1, search = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/corp/pagination`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params:{limit, page, search}
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
export function getAllCorps() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/corp`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}