import axios from "axios";

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/v1",
  // baseURL: "https://4dz0v26z-3000.asse.devtunnels.ms/v1",
});

export const fileBaseUrl = process.env.REACT_APP_BASE_URL + "/v1/file/";


export const API365 = axios.create({
  baseURL: process.env.REACT_APP_365_BASE_URL + "/api/v2",
});

export const fileBaseUrl365 = process.env.REACT_APP_365_BASE_URL + "/api/file/";
