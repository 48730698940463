import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import { getAllChart } from "@Services/organization/getAllChart";
import Organization from "@Organism/Employee/Organization";
import Button from "@Atom/Button";
import Toast from "@Atom/Toast";

export default function ModalOrganization({
  isOpen = false,
  setIsOpen = () => {},
  setSelectedChart = () => {},
  selectedChart = {
    chartId: null,
    chartName: "",
  },
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenToast, setIsOpenToast] = useState(null);

  useEffect(() => {
    const fetchAllChart = async () => {
      setIsLoading(true);
      try {
        const res = await getAllChart();
        if (res.status === 200) {
          setData(res);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchAllChart();
  }, []);

  const [getNodeId, setGetNodeId] = useState(0);
  // const getNodeIdSaved = sessionStorage.getItem("nodeId");
  // sessionStorage.setItem("chartName", selectedChart?.chartName);

  useEffect(() => {
    const getNodesSelected = data?.data?.response?.filter(
      (n) => n?.id === +getNodeId
    );
    if (getNodesSelected?.length >= 0) {
      setSelectedChart((prev) => ({
        ...prev,
        chartId: getNodesSelected[0]?.id,
        chartName: getNodesSelected[0]?.chartName,
      }));
    }
  }, [data?.data?.response, getNodeId, setSelectedChart]);

  useEffect(() => {
    if (isOpenToast) {
      const timer = setTimeout(() => {
        setIsOpenToast(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isOpenToast]);

  return (
    <>
      {isOpenToast && (
        <Toast
          text="Chart berhasil di pilih"
          color="green"
          isopen={isOpenToast}
          onClose={() => setIsOpenToast(false)}
        />
      )}

      <Modal isOpen={isOpen}>
        <div className={Styles.modalOrganization} isloading={isLoading}>
          <div className={Styles.header}>
            <h3>Pilih Chart</h3>
            <Icon
              icon={"close-circle"}
              size={"16"}
              color={"#000"}
              onClick={() => setIsOpen(!isOpen)}
              className={Styles.closeIcon}
            />
          </div>
          <div className={Styles.mainContain}>
            <Organization data={data} isForModal setGetNodeId={setGetNodeId} />
          </div>
          <div className={Styles.footModal}>
            <Button
              text="Cancel"
              style={{ background: "white", color: "#2E3192" }}
              isBorder
              onClick={() => setIsOpen(false)}
            />
            <Button
              text="Simpan"
              isDisabled={!selectedChart.chartName}
              onClick={() => {
                setIsOpen(false);
                setIsOpenToast(true);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
