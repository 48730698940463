import CustomDatePicker from '@Atom/CustomDatePicker';
import Icon from '@Atom/Icon';
import SearchBar from '@Atom/SearchBar';
import TabHeader from '@Atom/TabHeader';
import Table from '@Molecule/Table';
import Tabs from '@Molecule/Tabs';
import Images from '@Theme/Images';
import moment from 'moment';
import { useMemo, useState } from 'react';
import Styles from './style.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { fileBaseUrl } from 'configs';
import { getTimeDifference } from 'helpers/getTimeDifference';

export default function AttendanceListLayout({data, page, setPage, limit, setLimit, date, setDate, search, setSearch, isActiveTabs, setIsActiveTabs, totalData, totalPage}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  // const [date1, setDate1] = useState(new Date())
  // const [date2, setDate2] = useState(new Date())
  
  const [sort, setSort] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false)

  const template = useMemo(() => {
    switch (isActiveTabs) {
      case 1:
        return {
          data: [
            {
              id: 1,
              name: 'Alfian Tinangon',
              profilePicture: null,
              employeeID: '312312343123212',
              totalWorkingDays: 23,
              totalAttendances: 23,
              totalRevisions: 1,
              totalLeaves: 2,
              totalSicks: 2,
              totalAbsences: 1,
              totalPermits: 1,
              totalLates: 2
            }
          ],
          columns: [
            {
              name: 'name',
              title: <TabHeader text="Nama Karyawan" column={'name'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <div className={Styles.profile}>
                  <img src={Images.AVA_DEFAULT} alt='' />
                  <div>
                    <span>{row?.employee?.name}</span>
                    <p>{row?.employeeID?.split('(')?.[0]}</p>
                  </div>
                </div>
              )
            },
            {
              name: 'totalWorkingDays',
              title: <TabHeader text="Jml Hari Kerja" column={'totalWorkingDays'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <p>{row?.totalWorkingDays || row?.employeeID2}</p>
              )
            },
            {
              name: 'totalAttendances',
              title: <TabHeader text="Kehadiran" column={'totalAttendances'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <span>{row?.totalAttendances}</span>
              )
            },
            {
              name: 'totalRevisions',
              title: <TabHeader text="Revisi" column={'totalRevisions'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <span>{row?.totalRevisions || 0}</span>
              )
            },
            {
              name: 'totalLeaves',
              title: <TabHeader text="Cuti" column={'totalLeaves'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <span>{row?.totalLeaves || 0}</span>
              )
            },
            {
              name: 'totalAbsences',
              title: <TabHeader text="Absen" column={'totalAbsences'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <span>{row?.totalAbsences || 0}</span>
              )
            },
            {
              name: 'totalPermits',
              title: <TabHeader text="Izin" column={'totalPermits'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <span>{row?.totalPermits || 0}</span>
              )
            },
            {
              name: 'totalLates',
              title: <TabHeader text="Terlambat" column={'totalLates'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <span>{row?.totalLates || 0}</span>
              )
            },
          ]
        }
      default:
        return {
          columns: [
            {
              name: 'name',
              title: <TabHeader text="Nama Karyawan" column={'name'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <div className={Styles.profile}>
                  <img src={Images.AVA_DEFAULT} alt='' />
                  <div>
                    <span>{row?.employee?.name}</span>
                    <p>{row?.employeeID?.split('(')?.[0]}</p>
                  </div>
                </div>
              )
            },
            {
              name: 'startTime',
              title: <TabHeader text="Jam Masuk & Pulang" column={'startTime'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <div className={Styles.workingHour}>
                  <div>
                    <Icon icon={'clock-solid'} size={14} color={'#718198'} />
                    <span>{row?.type === 'HADIR' ? moment(new Date(row?.startTime))?.format('HH:mm') : "--:--"}</span>
                  </div>
                  <div>
                    <Icon icon={'clock-solid'} size={14} color={'#718198'} />
                    <span>{row?.type === 'HADIR' ? moment(new Date(row?.endTime))?.format('HH:mm') : "--:--"}</span>
                  </div>
                </div>
              )
            },
            {
              name: 'totalWorkInMin',
              title: <TabHeader text="Total Jam Kerja" column={'totalWorkInMin'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <div className={Styles.totalHours}>
                  <Icon icon={'timer-start-solid'} size={16} color={'#718198'} />
                  <span>{row?.totalWorkInMin ? getTimeDifference(new Date(row?.endTime), new Date(row?.startTime)) : "--:--"}</span>
                </div>
              )
            },
            {
              name: 'type',
              title: <TabHeader text="Kehadiran" column={'type'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <span>{row?.type}</span>
              )
            },
            {
              name: 'photo',
              title: <TabHeader text="Foto" column={'photo'} sort={sort} setSort={setSort} isSorted />,
              renderData: (row) => (
                <div className={Styles.selfie}>
                  {
                    row?.attendanceImage?.[0]?.uri
                      ?
                      <img alt='' src={`${fileBaseUrl}${row.attendanceImage[0].uri}`}/>
                      :
                      <div className={Styles.noSelfie}>
                        <Icon icon={'no-picture'} size={24} />
                        <span>Foto Tidak Tersedia</span>
                      </div>
                  }
                </div>
              )
            }
          ]
        }
    }
  }, [isActiveTabs, sort])


  const tabs = useMemo(() => {
    return ['Absensi Harian', 'Absensi Bulanan'];
  }, []);
  return (
    <div className={Styles.container}>
      <h3>Absensi</h3>
      <Tabs listTabs={tabs} isActive={isActiveTabs} setIsActive={setIsActiveTabs} />
      <div className={Styles.controller}>
        <div className={Styles.searchWrapper}>
          <SearchBar
            value={search}
            setValue={setSearch}
            placeholder='Search'
          />
        </div>
        <div className={Styles.actions}>
          <button>
            <Icon icon={'download'} size={16} />
            <span>Download Report</span>
          </button>
          <CustomDatePicker
            right='0'
            value={date}
            setValue={setDate}
            padding='12px'
            iconPosition='start'
            className={Styles.customDatePicker}
          />
        </div>
      </div>
      <div className={Styles.tableWrapper}>
        <Table
          className={Styles.tableRadius}
          data={data}
          columns={template.columns}
          page={page}
          limit={limit}
          totalData={totalData}
          totalPage={totalPage}
          setLimit={setLimit}
          setPage={setPage}
          withPagination
          onClickRow={(row) => {
            navigate(`${pathname}/${row?.employeeID?.split('(')?.[0]}`)
          }}
          isLoading={isLoading}

        // withCheckbox
        // isChecked={isChecked}
        // setIsChecked={setIsChecked}
        // expandComponent={(row) => (
        //     <>
        //         <span>Atasan Langsung</span>
        //         <div className={Styles.supervisorWrapper}>
        //             <Avatar imgUrl={row?.directSupervisor?.profilePicture} initial={row?.directSupervisor?.name[0]} />
        //             <div className={Styles.supervisorContent}>
        //                 <span>{row?.directSupervisor?.name}</span>
        //                 <span>{row?.directSupervisor?.position}</span>
        //             </div>
        //         </div>
        //     </>
        // )}
        />
      </div>
    </div>
  )
}