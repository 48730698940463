import moment from 'moment';
moment.locale('id');

export function isDateBetween(date, startDate, endDate) {
    const momentDate = moment(date);
    const momentStartDate = moment(startDate).startOf('day');
    const momentEndDate = moment(endDate).endOf('day');
    
    return momentDate.isBetween(momentStartDate, momentEndDate, undefined, '[]');
}
