import Modal from '@Atom/Modal'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'
import moment from 'moment'
import Map from '@Molecule/Map'
import { useCallback, useEffect, useState } from 'react'
import { fileBaseUrl } from 'configs'
import { capitalizedFirstLetter } from 'helpers/capitalizedFirstLetter'

export default function AttendanceDetailModal({ selectedAttendance, onClose = () => { } }) {
  const type = selectedAttendance?.value

  const [scale, setScale] = useState(100); // Nilai default skala
  const handleDecrement = () => {
    if (scale > 100) {
      setScale((prevScale) => {
        const newScale = prevScale - 10;
        return newScale >= 20 ? newScale : prevScale;
      });
    }


  };

  const handleIncrement = () => {
    setScale((prevScale) => {
      const newScale = prevScale + 10;
      return newScale <= 500 ? newScale : prevScale;
    });
  };

  const [mousePos, setMousePos] = useState({});
  const [imageCenter, setImageCenter] = useState(`center`);
  const [onImage, setOnImage] = useState(false);
  const [distanceX, setDistanceX] = useState(null);
  const [distanceY, setDistanceY] = useState(null);

  useEffect(() => {
    setMousePos({})
    setImageCenter('center')
    setScale(100)
    setDistanceX(null)
    setDistanceY(null)
  }, [selectedAttendance])

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  // const displayImage = 'https://media.istockphoto.com/id/1486674561/photo/young-woman-taking-a-selfie.jpg?s=612x612&w=0&k=20&c=ZbH8PakbBL8UAFhvsFSRAWXJakqz92UgnFFxRC3ucj0='

  const dateDetail = useCallback((field) => {
    switch (field) {
      case 'startTime':
        if (selectedAttendance?.startTime && type !== 'absence') return moment(selectedAttendance?.startTime)?.format('HH:mm');
        else return "--:--";
      case 'endTime':
        if (selectedAttendance?.endTime && type !== 'absence') return moment(selectedAttendance?.endTime)?.format('HH:mm');
        else return "--:--";
      default:
        if (!selectedAttendance?.startTime) return moment(selectedAttendance?.date)?.format('LL')

        const startT = moment(selectedAttendance?.startTime)?.format('DD MMM YYYY')
        const endT = selectedAttendance?.endTime ? moment(selectedAttendance?.endTime)?.format('DD MMM YYYY') : null

        if(startT === endT || !endT) return moment(selectedAttendance?.startTime)?.format('LL')
        else return `${moment(selectedAttendance?.startTime)?.format('DD MMM')} - ${endT}`
    }
  }, [selectedAttendance, type])

  const attendanceMethod = useCallback(() => {
      switch (selectedAttendance?.method) {
          case 'WFO':
              return 'Work From Office';
          case 'WFH':
              return 'Work From Home';
          case 'WFA':
              return 'Work From Anywhere';
          default:
              return '';
      }
  }, [selectedAttendance?.method]);
  


  return (
    <Modal isOpen={selectedAttendance} onClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Detail Absen</h3>
          <button onClick={onClose}>
            <Icon icon={'close-circle'} size={10} />
          </button>
        </div>
        <div className={`${Styles.content} ${Styles[type]}`}>
          {
            (type === 'attend'
              || type === 'sick'
            )
            &&
            <div className={Styles.selfie}>
              {/* <img 
              src='https://media.istockphoto.com/id/1486674561/photo/young-woman-taking-a-selfie.jpg?s=612x612&w=0&k=20&c=ZbH8PakbBL8UAFhvsFSRAWXJakqz92UgnFFxRC3ucj0=' alt='' 
              style={{ transform: `scale(${scale / 100})`}}
              /> */}
              <div
                className={Styles.image}
                style={{
                  // transform: `scale(${scale / 100})`,
                  height: `calc(${scale} / 100 * 100%)`,
                  width: `calc(${scale} / 100 * 100%)`,
                  //  left: 0,
                  //  right: 0,
                  //  marginLeft: 'auto',
                  //  marginRight: 'auto',
                  // backgroundImage: `url(${displayImage})`,
                  backgroundImage: `url(${fileBaseUrl + selectedAttendance?.attendanceImage?.[0]?.uri})`,
                  backgroundRepeat: `no-repeat`,
                  backgroundPosition: imageCenter,
                  backgroundSize: `${scale === 100 ? 'contain' : 'cover'}`,
                  position: `relative`
                }}
                onMouseDown={e => {
                  setOnImage(true)
                  setDistanceX(mousePos.x - parseInt(window.getComputedStyle(e.target).getPropertyValue("background-position").split(' ')[0].slice(0, -2)))
                  setDistanceY(mousePos.y - parseInt(window.getComputedStyle(e.target).getPropertyValue("background-position").split(' ')[1].slice(0, -2)))
                }}
                onMouseUp={() => setOnImage(false)} onMouseMove={e => {
                  if (onImage) {
                    setImageCenter(`${mousePos.x - distanceX}px ${mousePos.y - distanceY}px`)
                  }
                }}

              />

              <div className={Styles.scaler}>
                <button disabled={scale === 100} onClick={handleDecrement}>-</button>
                <span>{scale}%</span>
                <button onClick={handleIncrement}>+</button>
              </div>
            </div>
          }
          <div className={Styles.main}>
            <div className={Styles.card}>
              {
                [
                  {
                    icon: 'calendar-2',
                    title: 'Tanggal',
                    value: dateDetail('date')
                  },
                  {
                    icon: 'broken-clock',
                    title: 'Jam Masuk',
                    value: dateDetail('startTime')
                  },
                  {
                    icon: 'clock',
                    title: 'Jam Keluar',
                    value: dateDetail('endTime')
                  },
                  {
                    icon: 'profile-add',
                    title: 'Status',
                    value: selectedAttendance?.type ? capitalizedFirstLetter(selectedAttendance.type) : "-"
                  },
                ]
                  ?.filter(obj => type === 'sick' || type === 'leave' || type === 'permit' ? !obj?.title?.includes('Jam') : obj)
                  ?.map((p, pI) => (
                    <div key={pI}>
                      <Icon icon={p?.icon} size={16} color={'#2E3192'} />
                      <span>{p?.title}</span>
                      <p>{p?.value || '-'}</p>
                    </div>
                  ))
              }
            </div>
              {selectedAttendance?.description && (
                <div className={Styles.location}>
                    <div className={Styles.lHeader}>
                      <span>Keterangan</span>      
                    </div>
                    <p>{selectedAttendance?.description}</p>
                </div>
              )}
            {
              (type === 'attend')
              &&
              <div className={Styles.location}>
                <div className={Styles.lHeader}>
                  <Icon icon={'location'} size={16} color={'#919EB0'} />
                  <span>Detail Lokasi</span>
                  <p>{attendanceMethod()}</p>
                </div>
                <div className={Styles.mapWrapper}>
                  <Map
                  lat={parseFloat(selectedAttendance?.latitude || "-6.175110")}
                  lng={parseFloat(selectedAttendance?.longitude || "106.865036")}
                  />
                </div>
              </div>
            }

            {
              (type === 'absence')
              &&
              <div className={Styles.absenceWrapper}>

                {
                  [
                    {
                      title: 'Keterangan',
                      desc: 'Males berangkat'
                    },
                    {
                      title: 'Foto',
                      icon: 'no-picture',
                      desc: 'Foto Tidak Tersedia'
                    },
                    {
                      title: 'Detail Lokasi',
                      icon: 'no-location',
                      desc: 'Lokasi Tidak Tersedia'
                    },

                  ]
                    ?.map((d, dI) => (
                      <div key={dI}>
                        <span>{d?.title}</span>
                        <div>
                          <Icon icon={d?.icon} size={24} />
                          <span>{d?.desc}</span>
                        </div>
                      </div>
                    ))
                }
              </div>
            }



          </div>
        </div>
      </div>
    </Modal>
  )
}