import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import gallery from "@Assets/Icon/gallery.svg";
import Button from "@Atom/Button";
import badgeInfo from "@Assets/Icon/info.svg";
import briefCase from "@Assets/Icon/briefcase.svg";
import starPrestation from "@Assets/Icon/star.svg";
import useWindowSize from "@Hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import EmptyHandlerFamily from "@Atom/EmptyHandlerFamily";
import Modal from "@Atom/Modal";
import Toast from "@Atom/Toast";
import PersonalData from "./FormDataPersonal";
import DataFamilies from "./FormDataFamilies";
import {
  dataJobs,
  dataPrestation,
  dataStudy,
  dataToSendPersonal,
} from "initaialState/add-employee/dataToSend";
import { dataFamilies } from "initaialState/add-employee/dataFamilies";
import DataStudied from "./FormDataStudied";
import { makeRandomString } from "helpers/makeRandomString";
import FormDataJobs from "./FormDataJobs";
import FormDataPrestation from "./FormDataPrestation";
import FormDataStaffing from "./FormDataStaffing";
import FormDataUploadDocument from "./FormDataUploadDocument";
import { createManualEmployee } from "@Services/employee/createManualEmployee";
import { getAllChart } from "@Services/organization/getAllChart";
import LoadingJSON from "@Assets/json/loading.json";
import Lottie from "lottie-react";

export default function AddEmployee({ getData }) {
  const [selectedChart, setSelectedChart] = useState(
    sessionStorage.getItem("selectedChart") || {
      chartId: null,
      chartName: "",
    }
  );

  const [isActiveSideMenuProfile, setIsActiveSideMenuProfile] = useState(
    +sessionStorage.getItem("activeStep") || 0
  );

  useEffect(() => {
    sessionStorage.setItem("activeStep", isActiveSideMenuProfile);
  }, [isActiveSideMenuProfile]);

  const savedIsAddDataJobs = sessionStorage.getItem("isAddDataJobs");
  const [isAddDataJob, setIsAddDataJob] = useState(
    savedIsAddDataJobs !== null ? JSON.parse(savedIsAddDataJobs) : false
  );

  useEffect(() => {
    sessionStorage.setItem("isAddDataJobs", JSON.stringify(isAddDataJob));
  }, [isAddDataJob]);

  const savedIsAddDaPrestation = sessionStorage.getItem("isAddPrestation");
  const [addPrestation, setAddPrestation] = useState(
    savedIsAddDaPrestation !== null ? JSON.parse(savedIsAddDaPrestation) : false
  );

  useEffect(() => {
    sessionStorage.setItem("isAddPrestation", JSON.stringify(addPrestation));
  }, [addPrestation]);

  const [step, setStep] = useState(
    +sessionStorage.getItem("activeChildStep") || 0
  );

  useEffect(() => {
    sessionStorage.setItem("activeChildStep", step);
  }, [step]);

  const clearSession = () => {
    sessionStorage.removeItem("activeStep");
    sessionStorage.removeItem("isAddDataJobs");
    sessionStorage.removeItem("isAddPrestation");
    sessionStorage.removeItem("activeChildStep");
    sessionStorage.removeItem("selectedChart");
    sessionStorage.removeItem(nameSessionDataPersonal);
    sessionStorage.removeItem(nameSessionDataFamilies);
    sessionStorage.removeItem(nameSessionDataStudied);
    sessionStorage.removeItem(nameSessionDataJobs);
    sessionStorage.removeItem(nameSessionDataPrestation);
    sessionStorage.removeItem(nameSessionDataStaffing);
    sessionStorage.removeItem(nameSessionSelectedChart);
    sessionStorage.clear();
    navigate("/people");
  };

  const topRef = useRef();
  const handleBackToTop = () => {
    topRef?.current?.scrollIntoView({
      top: 0,
      behavior: "smooth",
      block: "center",
    });
  };
  const { width } = useWindowSize();
  useEffect(() => {
    handleBackToTop();
  }, [step, isActiveSideMenuProfile]);
  const reffUploadProfile = useRef();

  const navigate = useNavigate();
  const [docStaffing, setDocStaffing] = useState(null);
  const [progressDocStaffing, setIsProgressDocStaffing] = useState(0);
  const reffDocSatffing = useRef();

  const handleUploadFileStaffing = (e) => {
    const files = e?.target?.files[0];
    setDocStaffing(files);
    let progress = 0;
    const intervalId = setInterval(() => {
      progress += 1;
      setIsProgressDocStaffing(progress);
      if (progress >= 100) {
        clearInterval(intervalId);
      }
    }, 20);
  };

  // uploading Document
  const [documentsUpload, setDocumentsUpload] = useState([]);
  const [isErrorFilesDocument, setIsErrorFilesDocument] = useState(false);
  const reffUploadDocument = useRef(null);

  const handleChangeUploadDocument = (e) => {
    const files = e.target.files;
    const fileList = Array.from(files);
    const newUploadProgress = {};
    setDocumentsUpload((prevFiles) => {
      const updatedFiles = [...prevFiles, ...fileList];

      updatedFiles.forEach((_, idx) => {
        newUploadProgress[idx + prevFiles.length] = 0;
      });

      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        ...newUploadProgress,
      }));

      return updatedFiles;
    });
  };
  const handleRemoveDocument = (index) => {
    reffUploadDocument.current.value = null;
    const updatedDocuments = [...documentsUpload];
    updatedDocuments.splice(index, 1);
    setDocumentsUpload(updatedDocuments);
  };

  const [uploadProgress, setUploadProgress] = useState({});
  const handleUploadProgressDocument = () => {
    Object.keys(uploadProgress).forEach((idx) => {
      let progress = uploadProgress[idx] || 0;
      const intervalId = setInterval(() => {
        progress += 1;
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [idx]: progress,
        }));
        if (progress >= 100) {
          clearInterval(intervalId);
        }
      }, 20);
    });
  };
  useEffect(() => {
    if (documentsUpload?.length > 0) handleUploadProgressDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsUpload]);
  ///end upload document
  const [isErrorFormDocStaffing, setIsErrorFormDocStaffing] = useState();
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  setTimeout(() => {
    if (isOpenToast) {
      setIsOpenToast(false);
    }
    if (checkFormDataPersonal) {
      setIsErrorForm((prevError) => ({
        ...prevError,
        checkFormDataPersonal: false,
      }));
    }
    if (checkFormDataFamilies) {
      setIsErrorForm((prevError) => ({
        ...prevError,
        checkFormDataFamilies: false,
      }));
    }
    if (checkFormDataStudied) {
      setIsErrorForm((prevError) => ({
        ...prevError,
        checkFormDataStudied: false,
      }));
    }
    if (checkFormDataJobs) {
      setIsErrorForm((prevError) => ({
        ...prevError,
        checkFormDataJobs: false,
      }));
    }
    if (checkFormDataPrestation) {
      setIsErrorForm((prevError) => ({
        ...prevError,
        checkFormDataPrestation: false,
      }));
    }
    if (checkFormDataStaffing) {
      setIsErrorForm((prevError) => ({
        ...prevError,
        checkFormDataStaffing: false,
      }));
    }
    if (checkFormDataFilesDocument) {
      setIsErrorForm((prevError) => ({
        ...prevError,
        checkFormDataFilesDocument: false,
      }));
    }
  }, [5000]);

  const nameSessionDataPersonal = "inputDataPersonal";
  const savedDataPersonal = sessionStorage.getItem(nameSessionDataPersonal);
  const [inputDataPersonal, setInputDataPersonal] = useState(
    JSON.parse(savedDataPersonal) || dataToSendPersonal
  );
  useEffect(() => {
    sessionStorage.setItem(
      nameSessionDataPersonal,
      JSON.stringify(inputDataPersonal)
    );
  }, [inputDataPersonal]);

  const nameSessionDataFamilies = "inputDataFamilies";
  const savedDataFamilies = sessionStorage.getItem(nameSessionDataFamilies);
  const [inputDataFamilies, setInputDataFamilies] = useState(
    JSON.parse(savedDataFamilies) || dataFamilies
  );
  useEffect(() => {
    sessionStorage.setItem(
      nameSessionDataFamilies,
      JSON.stringify(inputDataFamilies)
    );
  }, [inputDataFamilies]);

  const nameSessionDataStudied = "inputDataStudied";
  const savedDataStudied = sessionStorage.getItem(nameSessionDataStudied);
  const [inputDataStudied, setInputDataStudied] = useState(
    JSON.parse(savedDataStudied) || dataStudy
  );
  useEffect(() => {
    sessionStorage.setItem(
      nameSessionDataStudied,
      JSON.stringify(inputDataStudied)
    );
  }, [inputDataStudied]);

  const nameSessionDataJobs = "inputDataJobs";
  const savedDataJobs = sessionStorage.getItem(nameSessionDataJobs);
  const [inputDataJobs, setInputDataJobs] = useState(
    JSON.parse(savedDataJobs) || dataJobs
  );
  useEffect(() => {
    sessionStorage.setItem(nameSessionDataJobs, JSON.stringify(inputDataJobs));
  }, [inputDataJobs]);

  const nameSessionDataPrestation = "inputDataPrestation";
  const savedDataPrestation = sessionStorage.getItem(nameSessionDataPrestation);
  const [inputDataPrestation, setInputDataPrestation] = useState(
    JSON.parse(savedDataPrestation) || dataPrestation
  );
  useEffect(() => {
    sessionStorage.setItem(
      nameSessionDataPrestation,
      JSON.stringify(inputDataPrestation)
    );
  }, [inputDataPrestation]);

  const handleDeleteFormStudied = (id) => {
    setInputDataStudied((prev) => prev.filter((d) => d?.id !== id));
  };
  const handleAddDataStudied = () => {
    setInputDataStudied((prev) => [
      ...prev,
      {
        id: makeRandomString(3),
        typeStudy: "",
        dateStartJoin: "",
        dateEndJoin: "",
        nameUniversity: "",
        faculty: "",
        ipk: "",
        noDiploma: "",
        city: "",
      },
    ]);
  };

  const handleDeleteFormJobs = (id) => {
    setInputDataJobs((prev) => prev.filter((d) => d?.id !== id));
  };

  const handleisAddDataJobs = () => {
    setInputDataJobs((prev) => [
      ...prev,
      {
        id: makeRandomString(3),
        dateStartJoin: "",
        dateEndJoin: "",
        companyName: "",
        position: "",
        takeSallary: "",
        contactCompany: "",
        jobDesc: "",
      },
    ]);
  };

  const handleDeleteFormPrestation = (id) => {
    setInputDataPrestation((prev) => prev.filter((d) => d?.id !== id));
  };

  const handleAddDataPrestation = () => {
    setInputDataPrestation((prev) => [
      ...prev,
      {
        id: makeRandomString(3),
        yearOfAchive: "",
        prestation: "",
        description: "",
      },
    ]);
  };
  let savedIsLifeNik = false;

  useEffect(() => {
    setInputDataPersonal((prev) => ({
      ...prev,
      dateNik: savedIsLifeNik,
    }));
  }, [savedIsLifeNik]);

  const [isLifeTimeNik, setIsLifeTimeNik] = useState(
    sessionStorage.getItem("isLifeTimeNik") || false
  );
  useEffect(() => {
    sessionStorage.setItem("isLifeTimeNik", isLifeTimeNik);
  }, [isLifeTimeNik]);
  // useEffect(() => {
  //   if (!inputDataPersonal.dateNik) {
  //     sessionStorage.setItem("isLifeTimeNik", Boolean(isLifeTimeNik));
  //   savedIsLifeNik += sessionStorage.getItem("isLifeTimeNik");
  //   }
  // }, [inputDataPersonal.dateNik, isLifeTimeNik]);
  const [isTheSameAddress, setIsTheSameAddress] = useState(false);

  const [isErrorFormPersonalData, setIsErrorFormPersonalData] = useState();

  const [isErrorFieldFamilies, setIsErrorFieldFamilies] = useState(
    Array(inputDataFamilies.length).fill({
      contactRelations: false,
      name: false,
      placeOfBirth: false,
      dateOfBirth: false,
      nik: false,
      gender: false,
      jobStatus: false,
    })
  );

  const [isErrorFormStudied, setIsErrorFormStudied] = useState(
    Array(inputDataStudied.length).fill({
      typeStudy: false,
      start: false,
      end: false,
      nameOrganization: false,
      courseName: false,
      sertificateCode: false,
      dateStartJoin: false,
      dateEndJoin: false,
      nameUniversity: false,
      faculty: false,
      ipk: false,
      noDiploma: false,
      city: false,
    })
  );

  const [isErrorFormJobs, setIsErrorFormJobs] = useState(
    Array(inputDataJobs.length).fill({
      dateStartJoin: false,
      dateEndJoin: false,
      companyName: false,
      position: false,
      takeSallary: false,
      contactCompany: false,
      jobDesc: false,
    })
  );

  const [isErrorFormPrestation, setIsErrorFormPrestation] = useState(
    Array(inputDataPrestation.length).fill({
      yearOfAchive: false,
      prestation: false,
      description: false,
    })
  );
  const [isErrorForm, setIsErrorForm] = useState({
    checkFormDataPersonal: false,
    checkFormDataFamilies: false,
    checkFormDataStudied: false,
    checkFormDataJobs: false,
    checkFormDataPrestation: false,
    checkFormDataStaffing: false,
    checkFormDataFilesDocument: false,
  });
  const {
    checkFormDataPersonal,
    checkFormDataFamilies,
    checkFormDataJobs,
    checkFormDataPrestation,
    checkFormDataStudied,
    checkFormDataStaffing,
    checkFormDataFilesDocument,
  } = isErrorForm;
  const handleNextValidate = () => {
    if (step === 0) {
      const checkFormDataPersonal = {
        name: !inputDataPersonal.name,
        telephone: !inputDataPersonal.telephone,
        email: !inputDataPersonal.email,
        nik: !inputDataPersonal.nik,
        dateNik: isLifeTimeNik ? false : !inputDataPersonal.dateNik,
        // isLifeTimeNik: !inputDataPersonal.isLifeTimeNik,
        placeOfBirth: !inputDataPersonal.placeOfBirth,
        dateOfBirth: !inputDataPersonal.dateOfBirth,
        gender: !inputDataPersonal.gender,
        religion: !inputDataPersonal.religion,
        bodyHeight: !inputDataPersonal.bodyHeight,
        bloodType: !inputDataPersonal.bloodType,
        maritalStatus: !inputDataPersonal.maritalStatus,
        npwp: !inputDataPersonal.npwp,
        birthMothersName: !inputDataPersonal.birthMothersName,
        address: !inputDataPersonal.address,
        rt: !inputDataPersonal.rt,
        rw: !inputDataPersonal.rw,
        village: !inputDataPersonal.village,
        district: !inputDataPersonal.district,
        regency: !inputDataPersonal.regency,
        province: !inputDataPersonal.province,
        postCode: !inputDataPersonal.postCode,
        domicilies: {
          address: !inputDataPersonal.domicilies.address,
          rt: !inputDataPersonal.domicilies.rt,
          rw: !inputDataPersonal.domicilies.rw,
          village: !inputDataPersonal.domicilies.village,
          district: !inputDataPersonal.domicilies.district,
          regency: !inputDataPersonal.domicilies.regency,
          province: !inputDataPersonal.domicilies.province,
          postCode: !inputDataPersonal.domicilies.postCode,
        },
        dataFamiliesAndContact: {
          kk: !inputDataPersonal.dataFamiliesAndContact.kk,
          name: !inputDataPersonal.dataFamiliesAndContact.name,
          contactRelations:
            !inputDataPersonal.dataFamiliesAndContact.contactRelations,
          telephone: !inputDataPersonal.dataFamiliesAndContact.telephone,
          address: !inputDataPersonal.dataFamiliesAndContact.address,
        },
        familiesAndContactNotTheSameHouse: {
          name: !inputDataPersonal.familiesAndContactNotTheSameHouse.name,
          contactRelations:
            !inputDataPersonal.familiesAndContactNotTheSameHouse
              .contactRelations,
          telephone:
            !inputDataPersonal.familiesAndContactNotTheSameHouse.telephone,
          address: !inputDataPersonal.familiesAndContactNotTheSameHouse.address,
        },
        nameAccountBank: !inputDataPersonal.nameAccountBank,
        bank: !inputDataPersonal.bank,
        bankNumber: !inputDataPersonal.bankNumber,
        // facebook: !inputDataPersonal.facebook,
        // instagram: !inputDataPersonal.instagram,
        // twitter: !inputDataPersonal.twitter,
        // linkedIn: !inputDataPersonal.linkedIn,
        // hobby: !inputDataPersonal.hobby,
        clinic: !inputDataPersonal.clinic,
      };
      setIsErrorFormPersonalData(checkFormDataPersonal);
      const isErrorFormDataPersonal = Object.values(checkFormDataPersonal).some(
        (value) => {
          if (typeof value === "object") {
            return Object.values(value).some((subValue) => subValue);
          }
          return value;
        }
      );

      if (isErrorFormDataPersonal) {
        handleBackToTop();
        setIsErrorForm((err) => ({
          ...err,
          checkFormDataPersonal: true,
        }));
      } else {
        setStep((step) => step + 1);
      }
    } else if (
      step === 1 &&
      inputDataPersonal.maritalStatus !== "Belum Menikah"
    ) {
      const checkFormDataFamilies = inputDataFamilies.map((f) => ({
        contactRelations: !f?.contactRelations,
        name: !f?.name,
        placeOfBirth: !f?.placeOfBirth,
        dateOfBirth: !f?.dateOfBirth,
        nik: !f?.nik,
        gender: !f?.gender,
        jobStatus: !f?.jobStatus,
      }));
      setIsErrorFieldFamilies(checkFormDataFamilies);
      const isErrorFormDataFamilies = Object.values(checkFormDataFamilies).some(
        (value) => {
          if (typeof value === "object") {
            return Object.values(value).some((subValue) => subValue);
          }
          return value;
        }
      );
      if (isErrorFormDataFamilies) {
        handleBackToTop();
        setIsErrorForm((err) => ({
          ...err,
          checkFormDataFamilies: true,
        }));
      } else {
        setStep((step) => step + 1);
      }
    } else if (step === 2) {
      const checkFormDataStudied = inputDataStudied.map((s) => ({
        typeStudy: !s?.typeStudy,
        dateStartJoin: !s?.dateStartJoin,
        dateEndJoin: !s?.dateEndJoin,
        nameUniversity: !s?.nameUniversity,
        faculty: !s?.faculty,
        ipk: !s?.ipk,
        noDiploma: s?.typeStudy === "Non Formal" ? false : !s?.noDiploma,
        city: !s?.city,
      }));
      setIsErrorFormStudied(checkFormDataStudied);
      const isErrorFormDataStudied = Object.values(checkFormDataStudied).some(
        (value) => {
          if (typeof value === "object") {
            return Object.values(value).some((subValue) => subValue);
          }
          return value;
        }
      );
      if (isErrorFormDataStudied) {
        handleBackToTop();
        setIsErrorForm((err) => ({
          ...err,
          checkFormDataStudied: true,
        }));
      } else {
        setStep((step) => step + 1);
      }
    } else if (step === 3 && isAddDataJob) {
      const checkFormDataJobs = inputDataJobs.map((job) => ({
        dateStartJoin: !job?.dateStartJoin,
        dateEndJoin: !job?.dateEndJoin,
        companyName: !job?.companyName,
        position: !job?.position,
        takeSallary: !job?.takeSallary,
        contactCompany: !job?.contactCompany,
        jobDesc: !job?.jobDesc,
      }));
      setIsErrorFormJobs(checkFormDataJobs);
      const isErrorFormDataJobs = Object.values(checkFormDataJobs).some(
        (value) => {
          if (typeof value === "object") {
            return Object.values(value).some((subValue) => subValue);
          }
          return value;
        }
      );
      if (isErrorFormDataJobs) {
        handleBackToTop();
        setIsErrorForm((err) => ({
          ...err,
          checkFormDataJobs: true,
        }));
      } else {
        setStep((step) => step + 1);
      }
    } else if (step === 4 && addPrestation && isActiveSideMenuProfile === 0) {
      const checkFormDataPrestation = inputDataPrestation.map((p) => ({
        yearOfAchive: !p.yearOfAchive,
        prestation: !p.prestation,
        description: !p.description,
      }));
      setIsErrorFormPrestation(checkFormDataPrestation);
      const isErrorFormDataPrestation = Object.values(
        checkFormDataPrestation
      ).some((value) => {
        if (typeof value === "object") {
          return Object.values(value).some((subValue) => subValue);
        }
        return value;
      });
      if (isErrorFormDataPrestation) {
        handleBackToTop();
        setIsErrorForm((err) => ({
          ...err,
          checkFormDataPrestation: true,
        }));
      } else {
        setIsActiveSideMenuProfile(1);
      }
    }
    if (isActiveSideMenuProfile === 1) {
      const checkFormDataStaffing = {
        nikEmployee: !inputDataDocStaffing?.nikEmployee,
        statusEmployee: !inputDataDocStaffing?.statusEmployee,
        dateStartJoined: !inputDataDocStaffing?.dateStartJoined,
        dateEndJobs:
          inputDataDocStaffing.statusEmployee === "PKWTT"
            ? false
            : !inputDataDocStaffing?.dateEndJobs,
        workPlacement: !inputDataDocStaffing?.workPlacement,
        position: !inputDataDocStaffing?.position,
        grade: !inputDataDocStaffing?.grade,
        departement: !inputDataDocStaffing?.department,
        documentPdf: !docStaffing,
      };

      setIsErrorFormDocStaffing(checkFormDataStaffing);
      const isErrorFormDocStaffing = Object.values(checkFormDataStaffing).some(
        (value) => {
          if (typeof value === "object") {
            return Object.values(value).some((subValue) => subValue);
          }
          return value;
        }
      );
      if (isErrorFormDocStaffing) {
        handleBackToTop();
        setIsErrorForm((err) => ({
          ...err,
          checkFormDataStaffing: true,
        }));
      } else {
        setIsActiveSideMenuProfile(2);
      }
    }
    if (isActiveSideMenuProfile === 2) {
      const checkFormDataDocuments = {
        filesDocument: documentsUpload.length === 0 ? true : false,
      };
      setIsErrorFilesDocument(checkFormDataDocuments);
      if (checkFormDataDocuments.filesDocument) {
        handleBackToTop();
        setIsErrorForm((err) => ({
          ...err,
          checkFormDataFilesDocument: true,
        }));
      } else {
        setIsOpenModal(true);
      }
    }
  };

  const [dataRenderMaritalStatus, setDataRenderMaritalStatus] =
    useState(dataFamilies);

  const addObjectMaritalStatus = useMemo(() => {
    return {
      contactRelations: "",
      name: "",
      placeOfBirth: "",
      dateOfBirth: "",
      nik: "",
      gender: "",
      jobStatus: "",
    };
  }, []);

  useEffect(() => {
    const statusNumber = parseInt(inputDataPersonal.maritalStatus.slice(1));
    if (!isNaN(statusNumber)) {
      const newObjects = Array(statusNumber + 1).fill(addObjectMaritalStatus);
      setDataRenderMaritalStatus(newObjects);
    }
  }, [addObjectMaritalStatus, inputDataPersonal.maritalStatus]);

  useEffect(() => {
    if (!addPrestation) {
      setInputDataPrestation((prev) => [
        {
          ...prev,
          description: "",
          id: "1",
          prestation: "",
          yearOfAchive: "",
        },
      ]);
    }
    if (!isAddDataJob) {
      setInputDataJobs((prev) => [
        {
          ...prev,
          id: "1",
          dateStartJoin: "",
          dateEndJoin: "",
          companyName: "",
          position: "",
          takeSallary: "",
          contactCompany: "",
          jobDesc: "",
        },
      ]);
    }
  }, [addPrestation, isAddDataJob]);

  const nameSessionDataStaffing = "inputDataDocStaffing";
  const savedDataDocStaffing = sessionStorage.getItem(nameSessionDataStaffing);
  const [inputDataDocStaffing, setInputDataDocStaffing] = useState(
    JSON.parse(savedDataDocStaffing) || {
      nikEmployee: "",
      statusEmployee: "",
      dateStartJoined: "",
      dateEndJobs: "",
      workPlacement: "",
      position: "",
      grade: "",
      department: "",
      chartId: 0,
    }
  );

  useEffect(() => {
    sessionStorage.setItem(
      nameSessionDataStaffing,
      JSON.stringify(inputDataDocStaffing)
    );
  }, [inputDataDocStaffing, selectedChart]);

  const nameSessionSelectedChart = "selectedChart";

  const [getNodes, setGetNodes] = useState([]);
  const [chartName, setChartName] = useState("");

  const fetchAllChart = async () => {
    try {
      const { data } = await getAllChart();
      setGetNodes(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllChart();
  }, []);

  const nodeId = sessionStorage.getItem("nodeId") || 0;

  useEffect(() => {
    sessionStorage.setItem(nameSessionSelectedChart, JSON.stringify(chartName));
  }, [chartName, nodeId]);
  // const savedSelectedChart = sessionStorage.getItem(nameSessionSelectedChart);
  // console.log("masuk ga", savedSelectedChart);
  useEffect(() => {
    const chartNames = getNodes?.response?.filter((n) => n?.id === +nodeId);
    for (let i = 0; i < chartNames?.length; i++) {
      const name = chartNames[i];
      setChartName(name.chartName);
      setInputDataDocStaffing((prev) => ({
        ...prev,
        chartId: +nodeId,
        department: name.chartName,
      }));
    }
  }, [
    inputDataDocStaffing.chartName,
    selectedChart,
    nodeId,
    getNodes,
    chartName,
  ]);

  useEffect(() => {
    setInputDataDocStaffing((prev) => ({
      ...prev,
      department: selectedChart.chartName,
      chartId: selectedChart.chartId,
    }));
  }, [selectedChart.chartId, selectedChart.chartName]);

  // post

  const {
    name,
    telephone,
    email,
    nik,
    dateNik,
    placeOfBirth,
    dateOfBirth,
    gender,
    religion,
    bodyHeight,
    bloodType,
    maritalStatus,
    npwp,
    birthMothersName,
    address,
    province,
    regency,
    district,
    village,
    rt,
    rw,
    postCode,
    nameAccountBank,
    bank,
    bankNumber,
    facebook,
    instagram,
    twitter,
    linkedIn,
    hobby,
    clinic,
  } = inputDataPersonal;
  const {
    domicilies,
    dataFamiliesAndContact,
    familiesAndContactNotTheSameHouse,
  } = inputDataPersonal;
  const [profilePicture, setProfilePicture] = useState();
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [isErrorPost, setIsErrorPost] = useState(true);

  const handleCreateEmployee = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phoneNumber", telephone);
    formData.append("email", email);
    formData.append("idNumber", nik);
    formData.append("idNumberExp", isLifeTimeNik ? "SEUMUR HIDUP" : dateNik);
    formData.append("placeOfBirth", placeOfBirth);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("gender", gender);
    formData.append("religion", religion);
    formData.append("bodyHeight", bodyHeight);
    formData.append("bloodType", bloodType);
    formData.append(
      "maritalStatus",
      maritalStatus !== "Belum Menikah" ? "KAWIN" : "Belum Kawin"
    );
    formData.append("taxNumber", npwp);
    formData.append("motherName", birthMothersName);
    formData.append("address", address);
    formData.append("province", province);
    formData.append("city", regency);
    formData.append("district", district);
    formData.append("subDistrict", village);
    formData.append("neighborhood", rt);
    formData.append("ward", rw);
    formData.append("postal", postCode);

    formData.append("domicileAddress", domicilies.address);
    formData.append("domicileProvince", domicilies.province);
    formData.append("domicileCity", domicilies.regency);
    formData.append("domicileDistrict", domicilies.district);
    formData.append("domicileSubDisctrict", domicilies.village);
    formData.append("domicileNeighborhood", domicilies.rt);
    formData.append("domicileWard", domicilies.rw);
    formData.append("domicilePostal", domicilies.postCode);

    formData.append("familyCard", dataFamiliesAndContact.kk);
    formData.append("emergencyContact[0][name]", dataFamiliesAndContact.name);
    formData.append(
      "emergencyContact[0][relation]",
      dataFamiliesAndContact.contactRelations
    );
    formData.append(
      "emergencyContact[0][phoneNumber]",
      dataFamiliesAndContact.telephone
    );
    formData.append(
      "emergencyContact[0][address]",
      dataFamiliesAndContact.address
    );

    formData.append(
      "emergencyContact[1][name]",
      familiesAndContactNotTheSameHouse.name
    );
    formData.append(
      "emergencyContact[1][relation]",
      familiesAndContactNotTheSameHouse.contactRelations
    );
    formData.append(
      "emergencyContact[1][phoneNumber]",
      familiesAndContactNotTheSameHouse.telephone
    );
    formData.append(
      "emergencyContact[1][address]",
      familiesAndContactNotTheSameHouse.address
    );

    formData.append("bankAccountName", nameAccountBank);
    formData.append("bankName", bank);
    formData.append("bankAccountNumber", bankNumber);
    formData.append("bankCode", "123");

    if (facebook) {
      formData.append("facebook", facebook);
    }
    if (twitter) {
      formData.append("twitter", twitter);
    }
    if (instagram) {
      formData.append("instagram", instagram);
    }
    if (linkedIn) {
      formData.append("linkedin", linkedIn);
    }
    if (hobby) {
      formData.append("hobby", hobby);
    }
    formData.append("nearestClinic", clinic);

    if (maritalStatus !== "Belum Menikah") {
      for (let fm = 0; fm < inputDataFamilies.length; fm++) {
        const families = inputDataFamilies[fm];
        formData.append(
          `familyMembers[${fm}][relation]`,
          families.contactRelations
        );
        formData.append(`familyMembers[${fm}][name]`, families.name);
        formData.append(
          `familyMembers[${fm}][birthPlace]`,
          families.placeOfBirth
        );
        formData.append(
          `familyMembers[${fm}][birthDate]`,
          families.dateOfBirth
        );
        formData.append(`familyMembers[${fm}][familyIdNumber]`, families.nik);
        formData.append(`familyMembers[${fm}][gender]`, families.gender);
        formData.append(
          `familyMembers[${fm}][employeementStatus]`,
          families.jobStatus
        );
      }
    }
    for (let st = 0; st < inputDataStudied.length; st++) {
      const studies = inputDataStudied[st];
      formData.append(`userEducations[${st}][type]`, studies.typeStudy);
      formData.append(
        `userEducations[${st}][startYear]`,
        studies.dateStartJoin
      );
      formData.append(`userEducations[${st}][endYear]`, studies.dateEndJoin);
      formData.append(`userEducations[${st}][name]`, studies.nameUniversity);
      formData.append(`userEducations[${st}][mastery]`, studies.faculty);

      formData.append(
        `userEducations[${st}][certificateNumber]`,
        studies.typeStudy === "Formal" ? studies.noDiploma : studies.ipk
      );

      if (studies.typeStudy === "Formal") {
        formData.append(`userEducations[${st}][grade]`, studies.ipk);
      }
      formData.append(`userEducations[${st}][city]`, studies.city);
    }

    if (isAddDataJob) {
      for (let j = 0; j < inputDataJobs.length; j++) {
        const jobs = inputDataJobs[j];
        formData.append(
          `userWorkExperiences[${j}][startYear]`,
          jobs.dateStartJoin
        );
        formData.append(`userWorkExperiences[${j}][endYear]`, jobs.dateEndJoin);
        formData.append(
          `userWorkExperiences[${j}][corpName]`,
          jobs.companyName
        );
        formData.append(`userWorkExperiences[${j}][position]`, jobs.position);
        formData.append(`userWorkExperiences[${j}][salary]`, jobs.takeSallary);
        formData.append(
          `userWorkExperiences[${j}][corpContactNum]`,
          jobs.contactCompany
        );
        formData.append(`userWorkExperiences[${j}][remark]`, jobs.jobDesc);
      }
    }

    if (addPrestation) {
      for (let ps = 0; ps < inputDataPrestation.length; ps++) {
        const prestations = inputDataPrestation[ps];
        formData.append(
          `userAchievements[${ps}][year]`,
          prestations.yearOfAchive
        );
        formData.append(
          `userAchievements[${ps}][achievement]`,
          prestations.prestation
        );
        formData.append(
          `userAchievements[${ps}][remark]`,
          prestations.description
        );
      }
    }

    formData.append("employeeID", inputDataDocStaffing.nikEmployee);
    formData.append("contractType", inputDataDocStaffing.statusEmployee);
    formData.append("joinDate", inputDataDocStaffing.dateStartJoined);
    if (inputDataDocStaffing.statusEmployee !== "PKWTT") {
      formData.append("resignDate", inputDataDocStaffing.dateEndJobs);
    }
    formData.append("area", inputDataDocStaffing.workPlacement);
    formData.append("position", inputDataDocStaffing.position);

    formData.append("grade", inputDataDocStaffing.grade);
    formData.append("department", inputDataDocStaffing.department);
    // formData.append("department", "Staff HR");

    formData.append("chartID", inputDataDocStaffing.chartId);
    // formData.append("chartID", 64);

    if (profilePicture) {
      formData.append("profilePic", profilePicture);
    }
    formData.append("contractDoc", docStaffing);
    documentsUpload.forEach((file) => {
      formData.append("files", file);
    });

    setIsLoadingPost(true);
    formData.forEach((value, key) => {
      console.log(`${key}: `, value);
    });
    try {
      const response = await createManualEmployee(formData);
      if (response?.status === 201) {
        setIsLoadingPost(false);
        setIsErrorPost(false);
        setIsOpenToast(true);
        clearSession();
      }
    } catch (error) {
      console.log(error);
      setIsLoadingPost(false);
      setIsErrorPost(true);
      setIsOpenToast(true);
    }
  };
  // end post

  const childStepToMap = [
    { number: "A", title: "Personal Data" },
    { number: "B", title: "Data Keluarga" },
    { number: "C", title: "Data Pendidikan" },
    { number: "D", title: "Data Pekerjaan" },
    { number: "E", title: "Data Prestasi" },
  ];
  const stepperToMap = [
    {
      number: "1",
      name: "Informasi Karyawan",
      subStep: childStepToMap,
    },
    {
      number: "2",
      name: "Kepegawaian",
    },
    {
      number: "3",
      name: "Unggah Dokumen",
    },
  ];

  return (
    <div className={Styles.mainContainerCenter}>
      <div className={Styles.wrapperAddEmployee}>
        {isOpenToast && (
          <Toast
            text={
              isErrorPost
                ? "Gagal Menambahkan Daftar Karyawan"
                : `${inputDataPersonal.name} Berhasil ditambahkan dalam daftar karyawan`
            }
            onClose={() => setIsOpenToast(false)}
            isopen={isOpenToast}
            color={isErrorPost ? "red" : "green"}
          />
        )}

        {checkFormDataPersonal && (
          <Toast
            text={`Form Data Personal Belum Lengkap`}
            onClose={() =>
              setIsErrorForm((prevError) => ({
                ...prevError,
                checkFormDataPersonal: false,
              }))
            }
            isopen={checkFormDataPersonal}
            color={"red"}
          />
        )}
        {checkFormDataFamilies && (
          <Toast
            text={`Form Data Keluarga Belum Lengkap`}
            onClose={() =>
              setIsErrorForm((prevError) => ({
                ...prevError,
                checkFormDataFamilies: false,
              }))
            }
            isopen={checkFormDataFamilies}
            color={"red"}
          />
        )}
        {checkFormDataStudied && (
          <Toast
            text={`Form Data Pendidikan Belum Lengkap`}
            onClose={() =>
              setIsErrorForm((prevError) => ({
                ...prevError,
                checkFormDataStudied: false,
              }))
            }
            isopen={checkFormDataStudied}
            color={"red"}
          />
        )}
        {checkFormDataJobs && (
          <Toast
            text={`Form Data Pekerjaan Belum Lengkap`}
            onClose={() =>
              setIsErrorForm((prevError) => ({
                ...prevError,
                checkFormDataJobs: false,
              }))
            }
            isopen={checkFormDataJobs}
            color={"red"}
          />
        )}
        {checkFormDataPrestation && (
          <Toast
            text={`Form Data Prestasi Belum Lengkap`}
            onClose={() =>
              setIsErrorForm((prevError) => ({
                ...prevError,
                checkFormDataPrestation: false,
              }))
            }
            isopen={checkFormDataPrestation}
            color={"red"}
          />
        )}
        {checkFormDataStaffing && (
          <Toast
            text={`Form Data Kepegawaian Belum Lengkap`}
            onClose={() =>
              setIsErrorForm((prevError) => ({
                ...prevError,
                checkFormDataStaffing: false,
              }))
            }
            isopen={checkFormDataStaffing}
            color={"red"}
          />
        )}
        {checkFormDataFilesDocument && (
          <Toast
            text={`Dokumen Belum Di Unggah`}
            onClose={() =>
              setIsErrorForm((prevError) => ({
                ...prevError,
                checkFormDataFilesDocument: false,
              }))
            }
            isopen={checkFormDataFilesDocument}
            color={"red"}
          />
        )}

        <div className={Styles.buttonBack}>
          <Icon
            icon={"arrow-down"}
            size={"20"}
            color={"#3E4856"}
            className={Styles.iconBack}
            onClick={() => navigate("/people")}
          />
          <h2>Tambah Karyawan</h2>
        </div>
        {width < 1024 && (
          <section className={Styles.wrapperUploadImage}>
            <div className={Styles.imgMobileInfo}>
              <UploadImage
                reffUploadProfile={reffUploadProfile}
                setProfilePicture={setProfilePicture}
              />
              <div className={Styles.mobileStep}>
                <span>Informasi Karyawan</span>
                <span>Langkah {isActiveSideMenuProfile + 1} / 3</span>
              </div>
            </div>
          </section>
        )}
        <section
          className={Styles.container}
          is-mobile={(width < 1024).toString()}
        >
          {width > 1024 && (
            <div className={Styles.sideProfile}>
              {
                <UploadImage
                  reffUploadProfile={reffUploadProfile}
                  setProfilePicture={setProfilePicture}
                />
              }
              <span className={Styles.line}></span>
              <aside className={Styles.sideMenu}>
                <div className={Styles.wrapFirstStep}>
                  <div className={Styles.dividerStep}></div>
                  <div
                    className={Styles.step1}
                    onClick={() => setIsActiveSideMenuProfile(0)}
                  >
                    <div className={Styles.number}>
                      {isActiveSideMenuProfile > 0 ? (
                        <Icon
                          icon={"tick-circle"}
                          color={"#2E3192"}
                          size={20}
                        />
                      ) : (
                        <span>1</span>
                      )}
                    </div>
                    <span>Informasi Karyawan </span>
                  </div>
                  {childStepToMap.map((s, idx) => (
                    <div
                      className={Styles.childStep}
                      is-active={(step >= idx).toString()}
                      key={idx}
                      onClick={() => setStep(idx)}
                    >
                      <div className={Styles.numChild}>
                        {step > idx || isActiveSideMenuProfile >= 1 ? (
                          <Icon
                            icon={"tick-circle"}
                            color={"#2E3192"}
                            size={"16"}
                          />
                        ) : (
                          <span>{s.number}</span>
                        )}
                      </div>
                      <span>{s.title}</span>
                    </div>
                  ))}
                </div>
                {stepperToMap.slice(1, 3).map((el, idx) => (
                  <div
                    className={Styles.step2}
                    key={idx}
                    is-active={(isActiveSideMenuProfile >= idx).toString()}
                    onClick={() => setIsActiveSideMenuProfile(idx + 1)}
                  >
                    {idx !== stepperToMap?.length - 2 && (
                      <div className={Styles.dividerStep}></div>
                    )}
                    <div
                      className={Styles.number}
                      style={
                        isActiveSideMenuProfile >= idx + 1
                          ? { background: "#F0F0FF" }
                          : { undefined }
                      }
                    >
                      {isActiveSideMenuProfile > idx + 1 ||
                      documentsUpload.length > 0 ? (
                        <Icon
                          icon={"tick-circle"}
                          color={"#2E3192"}
                          size={"20"}
                        />
                      ) : (
                        <span
                          style={
                            isActiveSideMenuProfile >= idx + 1
                              ? { color: "#2E3192" }
                              : { undefined }
                          }
                        >
                          {el?.number}
                        </span>
                      )}
                    </div>
                    <span
                      style={
                        isActiveSideMenuProfile >= idx + 1
                          ? { color: "#2E3192" }
                          : { undefined }
                      }
                    >
                      {el.name}
                    </span>
                  </div>
                ))}
              </aside>
            </div>
          )}
          <div className={Styles.lineSeparator}></div>
          <div className={Styles.mainContent}>
            <Modal isOpen={isLoadingPost}>
              <div className={Styles.viewLoadingCreate}>
                <Lottie
                  animationData={LoadingJSON}
                  loop
                  className={Styles.loadingElement}
                  alt="loading"
                />
              </div>
            </Modal>
            <div className={Styles.wrapperInput}>
              {step === 0 && (
                <div className={Styles.sticky}>
                  <h2>Informasi Pribadi</h2>
                  <div className={Styles.divider}></div>
                </div>
              )}
              {isActiveSideMenuProfile === 0 ? (
                <>
                  {step === 0 ? (
                    <PersonalData
                      inputDataPersonal={inputDataPersonal}
                      setInputDataPersonal={setInputDataPersonal}
                      isLifeTimeNik={isLifeTimeNik}
                      setIsLifeTimeNik={setIsLifeTimeNik}
                      isTheSameAddress={isTheSameAddress}
                      setIsTheSameAddress={setIsTheSameAddress}
                      topRef={topRef}
                      isErrorFormPersonalData={isErrorFormPersonalData}
                      setIsErrorFormPersonalData={setIsErrorFormPersonalData}
                    />
                  ) : step === 1 ? (
                    inputDataPersonal?.maritalStatus === "K0" ||
                    inputDataPersonal?.maritalStatus === "K1" ||
                    inputDataPersonal?.maritalStatus === "K2" ||
                    inputDataPersonal?.maritalStatus === "K3" ||
                    inputDataPersonal?.maritalStatus === "K4" ? (
                      dataRenderMaritalStatus.map((_, idx) => (
                        <DataFamilies
                          key={`data-families-${idx}`}
                          topRef={topRef}
                          inputDataFamilies={inputDataFamilies}
                          setInputDataFamilies={setInputDataFamilies}
                          idx={idx}
                          maritalStatus={inputDataPersonal.maritalStatus}
                          isErrorFieldFamilies={isErrorFieldFamilies}
                          setIsErrorFieldFamilies={setIsErrorFieldFamilies}
                        />
                      ))
                    ) : (
                      <div
                        className={Styles.containInput}
                        style={{ height: "100%" }}
                      >
                        <div className={Styles.badgeInfo} ref={topRef}>
                          <img src={badgeInfo} alt="badge" />
                          <span>Status kawin : Belum Kawin</span>
                        </div>
                        <EmptyHandlerFamily />
                      </div>
                    )
                  ) : step === 2 ? (
                    inputDataStudied?.map((ds, idx) => (
                      <DataStudied
                        key={`form-data-studied-${idx}`}
                        idx={idx}
                        inputDataStudied={inputDataStudied}
                        setInputDataStudied={setInputDataStudied}
                        handleAddStudied={() => handleAddDataStudied()}
                        handleDeleteFormStudied={() =>
                          handleDeleteFormStudied(ds?.id)
                        }
                        isErrorFormStudied={isErrorFormStudied}
                        setIsErrorFormStudied={setIsErrorFormStudied}
                        topRef={topRef}
                      />
                    ))
                  ) : step === 3 ? (
                    <>
                      {isAddDataJob ? (
                        inputDataJobs?.map((el, idx) => (
                          <FormDataJobs
                            key={`form-data-jobs-${idx}`}
                            handleDeleteFormJob={() =>
                              handleDeleteFormJobs(el?.id)
                            }
                            inputDataJobs={inputDataJobs}
                            setInputDataJobs={setInputDataJobs}
                            idx={idx}
                            handleAddFormJobs={() => handleisAddDataJobs()}
                            setIsAddDataJobs={() => setIsAddDataJob(false)}
                            isErrorFormJobs={isErrorFormJobs}
                            setIsErrorFormJobs={setIsErrorFormJobs}
                            topRef={topRef}
                          />
                        ))
                      ) : (
                        <section className={Styles.containInput}>
                          <AddOption
                            onClick={() => setIsAddDataJob(!isAddDataJob)}
                            title="Tambah Data Pekerjaan"
                            paragraph="Anda dapat menambah pengalaman pekerjaan, jika belum memiliki, anda dapat melanjutkan ke tahap berikutnya tanpa menambah pengalaman kerja"
                            buttonText="Tambah Pekerjaan"
                          />
                        </section>
                      )}
                    </>
                  ) : (
                    <>
                      {addPrestation ? (
                        inputDataPrestation?.map((ps, idx) => (
                          <FormDataPrestation
                            key={`form-data-prestation-${idx}`}
                            inputDataPrestation={inputDataPrestation}
                            setInputDataPrestation={setInputDataPrestation}
                            handleAddFormPrestation={handleAddDataPrestation}
                            handleDeleteFormPrestation={() =>
                              handleDeleteFormPrestation(ps?.id)
                            }
                            idx={idx}
                            setAddPrestation={() => setAddPrestation(false)}
                            isErrorFormPrestation={isErrorFormPrestation}
                            setIsErrorFormPrestation={setIsErrorFormPrestation}
                            topRef={topRef}
                          />
                        ))
                      ) : (
                        <section className={Styles.containInput}>
                          <AddOption
                            onClick={() => setAddPrestation(true)}
                            title="Tambah Data Prestasi"
                            paragraph="Tambahkan data prestasi, jika belum memiliki, anda dapat melanjutkan ke tahap berikutnya"
                            buttonText="Tambah Prestasi"
                            imageIcon={starPrestation}
                          />
                        </section>
                      )}
                    </>
                  )}
                </>
              ) : isActiveSideMenuProfile === 1 ? (
                <FormDataStaffing
                  docStaffing={docStaffing}
                  progressDocStaffing={progressDocStaffing}
                  reffDocSatffing={reffDocSatffing}
                  handleUploadFileStaffing={handleUploadFileStaffing}
                  setDocStaffing={setDocStaffing}
                  inputDataDocStaffing={inputDataDocStaffing}
                  setInputDataDocStaffing={setInputDataDocStaffing}
                  isErrorFormDocStaffing={isErrorFormDocStaffing}
                  setIsErrorFormDocStaffing={setIsErrorFormDocStaffing}
                  topRef={topRef}
                  setIsDocStaffing={setDocStaffing}
                  selectedChart={selectedChart}
                  setSelectedChart={setSelectedChart}
                />
              ) : (
                <FormDataUploadDocument
                  documentsUpload={documentsUpload}
                  handleChangeUploadDocument={handleChangeUploadDocument}
                  handleRemoveDocument={handleRemoveDocument}
                  reffUploadDocument={reffUploadDocument}
                  uploadProgress={uploadProgress}
                  setIsErrorFilesDocument={setIsErrorFilesDocument}
                  isErrorFilesDocument={isErrorFilesDocument}
                  topref={topRef}
                />
              )}
              <Modal isOpen={isOpenModal}>
                <section className={Styles.containerModal}>
                  <div className={Styles.modalAddEmployee}>
                    <div className={Styles.header}>
                      <div>
                        <Icon
                          icon={"message-question"}
                          color={"#EED202"}
                          size={"24"}
                        />
                        <h2>Simpan Data Karyawan Baru</h2>
                      </div>
                      <Icon
                        icon={"close-circle"}
                        color={"#3E4856"}
                        size={"14"}
                        className={Styles.closeModal}
                        onClick={() => setIsOpenModal(false)}
                      />
                    </div>
                    <p>
                      Apakah anda yakin data karyawan telah terisi dengan benar?
                      Lanjutkan untuk menyimpan data karyawan ini.
                    </p>
                    <div className={Styles.buttonModal}>
                      <Button
                        text="Kembali"
                        style={{ background: "white", color: "#2E3192" }}
                        isborder
                        onClick={() => setIsOpenModal(false)}
                      />
                      <Button
                        text="Ya,Simpan"
                        onClick={() => {
                          handleCreateEmployee();
                          setIsOpenModal(false);
                        }}
                      />
                    </div>
                  </div>
                </section>
              </Modal>
              <div className={Styles.nextAndPrevButton}>
                {step > 0 && (
                  <Button
                    style={{ background: "white", color: "#2E3192" }}
                    isborder
                    text="Kembali"
                    onClick={() => {
                      isActiveSideMenuProfile === 0 && setStep(step - 1);
                      handleBackToTop();
                      isActiveSideMenuProfile > 0 &&
                        setIsActiveSideMenuProfile(isActiveSideMenuProfile - 1);
                    }}
                    isDisabled={step === 0}
                  />
                )}
                <Button
                  text={
                    isActiveSideMenuProfile === 2 ? "Simpan" : "Selanjutnya"
                  }
                  onClick={() => {
                    step === 3 && !isAddDataJob
                      ? setStep((prev) => prev + 1)
                      : handleNextValidate();

                    step === 4 &&
                      !addPrestation &&
                      isActiveSideMenuProfile === 0 &&
                      setIsActiveSideMenuProfile(1);

                    step === 1 &&
                      inputDataPersonal.maritalStatus === "Belum Menikah" &&
                      setStep((prev) => prev + 1);

                    isActiveSideMenuProfile === 1 && handleNextValidate();
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
const AddOption = ({
  title = "placeholder",
  paragraph = "lorem",
  imageIcon = briefCase,
  buttonText = "placeholder",
  onClick = () => {},
}) => {
  const { width } = useWindowSize();
  return (
    <div
      className={Styles.wrapperAddOption}
      style={{ padding: width < 1024 ? "0" : "100px" }}
    >
      <div className={Styles.isAddOption}>
        <div>
          <img src={imageIcon} alt="" />
          <h4>{title}</h4>
          <p>{paragraph}</p>
        </div>
        <Button
          style={{
            width: "100%",
            justifyContent: "center",
            background: "white",
            color: "#2E3192",
          }}
          isAddButton
          isborder
          text={buttonText}
          onClick={onClick}
          icon={"add-circle"}
          colorIcon={"#2E3192"}
        />
      </div>
    </div>
  );
};

const UploadImage = ({ reffUploadProfile, setProfilePicture = () => {} }) => {
  const handleClickUploadProfile = () => reffUploadProfile?.current?.click();
  const [previewImage, setPreviewImage] = useState();
  const handleChangeProfile = (e) => {
    const file = e?.target?.files[0];
    setProfilePicture(file);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
    } else {
      setPreviewImage(null);
    }
  };
  return (
    <div
      className={Styles.imageProfile}
      onClick={handleClickUploadProfile}
      is-choose={previewImage ? "true" : "false"}
    >
      <img
        className={previewImage ? Styles.photos : undefined}
        src={previewImage ? previewImage : gallery}
        alt="upload-profil-picture"
        title="photo-profile"
      />
      <div>
        <Icon icon={"camera"} color={"#fff"} size={"24"} />
      </div>
      <input
        type="file"
        ref={reffUploadProfile}
        hidden
        onChange={handleChangeProfile}
        accept=".jpg,.png,.jpeg"
      />
    </div>
  );
};
