import React, { useContext, useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import InputField from "@Atom/InputField";
import Button from "@Atom/Button";
import { ModalContext } from "Context/ModalContetx";
import { makeRandomNumber } from "helpers/makeRandomNumber";
import { getDataByNames } from "@Services/employee/getAllEmployee";
import { addMember } from "@Services/organization/addMember";
import Toast from "@Atom/Toast";
import Lottie from "lottie-react";
import LoadingJSON from "@Assets/json/loading.json";
import Images from "@Theme/Images";

export default function AddMembers({
  id,
  handleSubmit,
  isOpenAdd = false,
  onCloseAdd = () => {},
}) {
  const { isOpenFieldMember, setIsOpenFieldmember } = useContext(ModalContext);

  const [inputDataMembers, setInputDataMembers] = useState([
    {
      id: makeRandomNumber(6),
      name: "",
      otherData: {
        position: "",
        imageURL: "",
      },
    },
    {
      id: makeRandomNumber(6),
      name: "",
      otherData: {
        position: "",
        imageURL: "",
      },
    },
  ]);

  const [names, setNames] = useState([]);
  const [isChoose, setIsChoose] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(null);
  const [isSuccessAddMember, setIsSuccessAddMember] = useState(false);
  const [isErrorAddMember, setIsErrorAddMember] = useState(false);
  const [isLoadingAddMember, setIsLoadingAddMember] = useState(false);

  const fetchListAutoComplete = async (query) => {
    try {
      const response = await getDataByNames(query);
      if (response.status === 200) {
        const getNames = response.data.data.map((item) => ({
          id: item.idNumber,
          name: item.name,
          imageURL:
            item.imageURL === "" ||
            item.imageURL === "-" ||
            item.imageURL === "_"
              ? Images.AVA_DEFAULT
              : item.imageURL,
          position: item.contracts[0]?.position || "-",
        }));

        setNames(getNames);
      }
      // if (response.status === 200) {
      //   const getNames = response.data.data.map((item) => ({
      //     id: item.idNumber,
      //     name: item.name,
      //     imageURL: item.imageURL,
      //     position: item.contracts?.position,
      //   }));
      //   setNames(getNames);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchListAutoComplete();
  }, []);

  const handleChangeInput = async (id, value) => {
    setIsChoose(false);
    setInputDataMembers((prev) =>
      prev.map((member) =>
        member.id === id ? { ...member, name: value } : member
      )
    );
    if (value) {
      fetchListAutoComplete(value);
    }
  };

  const handleClickName = (id, value) => {
    setIsChoose(true);
    setInputDataMembers((prev) =>
      prev.map((member) =>
        member.id === id
          ? { ...member, name: value.name, otherData: value }
          : member
      )
    );
  };

  const handleAddInput = () => {
    fetchListAutoComplete();
    setInputDataMembers([
      ...inputDataMembers,
      {
        id: makeRandomNumber(6),
        name: "",
        otherData: { position: "", imageURL: "" },
      },
    ]);
  };

  const handleDeleteInput = (id) => {
    setInputDataMembers(inputDataMembers.filter((member) => member.id !== id));
  };

  const handleDataToSend = () => {
    const filteredMembers = inputDataMembers.filter((el) => el.name !== "");
    const sendData = filteredMembers.map((member) => ({
      id: member.otherData.id,
      name: member.name,
      imageURL: member.otherData.imageURL,
      position: member.otherData.position,
    }));

    handleSubmit(sendData);

    setTimeout(() => {
      setInputDataMembers((prev) =>
        prev.map((el) => ({
          ...el,
          name: "",
          id: makeRandomNumber(6),
        }))
      );
    }, 500);
  };

  const handleAddMembers = async () => {
    setIsLoadingAddMember(true);
    const dataToSend = new URLSearchParams();
    const filteredMembers = inputDataMembers.filter((el) => el.name !== "");
    for (let m = 0; m < filteredMembers.length; m++) {
      const members = filteredMembers[m];
      dataToSend.append(`chartMembers[member][${m}]`, members.otherData.id);
    }
    try {
      const res = await addMember(id, dataToSend);
      if (res.status === 201) {
        handleDataToSend();
        setIsSuccessAddMember(true);
        setIsLoadingAddMember(false);
      }
    } catch (error) {
      console.log(error);
      setIsErrorAddMember(true);
      setIsLoadingAddMember(false);
    }
  };

  const onClose = () => {
    setIsOpenFieldmember(!isOpenFieldMember);
    onCloseAdd(!isOpenAdd);
  };

  setTimeout(() => {
    if (isSuccessAddMember || isErrorAddMember) {
      setIsSuccessAddMember(false);
      setIsErrorAddMember(false);
    }
  }, 5000);

  return (
    <>
      <Toast
        isopen={isSuccessAddMember || isErrorAddMember}
        text={
          isSuccessAddMember
            ? "Member berhasil di tambahkan"
            : "Member gagal di tambahkan"
        }
        color={isSuccessAddMember ? "green" : "red"}
        onClose={() => {
          setIsSuccessAddMember(false);
          setIsErrorAddMember(false);
        }}
      />

      <Modal isOpen={isLoadingAddMember}>
        <div className={Styles.viewLoadingCreate}>
          <Lottie
            animationData={LoadingJSON}
            loop
            className={Styles.loadingElement}
            alt="loading"
          />
        </div>
      </Modal>
      <Modal isOpen={isOpenFieldMember === id || isOpenAdd}>
        <div
          className={Styles.containerAddField}
          style={{ maxHeight: "90%", overflowY: "auto" }}
        >
          <div className={Styles.header}>
            <span>Tambah Member</span>
            <Icon
              icon={"close-circle"}
              className={Styles.iconHead}
              size={"12"}
              onClick={onClose}
            />
          </div>
          <div className={Styles.inputField}>
            {inputDataMembers.map((el) => (
              <div className={Styles.fieldMemberOther} key={el.id}>
                <InputField
                  placeholder="Masukkan nama karyawan"
                  value={el.name}
                  setValue={(e) => handleChangeInput(el.id, e)}
                  onClick={() => setIsOpenDropdown(el.id)}
                  isAutoComplete={isOpenDropdown === el.id}
                  closeAutoComplete={() => setIsOpenDropdown(null)}
                  setAutoComplete={(val) => handleClickName(el.id, val)}
                  listAutoComplete={names}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsOpenDropdown(false);
                    }, 200);
                    clearTimeout();
                  }}
                />
                <Icon
                  icon={"trash"}
                  className={`${
                    inputDataMembers.length === 1
                      ? Styles.icon
                      : Styles.deleteIcon
                  } `}
                  color={inputDataMembers.length === 1 ? "#A9B3C1" : "black"}
                  size={16}
                  onClick={() =>
                    inputDataMembers.length === 1
                      ? () => {}
                      : handleDeleteInput(el.id)
                  }
                />
              </div>
            ))}
            <div className={Styles.btnAddMembers} onClick={handleAddInput}>
              <span>Tambah Member</span>
              <Icon icon={"add-circle"} size={16} className={Styles.iconAdd} />
            </div>
          </div>
          <div className={Styles.footField}>
            <Button text="Kembali" onClick={onClose} />
            <Button
              text="Simpan"
              onClick={() => {
                onClose();
                handleAddMembers();
              }}
              isDisabled={!inputDataMembers[0].name || !isChoose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
