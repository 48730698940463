import PayrollSectionCard from '@Atom/PayrollSectionCard'
import Styles from './style.module.scss'
import Skeleton from '@Atom/Skeleton'

export default function InfoSection({
  schemeData,
  isLoadingScheme
}) {

  return (
    <PayrollSectionCard
      title='Informasi Umum'
    >
      <div className={Styles.infos}>
        <InfoDesc label='Client' isLoading={isLoadingScheme} desc={schemeData?.client} loadingWidth='300px' />
        <InfoDesc label='Dokumen' isLoading={isLoadingScheme} desc={schemeData?.document} loadingWidth='250px' />
        <InfoDesc label='Payroll Schema' isLoading={isLoadingScheme} desc={schemeData?.payrollScheme} loadingWidth='350px' />
        <InfoDesc label='Tax Schema' isLoading={isLoadingScheme} desc={schemeData?.taxScheme} loadingWidth='200px' />
        <InfoDesc label='Tax Payer' isLoading={isLoadingScheme} desc={schemeData?.taxPayer} loadingWidth='150px' />
      </div>
    </PayrollSectionCard>
  )
}

const InfoDesc = ({
  label = '',
  desc = '',
  isLoading = false,
  loadingWidth = 'auto'
}) => {

  return (
    <div className={Styles.infoDesc}>
      <span>{label}</span>
      {
        isLoading
          ?
          <Skeleton width={loadingWidth} />
          :

          <span>{desc || '-'}</span>
      }
    </div>
  )

}