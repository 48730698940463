import { API } from "configs";

export function getAllChart() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`/orgchart/?corpID=corp-0000001`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
