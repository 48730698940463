import React, { useEffect, useRef } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";
export default function DropdownMoreCircle({
  openChoose,
  setOopenChoose = () => {},
  id,
  setIsOpenModalArchive = () => {},
  setIsModalDelete = () => {},
  tabs = 0,
}) {
  const handleOpenDropdown = () => {
    if (openChoose === id) {
      setOopenChoose(null);
    } else {
      setOopenChoose(id);
    }
  };
  const navigate = useNavigate();
  const dropdownItems = [
    {
      icon: "archive",
      title: tabs === 1 ? "Aktifkan Karyawan" : "Non Aktifkan Karyawan",
      onClick: () => setIsOpenModalArchive(true),
    },
    {
      icon: "edit-2",
      title: "Edit Detail",
      onClick: () => navigate("edit/" + id),
    },
    // {
    //   icon: "trash",
    //   title: "Hapus",
    //   onClick: () => setIsModalDelete(true),
    // },
  ];

  const reff = useRef();
  const boxReff = useOutsideClick(reff);
  useEffect(() => {
    if (boxReff && openChoose) {
      setOopenChoose(false);
    }
  }, [boxReff, openChoose, setOopenChoose]);
  return (
    <div className={Styles.containerDropdownMoreCircle}>
      <div
        className={Styles.target}
        onClick={handleOpenDropdown}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Icon icon={"more-circle"} color={"#2E3192"} size={16} />
      </div>
      {openChoose === id && (
        <div className={Styles.wrapperDropdownMoreCirle} ref={reff}>
          {dropdownItems.map((item, idx) => (
            <div
              key={idx}
              onClick={item.onClick}
              // style={{ marginBottom: idx === 1 ? "8px" : "unset" }}
            >
              <Icon icon={item.icon} size={14} color={"#2E3192"} />
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
