import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import SearchBar from '@Atom/SearchBar'
import { useEffect, useMemo, useState } from 'react'
import Table from '@Molecule/Table'
import useQuery from '@Hooks/useQuery'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDebounce } from '@Hooks/useDebounce'
import Rotation from '@Atom/Rotation'
import ErrorBubble from '@Atom/ErrorBubble'

export default function SelectClientLayout({ packageClientList, isLoading }) {
  const query = useQuery()
  const [page, setPage] = useState(query?.get('p') ? +query?.get('p') : 1)
  const [limit, setLimit] = useState(query?.get('l') ? +query?.get('l') : 10)
  const [error, setError] = useState('')
  const [search, setSearch] = useState('')




  return (
    <div className={Styles.container}>
      <SelectCard
        packageClientList={packageClientList}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        error={error}
        setError={setError}
        search={search}
        setSearch={setSearch}
      />
    </div>
  )
}

export const SelectCard = ({ packageClientList, isLoading, isModal = false, page, setPage, limit, setLimit, error, setError, search, setSearch, onSuccess = () => { } }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedClient, setSelectedClient] = useState(null)
  const [loadingType, setLoadingType] = useState('')
  const columns = [
    {
      name: 'legalName',
      title: 'Nama Klien',
      width: '220px'
    },
    {
      name: 'address',
      title: 'Lokasi',
      width: '150px'
    },
    {
      name: '',
      title: '',
      width: '50px',
      align: 'left',
      skeleton: <></>,
      renderData: (row) => (
        <div>
          <Icon icon={row?.id === selectedClient?.id ? 'check' : ''} size={24} color={'#2E3192'} />
        </div>
      )
    }
  ]
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch])

  useEffect(() => {
    if (!isModal) {
      navigate(`${pathname}?p=${page}&l${limit}&s=${debouncedSearch}`, { replace: true })
    }
  }, [debouncedSearch, isModal, limit, navigate, page, pathname])

  const data = useMemo(() => {
    return packageClientList?.data?.length ? packageClientList?.data?.map(obj => {
      return {
        ...obj,
        className: Styles[obj?.id === selectedClient?.id ? 'selected' : ""]
      }
    }) : []
  }, [packageClientList?.data, selectedClient?.id])

  const loadingTemplate = useMemo(() => {
    switch (loadingType) {
      case 'loading':
        return {
          logo: <Rotation width='40px' type='blue' />,
          text: 'Memuat data...'
        }
      case 'success':
        return {
          logo: <Icon icon={'tick-circle'} size={45} color={'#22970F'} />,
          text: 'Data berhasil dimuat'
        }
      default:
        return {}
    }
  }, [loadingType])

  const handleSubmit = () => {
    if (loadingType !== 'success') {
      if (selectedClient) {
        setLoadingType('loading')
        localStorage.setItem('clientCode', selectedClient?.abbreviation)
        localStorage.setItem('clientName', selectedClient?.legalName)
        localStorage.setItem('corpID', selectedClient?.corpID)
        setTimeout(() => setLoadingType('success'), 1000)
      } else {
        setError('Klik salah satu klien terlebih dahulu')
      }

    } else {
      navigate('/')
      onSuccess()
    }
  }

  useEffect(() => {
    setError('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient])

  return (
    <div className={Styles.card}>
      <div className={Styles.header}>
        <Icon icon={'building'} size={24} color={'#3E4856'} />
        <h3>Pilih Klien</h3>
      </div>
      {
        !loadingType
          ?
          <div className={Styles.content}>
            <span>Silahkan pilih pada daftar klien yang tersedia untuk melanjutkan.</span>
            <SearchBar
              value={search}
              setValue={setSearch}
              placeholder='Cari klien'
            />
            <Table
              data={data}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalData={packageClientList?.totalData}
              totalPage={packageClientList?.totalPage}
              columns={columns}
              withNumbering
              withPagination
              isLoading={isLoading}
              className={Styles.table}
              onClickRow={(row) => setSelectedClient(row)}
            />
          </div>
          :
          <div className={Styles.loadingContent}>
            {loadingTemplate?.logo}
            <span>{loadingTemplate?.text}</span>
            <div className={Styles.clientName}>
              <Icon icon={'building'} size={20} />
              <span>{selectedClient?.legalName}</span>
            </div>
          </div>
      }
      {
        error
        &&
        <div className={Styles.errorWrapper}>
          <ErrorBubble
            errorText={error}
            noTip
            isV2
          />
        </div>
      }
      {
        loadingType !== 'loading'
        &&
        <div className={Styles.actions}>
          <button onClick={handleSubmit}>Lanjut</button>
        </div>

      }


    </div>
  )
}