export const makeRandomNumber = (length = 5) => {
  let result = "";
  const digits = "0123456789";
  const digitsLength = digits.length;
  let counter = 0;
  while (counter < length) {
    result += digits.charAt(Math.floor(Math.random() * digitsLength));
    counter += 1;
  }
  return Number(result);
};
