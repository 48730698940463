import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import moment from "moment";
export default function DateListTable({
  dateIn = "30 Agt 2023",
  dateOut = "30 Agt 2023",
}) {
  return (
    <div className={Styles.wrapperDateListTable}>
      <div className={Styles.wrapperText}>
        <div>
          <Icon icon={"succes-arrow"} size={"12"} color={"#22970F"} />
          <span> {moment(dateIn).format("ll")} </span>
        </div>
        <div>
          <Icon icon={"arrow-danger-left"} size={"12"} color={"#D42701"} />
          <span> {dateOut === "-" ? "-" : moment(dateOut).format("ll")}</span>
        </div>
      </div>
    </div>
  );
}
