import React, { Fragment, useEffect, useId, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
import popoverTooltip from "@Assets/Icon/popoverTooltip.svg";
import { makeRandomString } from "helpers/makeRandomString";
// import { fileBaseUrl } from "configs";

export default function InputField({
  isDisabled,
  isError = false,
  textError = "Error Message",
  isRequired,
  isLabel,
  textLabel = "Text Label",
  placeholder = "Placeholder",
  isDropdown,
  isLeftDropdown,
  isPercentage,
  isTextArea,
  dataMapDropdown = [
    "placeholder 1",
    "placeholder 2",
    "placeholder 3",
    "placeholder 4",
  ],
  value = "",
  setValue = () => {},
  type = "text",
  isMoney = false,
  isNumber = false,
  name = "",
  isButtonChart = false,
  isAutoComplete = false,
  isLoading = false,
  listAutoComplete = [
    {
      id: "",
      imageURL: "https://placehold.co/14x14",
      name: "silas",
      position: "Posisi",
    },
    {
      id: "",
      imageURL: "https://placehold.co/14x14",
      name: "papare",
      position: "Posisi",
    },
  ],
  closeAutoComplete = () => {},
  setAutoComplete = (name) => {},
  affix = null, //prefix, suffix, both  || tested on textfield & dropdown
  prefix,
  suffix,
  ...props
}) {
  const handleTypeMoney = (value) => {
    if (isMoney) {
      const isNumber = value.split(".").join("");
      if (+isNumber) {
        setValue(parseInt(isNumber).toLocaleString("id-ID"));
      } else if (value === "") {
        setValue("");
      }
    }
  };

  const handleChange = (e) => {
    if (isNumber) {
      const value = e?.target?.value;
      if (/^[+-]?\d*\.?\d*$/.test(value)) {
        setValue(value);
      }
    } else if (isDropdown) {
      setValue(e);
      setIsOpenDropdown(false);
    } else {
      setValue(e?.target?.value);
    }
  };

  const fieldInput = useId();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const handleChangeDropdown = () => {
    if (isDisabled) {
      return null;
    } else {
      setIsOpenDropdown(!isOpenDropdown);
    }
  };
  const boxRef = useRef();
  const boxOutsideClick = useOutsideClick(boxRef);
  useEffect(() => {
    if (boxOutsideClick && isOpenDropdown) {
      setIsOpenDropdown(false);
    }
  }, [boxOutsideClick, isOpenDropdown]);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const autoCompleteRef = useRef(null);
  const prefixRef = useRef(null);
  const suffixRef = useRef(null);

  useEffect(() => {
    const updatePaddingAndHeight = (ref1, ref2, paddingProp, heightProp) => {
      if (ref1.current && ref2.current) {
        const width = ref1.current.offsetWidth;
        const height = ref2.current.offsetHeight;
        ref2.current.style.setProperty(paddingProp, `${width + 10}px`);
        ref1.current.style.setProperty(heightProp, `${height}px`);
      }
    };

    const updateCustomWidth = () => {
      updatePaddingAndHeight(
        prefixRef,
        inputRef,
        "--prefix-padding",
        "--main-height"
      );
      updatePaddingAndHeight(
        suffixRef,
        inputRef,
        "--suffix-padding",
        "--main-height"
      );
      updatePaddingAndHeight(
        prefixRef,
        dropdownRef,
        "--prefix-padding",
        "--main-height"
      );
      updatePaddingAndHeight(
        suffixRef,
        dropdownRef,
        "--suffix-padding",
        "--main-height"
      );
    };

    updateCustomWidth();
    window.addEventListener("resize", updateCustomWidth);

    return () => {
      window.removeEventListener("resize", updateCustomWidth);
    };
  }, []);

  return (
    <div className={Styles.wrapperInputField}>
      {isLabel && (
        <label htmlFor={fieldInput}>
          <p className={Styles.text}>{textLabel}</p>
          {isRequired && <span>*</span>}
        </label>
      )}
      <div
        className={Styles.mainInput}
        ispercentage={isPercentage?.toString()}
        istext-area={isTextArea?.toString()}
        affix={affix}
      >
        {isTextArea ? (
          <textarea
            id={fieldInput}
            type={type}
            placeholder={placeholder}
            isvalue={value ? "true" : "false"}
            value={value}
            onChange={
              isMoney ? (e) => handleTypeMoney(e?.target?.value) : handleChange
            }
            className={Styles.textField}
            isdisabled={isDisabled?.toString()}
            disabled={isDisabled}
            iserror={isError?.toString()}
            isdropdown={isDropdown?.toString()}
            {...props}
            name={name}
            cols="30"
            rows="10"
          >
            {isError && (
              <div className={Styles.errorTextArea}>
                <Icon
                  icon={"info-circle"}
                  style={{ flexShrink: "0" }}
                  size={"16"}
                  color={"#D42701"}
                />
                <span>{textError}</span>
                <img src={popoverTooltip} alt="error-chevron" />
              </div>
            )}
          </textarea>
        ) : (
          <input
            id={fieldInput}
            type={type}
            placeholder={placeholder}
            isvalue={value ? "true" : "false"}
            value={value}
            onChange={
              isMoney ? (e) => handleTypeMoney(e?.target?.value) : handleChange
            }
            className={Styles.textField}
            isdisabled={isDisabled?.toString()}
            disabled={isDisabled}
            iserror={isError?.toString()}
            isdropdown={isDropdown?.toString()}
            name={makeRandomString(5) + "field"}
            ref={inputRef}
            {...props}
          />
        )}
        {isAutoComplete && (
          <div className={Styles.dropdownAuto}>
            {listAutoComplete.map((el, idx) => (
              <div
                className={Styles.autoComplete}
                onClick={() => {
                  closeAutoComplete();
                  setAutoComplete(el);
                }}
                key={idx}
              >
                <div className={Styles.namesAndPhoto}>
                  <img src={el.imageURL} alt="profile-picture" />
                  <span>{el.name}</span>
                </div>
                <div className={Styles.position}>
                  <span>{el.position}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        {isDropdown && (
          <div className={Styles.dropdown} ref={dropdownRef} {...props}>
            <div
              className={Styles.wrapper}
              isopen={isOpenDropdown?.toString()}
              onClick={handleChangeDropdown}
              isleft={isLeftDropdown?.toString()}
              isdisabled={isDisabled?.toString()}
              ref={boxRef}
              ischooseitems={value ? "true" : "false"}
              iserror={isError?.toString()}
            >
              <span ischooseitems={value ? "true" : "false"}>
                {value || placeholder}
              </span>
              {isButtonChart ? (
                <div className={Styles.btnChart}>
                  <span>
                    <span>Pilih</span> <span>Chart</span>
                  </span>
                </div>
              ) : (
                <Icon
                  icon={"arrow-down"}
                  size={"16"}
                  className={Styles.chevron}
                  isopen={isOpenDropdown?.toString()}
                />
              )}
            </div>
            {!isButtonChart && (
              <div
                className={Styles.dropdownItems}
                isopen={isOpenDropdown?.toString()}
              >
                <ul is-loading={isLoading?.toString()}>
                  {isLoading
                    ? [1, 2, 3, 4].map((_, idx) => (
                        <Fragment key={idx}>
                          <li></li>
                        </Fragment>
                      ))
                    : dataMapDropdown?.map((item, idx) => (
                        <li key={idx} onClick={() => handleChange(item)}>
                          {item}
                        </li>
                      ))}
                </ul>
              </div>
            )}
          </div>
        )}
        {affix === "prefix" || affix === "both" ? (
          <div
            className={Styles.prefixWrap}
            isError={isError?.toString()}
            ref={prefixRef}
          >
            {prefix}
          </div>
        ) : (
          <></>
        )}
        {affix === "suffix" || affix === "both" ? (
          <div
            className={Styles.suffixWrap}
            isError={isError?.toString()}
            ref={suffixRef}
          >
            {suffix}
          </div>
        ) : (
          <></>
        )}
        {isError && (
          <div className={Styles.error}>
            <Icon
              icon={"info-circle"}
              style={{ flexShrink: "0" }}
              size={"16"}
              color={"#D42701"}
            />
            <span>{textError}</span>
            <img src={popoverTooltip} alt="error-chevron" />
          </div>
        )}
      </div>
    </div>
  );
}
