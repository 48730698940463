import React, { useContext, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { Handle, Position } from "@xyflow/react";
import Icon from "@Atom/Icon";
import { ModalContext } from "Context/ModalContetx";
import { GraphValue } from "Context/GraphContext";
import { fileBaseUrl } from "configs";
import AvatarIamges from "@Assets/Images/ava-default.png";
export default function CardChart({
  data: {
    members = [
      {
        name: "placeholder",
        position: "placeholder",
        imageURL: AvatarIamges,
      },
    ],
    department = "Finance",
    isPinPositionTop = false,
    typePositionTop = "", // source or target
    typePositionBottom = "",
    chartColor = "black",
    color = "white",
    isForModal = false,
  },
  id,
}) {
  const [isOpenMenu, setIsOpeMenu] = useState(false);
  const { setIsOpenField, setIsOpenFieldmember, setIsOpenModalDetail } =
    useContext(ModalContext);
  const { edges } = useContext(GraphValue);

  const isHaveChild = useMemo(() => {
    return edges.some((el) => el.source === id);
  }, [edges, id]);

  const isThanFourMembers = members.length > 4;

  const { nodeId } = useContext(GraphValue);

  return (
    <div
      className={Styles.containerCard}
      onClick={() => (isForModal ? () => {} : setIsOpeMenu(!isOpenMenu))}
      is-selected={(isForModal && nodeId === id)?.toString()}
    >
      <div
        className={`${Styles.wraperrMenuChart}`}
        is-open={isOpenMenu?.toString()}
        members={members.length >= 4 ? "true" : members.length?.toString()}
      >
        <div
          className={Styles.wrapIcon}
          onClick={() => setIsOpenModalDetail(id)}
        >
          <Icon
            icon={"eye"}
            color={"#fff"}
            size={"16"}
            className={Styles.icon}
          />
        </div>
        <div
          className={Styles.wrapIcon}
          style={
            members.length > 1
              ? { background: "#CDD2DB", cursor: "not-allowed" }
              : {}
          }
          onClick={() => (members.length > 1 ? () => {} : setIsOpenField(id))}
        >
          <Icon
            icon={"card-add"}
            color={members.length > 1 ? "#919EB0" : "#fff"}
            size={"16"}
            className={Styles.icon}
          />
        </div>
        <div
          className={Styles.wrapIcon}
          style={
            isHaveChild ? { background: "#CDD2DB", cursor: "not-allowed" } : {}
          }
          onClick={() => (isHaveChild ? () => {} : setIsOpenFieldmember(id))}
        >
          <Icon
            icon={"user-add"}
            color={isHaveChild ? "#919EB0" : "#fff"}
            size={"16"}
            className={Styles.icon}
          />
        </div>
      </div>
      <div
        className={Styles.head}
        style={{ background: chartColor, color: color }}
      >
        <h1>{department}</h1>
      </div>
      <div>
        {members?.slice(0, 4).map((el, idx) => (
          <div className={Styles.contain} key={idx}>
            <img
              src={
                el?.imageURL === "" ||
                el?.imageURL === "-" ||
                !el?.imageURL ||
                el.imageURL.split("/")[0] === "dummy"
                  ? AvatarIamges
                  : fileBaseUrl + el.imageURL
              }
              is-opacity={(isThanFourMembers && idx === 3)?.toString()}
              alt="profile-picture"
              height={"24px"}
              width={"24px"}
            />
            <div
              className={Styles.names}
              is-true={(members.length > 3 && idx !== 3).toString()}
            >
              <span is-opacity={(isThanFourMembers && idx === 3)?.toString()}>
                {el?.name}
              </span>
              <span is-opacity={(isThanFourMembers && idx === 3)?.toString()}>
                {el?.position || "posisi"}
              </span>
            </div>
            {isThanFourMembers && idx === 3 && (
              <div className={Styles.otherMembers} key={idx}>
                {members.slice(4, 8).map((m, idx) => (
                  <img
                    key={idx}
                    src={fileBaseUrl + m.imageURL}
                    alt="profile-picture"
                    height={"24px"}
                    width={"24px"}
                  />
                ))}
                {members.length > 8 && (
                  <div className={Styles.length}>
                    <span> +{members.length - 8} </span>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {isPinPositionTop && (
        <Handle type={typePositionTop} position={Position.Top} />
      )}
      {members.length === 1 && (
        <Handle type={typePositionBottom} position={Position.Bottom} />
      )}
    </div>
  );
}
