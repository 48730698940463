import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import LoadingAnimationPage from "@Molecule/LoadingAnimationPage";
import SelectClientLayout from "@Organism/SelectClient";
import { getCorps } from "@Services/auth/getCorps";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const selectClientLoader = (route) => {
  const url = new URL(route?.request?.url);
  const page = url.searchParams.get("p") || 1;
  const limit = url.searchParams.get("l") || 10 ;
  const search = url.searchParams.get("s") || "";

  return defer({
    packageClientList: getCorps(limit, page, search),
  });
};

export default function SelectClientPage() {
  const { packageClientList } = useLoaderData()

  return (
    <Suspense
      fallback={
        <LoadingAnimationPage />
      }
    >
      <Await
        resolve={packageClientList}
        errorElement={
          <AsyncErrorPage height="100vh" />
        }
      >
        {(packageClientList) => (
          <SelectClientLayout
            packageClientList={packageClientList?.data?.response}
          />
        )}

      </Await>

    </Suspense>
  )
}