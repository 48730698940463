/* eslint-disable no-unused-vars */
import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import moment from 'moment'
import SearchBar from '@Atom/SearchBar'
import { useMemo, useState } from 'react'
import Table from '@Molecule/Table'
import { useNavigate, useParams } from 'react-router-dom'
import { releaseSlip } from '@Services/payroll/releaseSlip'
import useQuery from '@Hooks/useQuery'
import ReleaseLoadingModal from '@Molecule/_modal/ReleaseLoadingModal'
import Snackbar from '@Atom/Snackbar'
import { getMonths } from '@Assets/data/months'

const ExcelJS = require("exceljs");


export default function PayrollResultLayout({
  packageResult
}) {
  const navigate = useNavigate()
  const query = useQuery()

  const [search, setSearch] = useState('')

  const { id } = useParams()

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  let data = useMemo(() => {
    return packageResult?.payroll_details?.map(each => {
      // const calculated = each?.calculated_component.reduce(
      //   (obj, item) => Object.assign(obj, { [item?.component_code]: item?.calc_component_value }), {});

      return {
        ...each,
        // ...calculated
      }
    })
  }, [packageResult?.payroll_details])

  const [downloadError, setDownloadError] = useState('')
  const [isDownloading, setIsDownloading] = useState(false)


  const [isReleaseSuccess, setIsReleaseSuccess] = useState(false)

  const [releaseType, setReleaseType] = useState('')
  const [releaseError, setReleaseError] = useState('')
  const onRelease = async () => {
    setReleaseType('loading')
    try {
      await releaseSlip(query?.get('pi'), query?.get('y'), query?.get('m'), id, localStorage?.getItem('clientCode'))
      setIsReleaseSuccess(true)
      setReleaseType('')
    } catch (err) {
      setReleaseType('error')
      setReleaseError(err?.response?.data?.error || 'Something went wrong')
    }
  }

  const columns = [
    {
      name: "nrk",
      title: 'NRK',
      width: '150px',
      tdClassName: Styles.second,
      thClassName: Styles.second,
      renderData: (row) => (
        <span>{row?.nrk}</span>
      )
    },
    {
      name: "nama",
      titleText: 'Nama',
      title: <div className={Styles.name}>
        <span>Nama</span>
        <div className={Styles.border} />
      </div>,
      width: '250px',
      tdClassName: Styles.third,
      thClassName: Styles.third,
      renderData: (row) => (
        <div className={Styles.name}>
          <span>{row?.nama}</span>
          <div className={Styles.border} />
        </div>
      )
    },
    ...packageResult?.table_header
      ?.filter(obj => obj?.component_code !== 'nama' && obj?.component_code !== 'nrk')
      ?.map(th => {
        return {
          name: th?.component_code,
          title: th?.component_name,
          width: '200px',
          renderData: (row) => (
            <span>{row[th?.component_code] || '-'}</span>
          )
        }
      })
  ]


  const onDownload = async () => {
    setIsDownloading(true); // Set loading state to true at the start
  
    try {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("My Sheet", {
        views: [{ state: "frozen", ySplit: 3, xSplit: 3 }],
      });
  
      // Set default row height
      sheet.properties.defaultRowHeight = 20;
  
      // 1. Set the text in cell A1
      const a1Cell = sheet.getCell('A1');
      a1Cell.value = `Hasil Payroll - ${query?.get('y')}-${query?.get('m')}-01`;
      a1Cell.protection = { locked: true }; // Lock cell A1
  
      // 2. Add 1 empty row explicitly at the top
      const emptyRow = sheet.addRow([]);
      emptyRow.height = 20; // Set height for the empty row
  
      // 3. Sanitize and add the header row on the 4th row
      const sanitizedColumns = (columns || []).map((col, index) => {
        if (typeof col?.title !== 'string') {
          return col?.titleText; 
        }
        return col.title;
      });
  
      const headerRow = sheet.addRow(["Periode", ...sanitizedColumns]);
  
      // 4. Style the header row
      headerRow.font = { bold: true, size: 14 };
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFB2E0F4' },
        };
      });
      headerRow.height = 30; // Set height for the header row
  
      // 5. Define worksheet columns for alignment
      sheet.columns = [
        { key: "periode" },
        ...(columns || []).map((obj, index) => ({
          key: obj?.name || `col_${index}`, // Default key if missing
        })),
      ];
  
      // 6. Add a hidden row with column keys
      const keysRow = sheet.addRow(sheet.columns.map((col) => col.key));
      keysRow.hidden = true;
  
      // 7. Utility to remove circular references from data
      const removeCircular = (obj) => {
        const seen = new WeakSet();
        return JSON.parse(
          JSON.stringify(obj, (key, value) => {
            if (typeof value === "object" && value !== null) {
              if (seen.has(value)) return; // Skip circular references
              seen.add(value);
            }
            return value;
          })
        );
      };
  
      // 8. Add payroll data rows below the header
      (data || []).forEach((el) => {
        const sanitizedData = removeCircular(el); // Sanitize the data
        const newRow = sheet.addRow({
          ...sanitizedData,
          periode: `${getMonths()[query.get('mi')]} ${query.get('y')}`,
        });
        newRow.height = 20; // Set height dynamically
      });
  
      // 9. Set alignment for all cells
      sheet.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'left' };
        });
      });
  
      // 10. Lock all cells
      sheet.columns.forEach((column) => {
        column.eachCell((cell) => {
          cell.protection = { locked: true };
        });
      });
  
      // 11. Protect the worksheet
      await sheet.protect('your-password', {
        selectLockedCells: true,
        selectUnlockedCells: true,
      });
  
      // 12. Auto-adjust column widths based on content
      sheet.columns.forEach((column) => {
        const lengths = column.values
          .filter((v) => v != null) // Avoid null/undefined values
          .map((v) => v.toString().length);
        const maxLength = Math.max(...lengths, 10);
        column.width = maxLength + 8; // Adjust column width
      });
  
      // 13. Generate and download the Excel file
      const report = await workbook.xlsx.writeBuffer();
      const blob = new Blob([report], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Payroll Result.xlsx`;
      anchor.click();
  
      // Revoke the Blob URL after a short delay to free memory
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
  
      // 14. Reset loading state
      setIsDownloading(false);
    } catch (err) {
      console.error(err, 'Error generating Excel');
      setDownloadError(err?.response?.data?.error || 'Something went wrong');
      setIsDownloading(false); // Ensure loading state is reset on error
    }
  };
  
  

  return (
    <div className={Styles.containerWrapper}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.top}>
            <button onClick={() => navigate(-1)}>
              <Icon icon={'arrow-chevron-left'} size={20} />
            </button>
            <h3>Hasil Payroll • Dibuat pada {moment(new Date())?.format('LL')}</h3>
          </div>
          <div className={Styles.bottom}>
            <SearchBar
              backgroundColor='#fff'
              value={search}
              setValue={setSearch}
              placeholder='Cari nama karyawan'
            />
            <div className={Styles.actions}>
              <button onClick={onDownload} disabled={isDownloading}>
                <Icon icon={'document-download'} size={16} />
                <span>{isDownloading? 'Downloading' : 'Download'} Payroll</span>
              </button>
              <button onClick={onRelease}>
                <Icon icon={'document-upload'} size={16} />
                <span>Release e-Payslip</span>
              </button>
            </div>

          </div>

        </div>

        <div className={Styles.content}>
          <Table
            className={Styles.table}
            data={data?.filter(obj => obj?.nama?.toUpperCase()?.includes(search?.toUpperCase()))}
            columns={columns}
            page={page}
            limit={limit}
            totalData={data?.length || 0}
            setLimit={setLimit}
            setPage={setPage}
            totalPage={Math.ceil(data?.length / limit)}
            // withPagination
            withNumbering
            isNumberSticky
            widthNumber='20px'
            // height='400px'
            minHeight='0px'
            height={data?.filter(obj => obj?.nama?.toUpperCase()?.includes(search?.toUpperCase()))?.length ? '0px' : '370px'}
          />
        </div>
      </div>

      {
        !!releaseType
        &&
        <ReleaseLoadingModal
          type={releaseType}
          onClose={() => setReleaseType('')}
          onRetry={onRelease}
        />
      }

      <Snackbar
        show={isReleaseSuccess}
        type={'success'}
        autoClose
        onClose={() => {
          setIsReleaseSuccess(false)
        }}
        text={`Release e-Payslip Berhasil`}
        closeDuration={5}
        width='356px'
      />

    </div>
  )
}