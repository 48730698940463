import useQuery from "@Hooks/useQuery";
import SkeletonListEmployee from "@Molecule/Skeleton/Employee/ListEmployee";
import AttendanceListLayout from "@Organism/Attendance/AttendanceList";
import { getAttendancesList } from "@Services/employee/attendance";
import moment from "moment";
import { Suspense, useEffect, useState } from "react";
import { Await, defer, useLoaderData, useLocation, useNavigate } from "react-router-dom";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("p") || 1
  const limit = params?.get("l") || 5
  const type = params?.get("t") || "DAILY"
  const date = params?.get("d") || moment(new Date()).format('YYYY-MM-DD')
  const search = params?.get("n") || ''

  return defer({
    packageAttendances: getAttendancesList({page, limit, type, date: moment(date).format('YYYY-MM-DD'), search, cutOff: 22})
  })
}

export default function AttendanceListPage() {
  const { packageAttendances } = useLoaderData()
  const query = useQuery()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const defaultPage = query.get("p") || 1;
  const defaultLimit = query.get("l") || 5;
  const defaultType = query.get("t") || "DAILY";
  const defaultDate = query.get("d") || moment(new Date()).format('YYYY-MM-DD');
  const defaultSearch = query.get("n") || "";

  const [page, setPage] = useState(defaultPage)
  const [limit, setLimit] = useState(defaultLimit)
  const [date, setDate] = useState(defaultDate)
  const [search, setSearch] = useState(defaultSearch)
  const [isActiveTabs, setIsActiveTabs] = useState(defaultType === 'MONTHLY' ? 1 : 0);

  useEffect(()=>{
    navigate(`${pathname}?p=${page}&l=${limit}&t=${isActiveTabs === 0 ? 'DAILY' : 'MONTHLY'}&d=${moment(date).format('YYYY-MM-DD')}&n=${search}`)
  },[pathname, navigate, page, limit, isActiveTabs, date, search])

  return (
    <Suspense 
      fallback={<SkeletonListEmployee/>}
    >
      <Await
        errorElement={<p>Error get data</p>}
        resolve={packageAttendances}
      >
        {(packageAttendances) => (
          <AttendanceListLayout 
            data={packageAttendances?.data?.attendanceData || []}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            date={date}
            setDate={setDate}
            search={search}
            setSearch={setSearch}
            isActiveTabs={isActiveTabs}
            setIsActiveTabs={setIsActiveTabs}
            totalData={packageAttendances?.totalData || 0}
            totalPage={packageAttendances?.totalPage || 1}
          />
        )}
      </Await>
    </Suspense>
    
  )
}