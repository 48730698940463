import Button from '@Atom/Button';
import CustomDatePicker from '@Atom/CustomDatePicker';
import Icon from '@Atom/Icon';
import TabHeader from '@Atom/TabHeader';
import useWindowSize from '@Hooks/useWindowSize';
import SetLeaveModal from '@Molecule/_modal/SetLeaveModal';
import BigCalendar from '@Molecule/BigCalendar';
import Table from '@Molecule/Table';
import Tabs from '@Molecule/Tabs';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Styles from './style.module.scss';
import { get35DaysInMonth } from 'helpers/get35DaysInMonth';
import { getAttendancesByEmployee, getLeaveHistoryByEmployee } from '@Services/employee/detailEmployee';
import { useParams } from 'react-router-dom';
import { typeAttendace } from 'helpers/typeAttendace';
import { isDateBetween } from 'helpers/isDateBetween';

export default function DetailAttendanceEmployee({dataPersonal}) {
  const [isActiveTabs, setIsActiveTabs] = useState(0);
  const {id} = useParams();
  const { width } = useWindowSize();

  const [attendances, setAttendances] = useState([])
  const [attendanceCounts, setAttendanceCounts] = useState([])
  const [valueDate, setValueDate] = useState(new Date())
  const [valueDate2, setValueDate2] = useState(new Date())

  const [showSetLeave, setShowSetLeave] = useState(false)

  const [leavesData, setLeavesData] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [sort, setSort] = useState({})
  const [error, setError] = useState()


  const tabs = useMemo(() => {
    return ['Kehadiran', 'Cuti'];
  }, []);

  const leaveColumns = useMemo(() => {
    return [
      {
        name: "appliedAt",
        width: width > 768 ? "20%" : "20%",
        title: <TabHeader text="Tgl Pengajuan" column={"appliedAt"} sort={sort} setSort={setSort} isSorted />,
      },
      {
        name: "type",
        width: width > 768 ? "20%" : "20%",
        title: <TabHeader text="Jenis Cuti" column={"type"} sort={sort} setSort={setSort} isSorted />,
      },
      {
        name: "startTime",
        width: width > 768 ? "30%" : "30%",
        title: <TabHeader text="Tanggal Cuti" column={"startTime"} sort={sort} setSort={setSort} isSorted />,
        renderData: (row) => (
          <div className={Styles.leaveDate}>
            <p>
              <Icon icon={'arrow-right'} size={12} color={'#22970F'} />
              <span>{row?.startTime}</span>
            </p>
            <p>
              <Icon icon={'arrow-left'} size={12} color={'#D42701'} />
              <span>{row?.endTime}</span>
            </p>
          </div>
        )
      },
      {
        name: "description",
        width: width > 768 ? "30%" : "30%",
        title: <TabHeader text="Deskripsi" column={"description"} sort={sort} setSort={setSort} isSorted />,
        renderData: (row) => (
          <div className={Styles.desc}>
            {row?.description}
          </div>
        )
      },
    ]
  }, [width, sort])

  const content = useMemo(() => {
    switch (isActiveTabs) {
      case 1:
        return (
          <div className={Styles.leaves}>
            <div className={Styles.indicators}>
              {
                [
                  {
                    icon: 'pie-chart',
                    iconColor: '#EED202',
                    title: 'Jatah Cuti',
                    value: 12,
                    extraValue: 1
                  },
                  {
                    icon: 'calendar-check',
                    iconColor: '#0244EE',
                    title: 'Sisa Cuti Karyawan',
                    value: 9,
                    extraValue: 0
                  }
                ]
                  ?.map((i, iI) => (
                    <div key={iI} className={Styles.indicator}>
                      <Icon icon={i?.icon} size={20} color={i?.iconColor} />
                      <span>{i?.title}</span>
                      <div className={Styles.iValue}>
                        <span>{i?.value}</span>
                        {i?.extraValue > 0
                          &&
                          <div>
                            + {i?.extraValue}
                          </div>
                        }
                      </div>
                    </div>
                  ))
              }
            </div>
            <div className={Styles.tableWrapper}>
              <Table
                data={leavesData}
                columns={leaveColumns}
                page={page}
                limit={limit}
                totalData={totalData}
                totalPage={totalPage}
                setLimit={setLimit}
                setPage={setPage}
                withPagination
                withNumbering
                isLoading={isLoading}
              />
            </div>
          </div>
        )

      default:
        return (
          <div className={Styles.attendances}>
            {/* <div className={Styles.types}>
              {
                [
                  {
                    title: 'Hadir',
                    bgColor: '#F4FFF2',
                    borderColor: '#22970F',
                    value: attendanceCounts?.find((f) => f.type === "HADIR")?.count || 0
                  },
                  {
                    title: 'Sakit',
                    bgColor: '#FEEDFF',
                    borderColor: '#DB2BE5',
                    value: attendanceCounts?.find((f) => f.type === "SAKIT")?.count || 0
                  },
                  {
                    title: 'Cuti',
                    bgColor: '#FFFCE6',
                    borderColor: '#EED202',
                    value: attendanceCounts?.find((f) => f.type === "CUTI")?.count || 0
                  },
                  {
                    title: 'Izin',
                    bgColor: '#F0F0FF',
                    borderColor: '#2E3192',
                    value: attendanceCounts?.find((f) => f.type === "IZIN")?.count || 0
                  },
                  {
                    title: 'Absen',
                    bgColor: '#FFF4F2',
                    borderColor: '#D42701',
                    value: attendanceCounts?.find((f) => f.type === "ABSEN")?.count|| 0
                  },
                ]
                  ?.map((t, tI) => (
                    <div key={tI} style={{ backgroundColor: t?.bgColor, borderColor: t?.borderColor }}>
                      <span>{t?.title}</span>
                      <span>{t?.value}</span>
                    </div>
                  ))
              }
            </div> */}
      
              <BigCalendar attendances={attendances} />
          </div>
        )
    }
  }, [attendances, isActiveTabs, leaveColumns, leavesData, limit, page, totalPage, isLoading, totalData])

  useEffect(() => {
    async function fetchLeaveHistory(){
      try {
        setIsLoading(true)
        const {response} = await getLeaveHistoryByEmployee({
          employeeID: dataPersonal?.employeeID,
          year: moment(valueDate2)?.format('YYYY'),
          page,
          limit
        })

        setTotalPage(1)
        setTotalData(response?.length || 1)
        setLeavesData(response)
        setIsLoading(false)
      } catch (error) {
        console.log(error);
        setLeavesData([])
        setError(error?.response?.data?.message || error?.message);
        setIsLoading(false);
      }
    }

    fetchLeaveHistory()
  }, [dataPersonal?.employeeID, valueDate2, page, limit])

  useEffect(() => {
      const allDays = get35DaysInMonth(new Date(valueDate));

      async function fetchAttendace() {
          try {
            setIsLoading(true)
            const {data} = await getAttendancesByEmployee({
              employeeID: id,
              date: moment(valueDate)?.format('YYYY-MM-DD')
            })

            setAttendanceCounts(data?.attendanceCounts)

            const resultAttend = allDays?.map((obj) => {
              const currentAttend = data?.attendanceData?.find((d) => isDateBetween(obj, d?.startTime, d?.endTime || d?.startTime));
              if (currentAttend) {
                  return {
                      ...currentAttend,
                      date: moment(obj)?.format('YYYY-MM-DD'),
                      value: typeAttendace(currentAttend?.type || ''),
                  };
              } else {
                return {
                    date: moment(obj)?.format('YYYY-MM-DD'),
                    value: '',
                };
              }
          });
          setAttendances(resultAttend)
          setIsLoading(false)
          } catch (error) {
              console.log(error);
              const resultAttend = allDays?.map((obj) => {
                return {
                    date: moment(obj)?.format('YYYY-MM-DD'),
                    value: '',
                };
              })
              setAttendances(resultAttend);
              setError(error?.response?.data?.message || error?.message);
              setIsLoading(false);
          }
      }

      fetchAttendace()
  }, [id, valueDate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapperTabs}>
        <Tabs listTabs={tabs} isActive={isActiveTabs} setIsActive={setIsActiveTabs} />
        {
          isActiveTabs === 0
            ?
              <div className={Styles.rightTabs}>
                <div className={Styles.totalAttendance}>
                  <div className={Styles.titleWrapper}>
                    <span>Total</span>
                    <span>Kehadiran</span>
                  </div>
                  <div className={Styles.countWrapper}>
                    {attendanceCounts?.find((f) => f.type === "HADIR")?.count || 0}
                  </div>
                </div>
                <CustomDatePicker type='month-year' value={valueDate} setValue={setValueDate}  right='0'/>
              </div>
            :
              <div className={Styles.rightTabs}>
                <CustomDatePicker type='year-only' value={valueDate2} setValue={setValueDate2} right='0'/>
                <Button
                  text='Atur Cuti'
                  onClick={() => setShowSetLeave(true)}
                />
              </div>
        }

      </div>
      {content}
      {error && (
          <div className={Styles.errorText}>
              {error}
          </div>
      )}
      <SetLeaveModal
        show={showSetLeave}
        onClose={() => setShowSetLeave(false)}
      />

    </div>

  )
}