import Modal from '@Atom/Modal'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'
import { useMemo } from 'react'
import Rotation from '@Atom/Rotation'

export default function ReleaseLoadingModal({
  type,
  onClose = () => { },
  onRetry = () => { },
}) {

  const template = useMemo(() => {
    switch (type) {
      case 'loading':
        return {
          image: <Rotation type='blue' width='40px' />,
          title: 'Dalam Proses',
          description: 'Harap tunggu dan tidak menutup atau memuat ulang halaman ini',
        }
      case 'error':
        return {
          image: <Icon icon='close-circle-solid' size='40px' color={'#D42701'} />,
          title: 'Dalam Proses',
          description: 'Harap tunggu dan tidak menutup atau memuat ulang halaman ini',
        }
      default:
        return {}
    }
  }, [type])

  return (
    <Modal isOpen={!!type}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <Icon icon={'receipt-2'} size={24} color={'#2E3192'} />
          <h3>Release e-Payslip</h3>
        </div>
        <div className={Styles.content}>
          {template?.image}
          <span>{template?.title}</span>
          <p>{template?.description}</p>
          {
            type === 'error'
            &&
            <div className={Styles.actions}>
              <button onClick={onClose}>Tutup</button>
              <button onClick={onRetry}>Release Ulang</button>
            </div>
          }
        </div>
      </div>
    </Modal>
  )

}