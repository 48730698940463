import router from "@Navigators/index";
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import "moment/locale/id";
import moment from "moment";
import { ModalProvider } from "Context/ModalContetx";
moment.locale("id");

function App() {
  return (
    <ModalProvider>
      <RouterProvider router={router} />
    </ModalProvider>
  );
}

export default App;
