import Icon from "@Atom/Icon";
import React, { useState } from "react";
import Styles from "./style.module.scss";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

export default function FileViewerModal({
  isOpen,
  onClose,
  fileType = "image",
  fileUrl,
  fileName,
  hideOnMobile = false,
}) {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div
      className={`${Styles.overlay} ${isOpen ? Styles.open : Styles.close} ${
        hideOnMobile ? Styles.hideOnMobile : ""
      }`}
    >
      <div className={Styles.header}>
        <button onClick={onClose} className={Styles.backButton}>
          <Icon icon={"arrow-back"} size={"24px"} color={"#FFFFFF"} />
        </button>
        {fileType === "pdf" ? (
          <Icon icon={"pdf"} size={"24px"} />
        ) : (
          <Icon icon={"image"} size={"24px"} color={"#FFFFFF"} />
        )}
        <span>{fileName || `file.${fileType}`}</span>
      </div>
      <div
        className={`${Styles.modal} ${
          fileType === "pdf" ? Styles.pdfContent : ""
        }`}
      >
        <div className={Styles.content}>
          {fileType === "image" && (
            <img
              src={fileUrl}
              alt="File"
              style={{ width: "auto", height: "80%" }}
            />
          )}
          {fileType === "pdf" && (
            <Document
              file={fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              className={Styles.documentWrapper}
              error={
                <span className={Styles.errorText}>Gagal memuat file PDF</span>
              }
              loading={
                <span className={Styles.loadingText}>Memuat file PDF...</span>
              }
              noData={
                <span className={Styles.noDataText}>Tidak ada file PDF</span>
              }
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
        </div>
      </div>
    </div>
  );
}
