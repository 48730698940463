import { getMonths } from '@Assets/data/months'
import Icon from '@Atom/Icon'
import Input from '@Atom/Input'
import InputTitle from '@Atom/InputTitle'
import Modal from '@Atom/Modal'
import RadioButton from '@Atom/RadioButton'
import { readExcelFile } from '@Helpers/readExcelFile'
import { downloadTemplate } from '@Services/payroll/downloadTemplate'
import { useCallback, useEffect, useMemo, useState } from 'react'
import CalculateModal from '../CalculateModal'
import Styles from './style.module.scss'
import Rotation from '@Atom/Rotation'
import ErrorBubble from '@Atom/ErrorBubble'
import { uploadTemplate } from '@Services/payroll/uploadTemplate'
import { makeRandomString } from '@Helpers/makeRandomString'

const ExcelJS = require("exceljs");


export default function PayrollProceedModal({
  show,
  type,
  onClose = () => { },
  pksID = '',
  year = '',
  month = '01',
  monthIndex = 0,
  pksAlias = '',
  setTrigger = () => { }
}) {
  const [workDay, setWorkDay] = useState()
  const [dataType, setDataType] = useState('full')
  const [file, setFile] = useState(null)

  const [isProceeding, setIsProceeding] = useState(false)
  const [totalData, setTotalData] = useState()
  // eslint-disable-next-line no-unused-vars
  const [isDownloading, setIsDownloading] = useState(false)
  const [error, setError] = useState('')

  const [read, setRead] = useState([])

  // eslint-disable-next-line no-unused-vars
  const [proceededData, setProceededData] = useState(0)

  const readFile = useCallback(() => {
    if (file) {
      readExcelFile([file], setRead)
    }
  }, [file])

  useEffect(() => {
    setTotalData(read?.slice(2, read?.length)?.length)
  }, [read])

  useEffect(() => {
    readFile()
  }, [readFile])

  const alertFound = useMemo(() => {
    switch (type) {
      case 'download':
        if (!workDay) {
          return 'Masukkan jumlah hari kerja'
        }
        return ''
      default:
        return ''
    }
  }, [type, workDay])

  const handleSubmit = async () => {
    if (alertFound) {
      setError(alertFound);
    } else if (type === 'proceed') {
      setProceededData(0)
      setIsProceeding(true);
      try {
        await uploadTemplate(file, year, month, pksID)
        setProceededData(totalData)
        setTrigger(makeRandomString(5))
      
      } catch (err) {
        setError(err?.response?.data?.error || 'Something went wrong');
        setIsProceeding(false);

      }
    } else {
      setIsDownloading(true); // Set loading state to true at the start
      try {
        const response = await downloadTemplate(
          pksID,
          year,
          month,
          dataType === 'full' ? 'true' : 'false',
          workDay
        );

        const resData = response?.data?.response;
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("My Sheet", {
          views: [{ state: "frozen", ySplit: 3, xSplit: 3 }],
        });

        // Set default row height if needed
        sheet.properties.defaultRowHeight = 20;

        // 1. Set the text in cell A1
        const a1Cell = sheet.getCell('A1');
        a1Cell.value = `${pksAlias} - ${year}-${month}-01`;
        a1Cell.protection = { locked: true }; // Lock cell A1

        // 2. Add 1 empty row explicitly at the top
        const emptyRow = sheet.addRow([]); // Only one empty row now
        emptyRow.height = 20; // Set height for the empty row

        // 3. Add the header directly on the 4th row
        const headerRow = sheet.addRow([
          "Periode",
          ...(resData?.table_header || []).map((obj) => obj?.component_name),
        ]);

        // 4. Style the header row
        headerRow.font = { bold: true, size: 14 };
        headerRow.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFB2E0F4' },
          };
        });
        headerRow.height = 30; // Set height for the header row

        // 5. Define worksheet columns for alignment
        sheet.columns = [
          { key: "periode" },
          ...(resData?.table_header || []).map((obj) => ({
            key: obj?.component_code,
          })),
        ];

        // 6. Add a hidden row with column keys
        const keysRow = sheet.addRow(sheet.columns.map((col) => col.key));
        keysRow.hidden = true;

        // 7. Add payroll data rows below the header
        (resData?.payroll_list || []).forEach((el) => {
          const newRow = sheet.addRow({
            ...el,
            periode: `${getMonths()[monthIndex]} ${year}`,
          });

          // Set height dynamically
          newRow.height = 20; // Adjust as needed
        });

        // Set vertical alignment to center and horizontal alignment to left for all cells
        sheet.eachRow((row) => {
          row.eachCell((cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'left' }; // Center vertically, left align horizontally
          });
        });

        // 8. Lock the column with key 'potonganpinjaman'
        const potonganPinjamanColumnIndex = sheet.columns.findIndex(column => column.key === 'potonganpinjaman');

        // 9. Lock the first 17 columns (A to Q) and the 'potonganpinjaman' column
        sheet.columns.forEach((column, index) => {
          column.eachCell((cell) => {
            const cellRowIndex = cell.row;
            if (cellRowIndex === 4) {
              cell.protection = { locked: true }; // Lock cells in the fourth row
            } else {
              cell.protection = { locked: index < 17 || index === potonganPinjamanColumnIndex };
            }
          });
        });

        // 10. Protect the worksheet
        await sheet.protect('your-password', {
          selectLockedCells: true,
          selectUnlockedCells: true,
        });

        // 11. Auto-adjust column widths based on content
        sheet.columns.forEach((column) => {
          const lengths = column.values
            .filter((v) => v)
            .map((v) => v.toString().length);
          const maxLength = Math.max(...lengths, 10);
          column.width = maxLength + 8;
        });

        // 12. Generate and download the Excel file
        const report = await workbook.xlsx.writeBuffer();
        const blob = new Blob([report], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `Payroll Template ${pksAlias}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);

        setIsDownloading(false); // Reset loading state
        onClose(); // Close the modal or reset state
      } catch (err) {
        console.error(err, 'Error generating Excel');
        setError(err?.response?.data?.error || 'Something went wrong');
        setIsDownloading(false);
      }
    }
  };

  useEffect(()=> {
    if(proceededData > 0 && proceededData === totalData){
      setTimeout(()=> {
        setIsProceeding(false);
        onClose()
      }, 1000)
    }
  }, [onClose, proceededData, totalData])

  useEffect(() => {
    if (!alertFound) {
      setError('')
    }
  }, [alertFound])

  useEffect(()=> {
    setFile(null)
    setRead([])
    setProceededData(0)
    setTotalData(0)
  }, [show])


  return (
    <Modal isOpen={show} onClose={onClose}
    // isTransparent={isProceeding}
    >
      <div className={Styles.container}>
        <div className={Styles.header}>
          <Icon icon={'message-question'} size={24} color={'#EED202'} />
          <h3>Proses Data Payroll?</h3>
          <button onClick={onClose}>
            <Icon icon={'close-circle'} size={10} />
          </button>
        </div>
        <div className={Styles.content}>
          {
            type === 'download'
              ?
              <div className={Styles.downloadForm}>
                <Input
                  title='Masukkan Jumlah Hari Kerja'
                  value={workDay}
                  setValue={setWorkDay}
                  placeholder='Contoh: 20'
                  isNumber
                />
                <div className={Styles.dataTypeChooser}>
                  <InputTitle
                    title='Apakah Kamu Ingin Proses Seluruh Data/Sebagian Data?'
                  />
                  <div className={Styles.dataTypes}>
                    <div onClick={() => setDataType('full')}>
                      <RadioButton isActive={dataType === 'full'} />
                      <span>Seluruh Data</span>
                    </div>
                    <div onClick={() => setDataType('partial')}>
                      <RadioButton isActive={dataType === 'partial'} />
                      <span>Sebagian Data</span>
                    </div>

                  </div>
                </div>
              </div>
              :
              <div>
                <Input
                  value={file}
                  setValue={setFile}
                  isFile
                  placeholder='Unggah data payroll karyawan yang sudah anda isi'
                  accept={{
                    'application/vnd.ms-excel': ['.xlsx', '.numbers', '.xls'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                  }}
                  maxSize={10}
                />
              </div>
          }
        </div>
        {
          error
          &&
          <ErrorBubble
            errorText={error}
            noTip
          />
        }
        <div className={Styles.actions}>
          <button onClick={onClose}>
            Kembali
          </button>
          <button
            disabled={isDownloading || isProceeding}
            onClick={handleSubmit}
          >

            {
              isDownloading || isProceeding
                ?
                <Rotation type='white' width='18px' />
                :
                <span>
                  {type === 'download' ? 'Download Template' : 'Kalkulasi Payroll'}
                </span>
            }
          </button>
        </div>
      </div>
      {
        isProceeding
        &&
        <CalculateModal
          proceededData={proceededData}
          totalData={totalData}
          show={isProceeding}
        />
      }
    </Modal>
  )
}