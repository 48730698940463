import Styles from './style.module.scss'

export default function PayrollSectionCard({
  title = '',
  children,
  button
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <span>{title}</span>
        {!!button && button}
      </div>
      <div className={Styles.underline} />
      <div>
        {children}
      </div>
    </div>
  )
}