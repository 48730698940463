import React from 'react';
import Styles from './style.module.scss';
import Icon from '@Atom/Icon';

export default function Thumbnail({ src, alt, height = '44px', width = 'auto' }) {
    const customSize = {
        '--thumbnail-width': width, 
        '--thumbnail-height': height
    };

    return (
        <>
            {src ? (
                <img
                    src={src}
                    alt={alt || 'Thumbnail'}
                    className={Styles.thumbnailWrapper}
                    style={customSize}
                />
            ) : (
                <div className={Styles.errorIcon} style={customSize}>
                    <Icon icon={'image'} size={20} color={'#A9B3C1'} />
                </div>
            )}
        </>
    );
}
