import Modal from '@Atom/Modal'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'
import ProgressBar from '@Atom/ProgressBar'

export default function CalculateModal({
  totalData = 0,
  proceededData = 0,
  show
}) {

  return (
    <Modal isOpen={show}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <Icon icon={'calculator'} size={24} color={'#2E3192'} />
          <h3>Sedang Menghitung Data</h3>
        </div>
        <div className={Styles.content}>
          <span>Harap jangan tutup atau refresh halaman ini karena sedang dalam proses menghitung data</span>
          <ProgressBar
            percentage={(proceededData / totalData) * 100}
          />
          <p>{proceededData}/{totalData}</p>
        </div>
      </div>
    </Modal>
  )
}