import LoginLayout from "@Organism/Login";
import { redirect } from "react-router-dom";

export const loginLoader = () => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    throw redirect("/");
  }

  return null;
}

export default function LoginPage() {

  return (
    <LoginLayout />
  )
}