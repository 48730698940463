const ExcelJS = require('exceljs');

export const downloadExcel = (columns = [], data, fileName = 'Report', withNumber = false) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    if (withNumber){
        worksheet.columns = [ 
            {
                header: 'No.',
                key: 'no',
                width: 5
            },
            ...columns
        ];

        data.forEach((item, index) => {
            worksheet.addRow({
                no: (index + 1) + '.',
                ...item
            });
            
        });
    } else {
        worksheet.columns = columns;

        data.forEach((item) => {
            worksheet.addRow(item);
        });
    }
    
    worksheet.properties.defaultRowHeight = 20;
    worksheet.getRow(1).font = {
        size: 14,
        bold: true,
    };

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${fileName}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};
