import ErrorBoundary from "@Molecule/ErrorBoundary";
import LoadingAnimationPage from "@Molecule/LoadingAnimationPage";
import Doc, { viewFLBLoader } from "@Organism/Doc";
import { mainLoader } from "@Organism/Main";

import {
  loader as getDetailEmployee,
} from "@Pages/Employee/Detail";
import {
  loader as getDataEmployee,
} from "@Pages/Employee/List";
import {
  loader as getAllChart,
} from "@Pages/Employee/Organization";
import {
  loader as loaderAttendanceList
} from "@Pages/Attendance/AttendanceList"
import {
  loader as loaderAttendanceDetail
} from "@Pages/Attendance/AttendanceDetails"
import { loginLoader } from "@Pages/Login";
import { payrollResultLoader } from "@Pages/Payroll/PayrollResult";
import { payrollSchemeLoader } from "@Pages/Payroll/PayrollScheme";
import { selectClientLoader } from "@Pages/SelectClient";
import { lazy, Suspense } from "react";
import { feedbackListLoader } from "@Pages/Feedback/FeedbackList";
import { editEmployeeLoader } from "@Pages/Employee/Edit";

const { createBrowserRouter } = require("react-router-dom");

// lazy import for code splitting
const MainLayout = lazy(() => import("@Organism/Main"))
const CompanyDetailsLayout = lazy(() => import("@Organism/Companies/Details"))
const AddSchemaSalary = lazy(() => import("@Organism/Settings/payroll/Salary/BasicSalary/AddSchemaSalary"))
const AddSchemaBenefitsFood = lazy(() => import("@Organism/Settings/payroll/Salary/BenefitsFood/AddSchemaBenefitsFood"))
const AddSchemaHouse = lazy(() => import("@Organism/Settings/payroll/Salary/House/AddSchemaHouse"))
const AddSchemaOvertime = lazy(() => import("@Organism/Settings/payroll/Salary/Overtime/AddSchemaOvertime"))
const DetailSchemaOvertime = lazy(() => import("@Organism/Settings/payroll/Salary/Overtime/DetailSchemaOvertime"))
const AddSchemaProrate = lazy(() => import("@Organism/Settings/payroll/Salary/Prorate/AddSchemaProrate"))
const AddSchemaTAX = lazy(() => import("@Organism/Settings/payroll/Salary/TAX/AddSchemaTAX"))
const AddSchemaTHR = lazy(() => import("@Organism/Settings/payroll/Salary/THR/AddSchemaTHR"))
const AddSchemaTransportation = lazy(() => import("@Organism/Settings/payroll/Salary/Transportation/AddSchemaTransportation"))
const AddSchemaUAK = lazy(() => import("@Organism/Settings/payroll/Salary/UAK/AddSchemaUAK"))
const AttendanceDetailsPage = lazy(() => import("@Pages/Attendance/AttendanceDetails"))
const AttendanceListPage = lazy(() => import("@Pages/Attendance/AttendanceList"))
const CompanyListPage = lazy(() => import("@Pages/Companies/List"))
const AddEmployeePage = lazy(() => import("@Pages/Employee/Add"))
const DetailEmployeePage = lazy(() => import("@Pages/Employee/Detail"))
const EditEmployee = lazy(() => import("@Pages/Employee/Edit"))
const ListEmployeePage = lazy(() => import("@Pages/Employee/List"))
const ComponentsSalaryPage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary"))
const BasicSalaryPayrollPage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/BasicSalary"))
const BenefitsFoodPage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/BenefitsFood"))
const OrganizationPage = lazy(() => import("@Pages/Employee/Organization"))
const HousePage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/House"))
const OvertimePage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/Overtime"))
const ProratePayrollPage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/Prorate"))
const TaxPayrollPage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/TAX"))
const FeastDayPayrollPage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/THR"))
const TransportationPage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/Transportation"))
const UAKPayrollPage = lazy(() => import("@Pages/Settings/Payroll/ComponentsSalary/UAK"))
const PayrollSchemePage = lazy(() => import("@Pages/Payroll/PayrollScheme"))
const PayrollResultPage = lazy(() => import("@Pages/Payroll/PayrollResult"))
const LoginPage = lazy(() => import("@Pages/Login"))
const SelectClientPage = lazy(() => import("@Pages/SelectClient"))
const FeedbackListPage = lazy(() => import("@Pages/Feedback/FeedbackList"))
const FeedbackDetailsPage = lazy(() => import("@Pages/Feedback/FeedbackDetails"))

// const i = lazy(() => import(""))
// const i = lazy(() => import(""))


const router = createBrowserRouter([
  {
    path: "/",
    loader: mainLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <MainLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        path: "",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <h1>Halaman Dashboard</h1>
          </Suspense>
        ),
      },

      {
        path: "people",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <ListEmployeePage />
              </Suspense>
            ),
            loader: getDataEmployee,
          },
          {
            path: "detail/:id",
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <DetailEmployeePage />
              </Suspense>
            ),
            loader: getDetailEmployee,
          },
          {
            path: "edit/:id",
            loader: editEmployeeLoader,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <EditEmployee />
              </Suspense>
            ),
          },
          {
            path: "organization",
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <OrganizationPage />
              </Suspense>
            ),
            loader: getAllChart,
          },
          {
            path: "add-employee",
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <AddEmployeePage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "attendance",
        children: [
          {
            index: true,
            loader: loaderAttendanceList,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <AttendanceListPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            loader: loaderAttendanceDetail,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <AttendanceDetailsPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "companies",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <CompanyListPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <CompanyDetailsLayout />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "requests",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <h1>Halaman daftar permintaan</h1>
          </Suspense>
        ),
      },
      {
        path: "payroll",
        children: [
          {
            index: true,
            loader: payrollSchemeLoader,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <PayrollSchemePage />
              </Suspense>
            ),
          },
          {
            path: ':id',
            loader: payrollResultLoader,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <PayrollResultPage />
              </Suspense>
            ),
          },
        ]
      },
      {
        path: "feedback",
        children: [
          {
            index: true,
            loader: feedbackListLoader,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <FeedbackListPage />
              </Suspense>
            ),
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <FeedbackDetailsPage />
              </Suspense>
            ),
          }

        ]
      },
      {
        path: "facilities",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <h1>Halaman fasilitas</h1>
          </Suspense>
        ),
      },
      {
        path: "master-data",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <>master data</>
          </Suspense>
        ),
      },
      {
        path: "activities-center",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <>pusat kegiatan</>
          </Suspense>
        ),
      },
      {
        path: "content-management",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <>pengelolaan konten</>
          </Suspense>
        ),
      },
      {
        path: "epay-slip",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <>slip gaji</>
          </Suspense>
        ),
      },
      {
        path: "employees-benefit",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <>benefit karyawan</>
          </Suspense>
        ),

      },
      {
        path: "settings",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <div>halaman Settings</div>
              </Suspense>
            ),
          },
          {
            path: "payroll",
            children: [
              {
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <ComponentsSalaryPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "benefits-e-pays",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <BasicSalaryPayrollPage />
                          </Suspense>
                        ),

                      },
                      {
                        path: "add-schema-salary",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaSalary />
                          </Suspense>
                        ),
                      },
                      {
                        path: "edit-schema-salary/:id",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaSalary />
                          </Suspense>
                        ),

                      },
                    ],
                  },
                  {
                    path: "feast-day",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <FeastDayPayrollPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: "add-schema-feast-day",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaTHR />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: "uak",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <UAKPayrollPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: "add-schema-uak",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaUAK />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: "tax",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <TaxPayrollPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: "add-schema-tax",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaTAX />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: "prorate",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <ProratePayrollPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: "add-schema-prorate",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaProrate />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: "benefits-food",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <BenefitsFoodPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: "add-schema-benefits-food",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaBenefitsFood />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: "transportation",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <TransportationPage />
                          </Suspense>
                        ),
                      },
                      {
                        path: "add-schema-transportation",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaTransportation />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: "house",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <HousePage />
                          </Suspense>
                        ),
                      },
                      {
                        path: "add-schema-house",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaHouse />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: "overtime",
                    children: [
                      {
                        index: true,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <OvertimePage />
                          </Suspense>
                        ),
                      },
                      {
                        path: "add-schema-overtime",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <AddSchemaOvertime />
                          </Suspense>
                        ),
                      },
                      {
                        path: "detail-schema-overtime/:id",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <DetailSchemaOvertime />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "epay-slip",
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <h1>Komponen E-Pay Slip</h1>
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    loader: loginLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <LoginPage />
      </Suspense>

    )
  },
  {
    path: 'select-client',
    loader: selectClientLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <SelectClientPage />
      </Suspense>

    )
  },
  {
    path: 'flb',
    children: [
      {
        path: ':id',
        errorElement: <ErrorBoundary />,
        loader: viewFLBLoader,
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <Doc />
          </Suspense>
        ),
      }
    ]

  }


]);

export default router;
