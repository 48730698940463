import { API } from "configs";

export function getFeedbackList(
  page,
  limit = '',
  search
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/ticket`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
          params: { page, limit, search }
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}