import useQuery from '@Hooks/useQuery';
import SkeletonListEmployee from '@Molecule/Skeleton/Employee/ListEmployee';
import AttendanceDetailsLayout from '@Organism/Attendance/AttendanceDetails';
import { getAttendancesByEmployee, getDetailEmployee } from '@Services/employee/detailEmployee';
import moment from 'moment';
import { Suspense, useEffect, useState } from 'react';
import { Await, defer, useLoaderData, useLocation, useNavigate } from 'react-router-dom';

export const loader = (route) => {
    const params = new URLSearchParams(route?.request?.url);
    const { id } = route?.params;
    const date = params?.get('d') || moment(new Date()).format('YYYY-MM-DD');

    return defer({
        packageDetailEmployee: getDetailEmployee(id, '', '', ''),
        packageAttendanceByEmployee: getAttendancesByEmployee({
            employeeID: id,
            date: moment(date)?.format('YYYY-MM-DD'),
        }),
    });
};

export default function AttendanceDetailsPage() {
    const { packageAttendanceByEmployee, packageDetailEmployee } = useLoaderData();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const query = useQuery();
    // const { id } = useParams();
    const defaultDate = query.get('d') || moment()?.format('YYYY-MM-DD');
    const [valueDate, setValueDate] = useState(defaultDate);

    useEffect(() => {
        navigate(`${pathname}?d=${valueDate}`);
    }, [pathname, navigate, valueDate]);

    return (
        <Suspense fallback={<SkeletonListEmployee />}>
            <Await errorElement={<p>Error</p>} resolve={packageAttendanceByEmployee}>
                {(packageAttendanceByEmployee) => (
                    <Suspense fallback={<SkeletonListEmployee />}>
                        <Await errorElement={<p>Error</p>} resolve={packageDetailEmployee}>
                            {(packageDetailEmployee) => (
                                <AttendanceDetailsLayout
                                    data={packageAttendanceByEmployee?.data}
                                    dataPersonal={packageDetailEmployee?.data?.data}
                                    valueDate={valueDate}
                                    setValueDate={setValueDate}
                                />
                            )}
                        </Await>
                    </Suspense>
                )}
            </Await>
        </Suspense>
    );
}
