import AsyncErrorPage from "@Molecule/AsyncErrorPage";
import LoadingAnimationPage from "@Molecule/LoadingAnimationPage";
import PayrollResultLayout from "@Organism/Payroll/PayrollResult";
import { getHistoryDetails } from "@Services/payroll/getHistoryDetails";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const payrollResultLoader = (route) => {
  const url = new URL(route?.request?.url);
  const batchID = route?.params?.id

  const searchParams = url.searchParams
  const year = searchParams.get('y');
  const month = searchParams.get('mi');
  const pksID = searchParams.get('pi');

  return defer({
    packageResult: getHistoryDetails(pksID, year, `${+month + 1}`?.length === 1 ? `0${+month + 1}` : +month + 1, batchID)
  })
}

export default function PayrollResultPage() {
  const { packageResult } = useLoaderData()

  return (
    <Suspense
      fallback={
        <LoadingAnimationPage />
      }
    >
      <Await
        resolve={packageResult}
        errorElement={
          <AsyncErrorPage />
        }
      >
        {(packageResult) => (
          <PayrollResultLayout
            packageResult={packageResult?.data?.response}
          />
        )}

      </Await>

    </Suspense>
  )
}