import Modal from '@Atom/Modal';
import Styles from './style.module.scss';
import Icon from '@Atom/Icon';
import TagStatus from '@Atom/TagStatus';
import { rupiahFormat } from 'helpers/rupiahFormat';
import Button from '@Atom/Button';
import { useEffect, useState } from 'react';
import { getPayslipDetailByEmployee } from '@Services/employee/detailEmployee';
import moment from 'moment';

export default function ModalEpayslip({ data, isOpen, onClose }) {
    const [detail, setDetail] = useState();
    const [components, setComponents] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState()

    useEffect(() => {
        async function fetchDetail() {
            try {
                setIsLoading(true);
                const { response } = await getPayslipDetailByEmployee({ id: data?.id });
                setDetail(response);

                const induction = response?.components?.filter((f) => f.type === 'INDUCTION');
                const deduction = response?.components?.filter((f) => f.type === 'DEDUCTION');

                setComponents([
                    {
                        title: 'Detail Income',
                        list: induction,
                        total: induction.reduce((acc, curr) => {
                            return acc + parseInt(curr.value);
                        }, 0),
                    },
                    {
                        title: 'Deduction',
                        list: deduction,
                        total: deduction.reduce((acc, curr) => {
                            return acc + parseInt(curr.value);
                        }, 0),
                    },
                ]);
                setIsLoading(false);
            } catch (error) {
                console.log(error)
                setError(error?.message)
                setDetail({});
                setComponents([
                    {
                        title: 'Detail Income',
                        list: [],
                    },
                    {
                        title: 'Deduction',
                        list: [],
                    },
                ]);
                setIsLoading(false);
            }
        }
        if (isOpen) fetchDetail();
    }, [isOpen, data?.id]);

    const handleStatusType = (status) => {
        if (status) {
            switch (status) {
                case 'BERJALAN':
                    return 'blue';
                case 'BELUM TERBAYAR':
                    return 'red';
                case 'HOLD':
                    return 'yellow';
                case 'TERBAYAR':
                    return 'green';
                case 'LUNAS':
                    return 'green';
                default:
                    return '';
            }
        }
        return '';
    };

    return (
        <Modal isOpen={isOpen}>
            <div className={Styles.container}>
                {isLoading ? (
                    <div className={Styles.loading}>Loading...</div>
                ) : (
                    <>
                        <div className={Styles.header}>
                            <span>{`E-Pay Slip ${moment(data?.disbursedAt).format('MMMM YYYY')}`}</span>
                            <div className={Styles.iconClose} onClick={onClose}>
                                <Icon size={'16px'} icon={'close-circle'} />
                            </div>
                        </div>
                        <div className={Styles.body}>
                            {components?.map((mainValue, mainIdx) => (
                                <div className={Styles.cardBox} key={`card_${mainIdx}`}>
                                    <div className={Styles.title}>
                                        <span>{mainValue?.title}</span>
                                    </div>
                                    <div className={Styles.contentWrapper}>
                                        {mainValue?.list?.map((value, idx) => (
                                            <div className={Styles.childContent}>
                                                <span>{value?.label}</span>
                                                <span>{rupiahFormat(value?.value)}</span>
                                            </div>
                                        ))}
                                        <div className={Styles.divider} />
                                        <div className={Styles.totalWrapper}>
                                            <span>{'Total Income'}</span>
                                            <span>{rupiahFormat(mainValue?.total)}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className={Styles.takeHomePayWrapper}>
                                <div className={Styles.takeHomePay}>
                                    <span>{'Total Take Home Pay'}</span>
                                    <span>{rupiahFormat(detail?.totalTakeHomePay)}</span>
                                </div>
                                <div className={Styles.status}>
                                    <span>{'Status'}</span>
                                    <TagStatus text={data?.status} color={handleStatusType(data?.status)} />
                                </div>
                            </div>
                        </div>
                        <div className={Styles.footer}>
                            <Button isDisabled={Boolean(error)} icon={'download'} isLeftIcon text="Unduh" />
                        </div>
                    </>
                )}
                {error && (
                    <div className={Styles.errorWrapper}>
                        {error}
                    </div>
                )}
            </div>
        </Modal>
    );
}
