// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MWS_LOGO: require("@Assets/Images/myworkspace.png"),
  BNI_LOGO: require("@Assets/Images/BNI-logo.png"),
  LOGO: require("@Assets/Images/logo.png"),
  PDF: require("@Assets/Images/pdf.png"),
  AVA_DEFAULT: require("@Assets/Images/ava-default.png"),
  HEADER_BG: require("@Assets/Images/header-bg.png"),
  LOGIN_HEADER: require("@Assets/Images/login-bg-header.png"),
  RAFIKI: require("@Assets/Images/rafiki.png"),
  EXCEL: require("@Assets/Images/Excel-Icon.png"),
  LOADER: require("@Assets/Images/loader.png"),
  LOADER_V2: require("@Assets/Images/loader-v2.png"),
  DOT_BG: require("@Assets/Images/dot-bg.png"),
  PERMATA: require("@Assets/Images/permata-logo.png"),
  AUTH_BG: require("@Assets/Images/auth-bg-2.png"),
  KERJA365_LIGHT: require("@Assets/Images/kerja365-light.png"),
  COMPANY_OUTLINE: require(`@Assets/Images/company-outline.png`),
  FILE_AND_FOLDER: require("@Assets/Images/file-and-folder.png"),
  BRIEFCASE_OUTLINE: require(`@Assets/Images/briefcase-outline.png`),
  HALF_EYE: require(`@Assets/Images/half-eye.png`),
  INFORMATION_SOLID: require("@Assets/Images/information-solid.png"),
  BPJS: require("@Assets/Images/bpjs.png"),
  EMO_1: require("@Assets/Images/emo-1.png"),
  EMO_2: require("@Assets/Images/emo-2.png"),
  EMO_3: require("@Assets/Images/emo-3.png"),
  EMO_4: require("@Assets/Images/emo-4.png"),
  EMO_5: require("@Assets/Images/emo-5.png"),
  IMAGE_LOGO: require("@Assets/Images/image-logo.png"),
  PDF_LOGO: require("@Assets/Images/pdf-logo.png"),
};
