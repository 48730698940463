import React, { useState } from "react";
import Styles from "./style.module.scss";
import InputField from "@Atom/InputField";
import useWindowSize from "@Hooks/useWindowSize";
import DatePicker from "@Atom/DatePicker";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { formatBytes } from "helpers/formatBytes";
import ModalOrganization from "@Molecule/_modal/OrganizationMdal";
export default function FormDataStaffing({
  docStaffing,
  reffDocSatffing,
  setIsDocStaffing = () => {},
  handleUploadFileStaffing = () => {},
  progressDocStaffing,
  setInputDataDocStaffing = () => {},
  inputDataDocStaffing = {},
  isErrorFormDocStaffing,
  setIsErrorFormDocStaffing = () => {},
  topRef = null,
  selectedChart,
  setSelectedChart = () => {},
}) {
  const { width } = useWindowSize();
  const handleChangeInput = (name, value) => {
    if (value) {
      setIsErrorFormDocStaffing((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
    if (name === "nikEmployee") {
      const isNumber = /^[+-]?\d*\.?\d*$/.test(value);
      if (isNumber) {
        setInputDataDocStaffing((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setInputDataDocStaffing((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const dataListStatusEmployee = ["PKM", "PKWT", "PKWTT"];
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <section className={Styles.wrapperStaffing}>
      <ModalOrganization
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        selectedChart={selectedChart}
        setSelectedChart={setSelectedChart}
      />
      <div
        style={{
          padding: "0",
          margin: "0",
          height: "10px",
          marginBottom: "-26px",
        }}
        ref={topRef}
      ></div>
      <h2 className={Styles.headerStaffing}>Kepegawaian</h2>
      <div className={Styles.inputStaffing}>
        <h3>Data Kepegawaian</h3>
        <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
          <InputField
            isRequired
            isLabel
            textLabel="NIK (Nomor Induk Kepegawaian)"
            placeholder="Nomor Induk Kepegawaian"
            setValue={(val) => handleChangeInput("nikEmployee", val)}
            value={inputDataDocStaffing?.nikEmployee}
            isError={isErrorFormDocStaffing?.nikEmployee}
            textError="NIK Belum di isi"
          />
          <InputField
            isRequired
            textLabel="Status Karyawan"
            isDropdown
            placeholder="Pilih status karyawan"
            isLabel
            setValue={(val) => handleChangeInput("statusEmployee", val)}
            value={inputDataDocStaffing?.statusEmployee}
            isError={isErrorFormDocStaffing?.statusEmployee}
            textError="Status Karyawan Belum di pilih"
            dataMapDropdown={dataListStatusEmployee}
          />
        </div>
        <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
          <DatePicker
            isRequired
            textLabel="Tanggal Bergabung"
            placeholder="Pilih tanggal"
            isAddLabel
            setValue={(val) => handleChangeInput("dateStartJoined", val)}
            value={inputDataDocStaffing?.dateStartJoined}
            isError={isErrorFormDocStaffing?.dateStartJoined}
            textError="Tanggal Bergabung Belum di pilih"
          />
          <DatePicker
            isRequired
            textLabel="Tanggal Masa Akhir Kerja"
            placeholder="Pilih tanggal"
            isAddLabel
            setValue={
              inputDataDocStaffing?.statusEmployee === "PKWTT"
                ? () => {}
                : (val) => handleChangeInput("dateEndJobs", val)
            }
            value={
              inputDataDocStaffing?.statusEmployee === "PKWTT"
                ? null
                : inputDataDocStaffing?.dateEndJobs
            }
            isError={
              inputDataDocStaffing?.statusEmployee === "PKWTT"
                ? false
                : isErrorFormDocStaffing?.dateEndJobs
            }
            textError="Tanggal Masa Akhir Kerja Belum di pilih"
            isChecked={inputDataDocStaffing.statusEmployee === "PKWTT"}
            type="date"
          />
        </div>
        <div
          style={width < 851 ? { width: "100%" } : { width: "calc(50% - 8px)" }}
        >
          <InputField
            isRequired
            textLabel="Penempatan Kerja"
            isDropdown
            placeholder="Pilih penempatan kerja karyawan"
            isLabel
            setValue={(val) => handleChangeInput("workPlacement", val)}
            value={inputDataDocStaffing?.workPlacement}
            isError={isErrorFormDocStaffing?.workPlacement}
            textError="Penempatan Kerja Belum di isi"
          />
        </div>
        <section className={Styles.wrapperUploadDocument}>
          <h2>Unggah Dokumen Kontrak</h2>
          <div
            className={Styles.uploadDocument}
            style={
              docStaffing?.name
                ? { border: "none" }
                : {
                    borderColor: isErrorFormDocStaffing?.documentPdf
                      ? "#D42701"
                      : "inherit",
                  }
            }
          >
            <div className={Styles.between}>
              <div>
                <div
                  className={Styles.wrapperIcon}
                  is-mobile={(width < 851).toString()}
                >
                  <Icon
                    icon={docStaffing?.name ? "folder" : "document-upload"}
                    color={"#2E3192"}
                    size={"24"}
                  />
                </div>
                <div className={Styles.wrapperChange}>
                  <h3>
                    {docStaffing?.name ? (
                      <div>
                        <span className={Styles.fileName}>
                          {docStaffing?.name}
                        </span>
                        <div className={Styles.gap}>
                          <span
                            onClick={() => reffDocSatffing?.current?.click()}
                          >
                            Ubah File
                          </span>

                          <Icon
                            icon={
                              progressDocStaffing >= 100
                                ? "trash"
                                : "close-circle-delete"
                            }
                            color={
                              progressDocStaffing >= 100 ? "#D42701" : "#3E4856"
                            }
                            size={"16"}
                            alt="close"
                            onClick={() => {
                              setIsDocStaffing(null);
                              reffDocSatffing.current.value = "";
                              setIsErrorFormDocStaffing((prevErrors) => ({
                                ...prevErrors,
                                documentPdf: false,
                              }));
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    ) : (
                      "Unggah Dokumen"
                    )}
                  </h3>
                  <span className={Styles.desc}>
                    {docStaffing?.size
                      ? formatBytes(docStaffing?.size)
                      : "Unggah file dengan format dokumen PDF"}
                  </span>
                  {docStaffing?.name && (
                    <div className={Styles.loading}>
                      <div className={Styles.loader}>
                        <span
                          style={{
                            width: `${progressDocStaffing}%`,
                          }}
                        ></span>
                      </div>
                      <span
                        className={Styles.percentage}
                        style={{ height: "12px" }}
                      >
                        {progressDocStaffing === 100
                          ? progressDocStaffing
                          : progressDocStaffing + "%"}
                      </span>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  accept=".pdf"
                  ref={reffDocSatffing}
                  hidden
                  onChange={handleUploadFileStaffing}
                />
              </div>
              {!docStaffing?.name && (
                <div>
                  <Button
                    text="Browse File"
                    style={{
                      background: "white",
                      color: "#2E3192",
                      fontSize: "12px",
                      width: "79px",
                      padding: "8px 6px",
                      height: "30px",
                    }}
                    isborder
                    onClick={() => reffDocSatffing?.current?.click()}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      <div className={Styles.inputOrganization}>
        <h3>Organisasi</h3>
        <div className={width < 768 ? Styles.betweenInput : Styles.flexInput}>
          <InputField
            isRequired
            isLabel
            textLabel="Posisi"
            placeholder="Pilih Posisi karyawan"
            isDropdown
            setValue={(val) => handleChangeInput("position", val)}
            value={inputDataDocStaffing?.position}
            isError={isErrorFormDocStaffing?.position}
            textError="Posisi Akhir Kerja Belum di isi"
          />
          <InputField
            isRequired
            textLabel="Grade"
            placeholder="Pilih Grade"
            isLabel
            setValue={(val) => handleChangeInput("grade", val)}
            value={inputDataDocStaffing?.grade}
            isError={isErrorFormDocStaffing?.grade}
            textError="Grade Kerja Belum di isi"
            isDropdown
          />
        </div>
        <div
          style={width < 851 ? { width: "100%" } : { width: "calc(50% - 8px)" }}
        >
          <InputField
            isRequired
            textLabel={"Organization Chart Box"}
            isDropdown
            // placeholder={
            //   inputDataDocStaffing.statusEmployee === "PKWT"
            //     ? "Pilih Chart"
            //     : "Pilih Departement karyawan"
            // }
            placeholder="Pilih Chart"
            isLabel
            value={inputDataDocStaffing?.department}
            // value={selectedChart.chartName}
            isError={isErrorFormDocStaffing?.department}
            textError="Departement Karyawan Belum di isi"
            // isButtonChart={inputDataDocStaffing.statusEmployee === "PKWT"}
            isButtonChart
            onClick={() => setIsOpenModal(true)}
          />
        </div>
      </div>
    </section>
  );
}
