import moment from "moment"
import { getDaysInMonth } from "./getDaysInMonth";

export const get35DaysInMonth = (dateObj, isStartingOnSunday = false) => {
  const date = moment(new Date(dateObj))?.format('YYYY-MM-01')
  const startFillerAmount = new Date(date)?.getDay() - (!isStartingOnSunday ? 1 : 0)
  var priorDates = [];

  for (let i = 0; i < startFillerAmount; i++) {
    priorDates?.unshift(new Date(new Date().setDate(new Date(date).getDate() - (i + 1))))
  }

  const currentDates = getDaysInMonth(new Date(date)?.getMonth(), new Date(date)?.getFullYear(), 'dates')


  const endFillerAmount = 35 - (+priorDates?.length + currentDates?.length)

  const nextDates = []

  for (let i = 0; i < endFillerAmount; i++) {
    nextDates?.push(new Date(new Date(date).setDate(new Date(date).getDate() + (currentDates?.length + i))))
  }

  return [...priorDates, ...currentDates, ...nextDates]

}