export const rupiahFormat = (number)=>{
  let fixNumber = number
  if(typeof fixNumber !== "number" && fixNumber?.includes(".")){
    fixNumber = fixNumber.split(".").join("")
  }

  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0
  }).format(fixNumber);
}