import TabHeader from "@Atom/TabHeader";
import { useMemo, useState } from "react";
import Styles from "./style.module.scss"
import Thumbnail from "@Atom/Thumbnail";
import TagStatus from "@Atom/TagStatus";
import Table from "@Molecule/Table";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAssetByEmployee } from "@Services/employee/detailEmployee";
import { fileBaseUrl } from "configs";

// eslint-disable-next-line no-unused-vars
const defaultDataAsset = {
      goods: [
        {
            name: "Lenovo Thinkpad A-492NF",
            code: "2D01234",
            receivedDate: "12 Sep 2023",
            returnDate: "12 Sep 2024",
            category: "Laptop",
            photo: "https://cdn.pixabay.com/photo/2022/12/08/23/11/ai-generated-7644202_960_720.jpg",
            status: "false"
        },
        {
            name: "Lenovo Thinkpad A-492NF",
            code: "2D01234",
            receivedDate: "12 Sep 2023",
            returnDate: "",
            category: "Laptop",
            photo: "https://cdn.pixabay.com/photo/2024/03/29/17/43/ai-generated-8663299_960_720.png",
            status: "true"
        },
        {
            name: "Lenovo Thinkpad A-492NF",
            code: "2D01234",
            receivedDate: "12 Sep 2023",
            returnDate: "",
            category: "Laptop",
            photo: "",
            status: ""
        }
      ]
}

export default function DetailAssetEmployee({
    idNumber,
    dataPersonal,
    setViewFile,
    setShowFile,
}){
    const {id} = useParams()
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(5)
    const [sort, setSort] = useState({})
    const [totalPage, setTotalPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    useEffect(() => {
        async function fetch() {
            try {
                setIsLoading(true)
                const data = await getAssetByEmployee({id, page, limit})
                setData(data?.data?.assets ?? [])
                setTotalPage(data?.totalPages ?? 1)
                setIsLoading(false)
            } catch (error) {
                console.log(error)
                setError(error?.response?.data?.message || error?.message)
                setData([])
                setIsLoading(false)
            }
        }
        fetch()
    }, [id, limit, page])

    const handleStatusType = (status) => {
        if (status){
            switch(status){
                case "DIPINJAM":
                    return "blue"
                case "BELUM TERBAYAR":
                    return "red"
                case "HOLD":
                    return "yellow"
                case "TERBAYAR":
                    return "green"
                case "LUNAS":
                    return "green"
                default : return ""
            }
        } 
        return ""
    }

    const handleViewFile = (data, key) => {
        if (data[key]){
            setViewFile({
                type: "image",
                url: data[key]
            })
            setShowFile(true)
        }
    }

    const template = useMemo(() => {
        return {
            data : data?.map((value, idx) => {
                return {
                    id: idx,
                    name: value?.asset?.name,
                    code: value?.asset?.code,
                    receivedDate: value?.lendAt,
                    returnDate: value?.returnedAt,
                    category: value?.asset?.category,
                    photoGoods: fileBaseUrl + value?.asset?.imageURI,
                    status: value?.asset?.status
                }
            }) ,
            columns: [
                {
                    name: "name",
                    width: "30%",
                    title: 
                        <TabHeader 
                            text="Nama Barang" 
                            column={"name"} 
                            sort={sort} 
                            setSort={setSort} 
                            isSorted
                        />,
                    renderData: (row) => (
                        <div className={Styles.goodsNameWrapper}>
                            <span>{row?.name}</span>
                            <span>{row?.category}</span>
                        </div>
                    )
                },
                {
                    name: "code",
                    width: "20%",
                    hideOnMobile: true,
                    title: 
                        <TabHeader 
                            text="Kode Barang" 
                            column={"code"} 
                            sort={sort} 
                            setSort={setSort} 
                            isSorted
                        />,
                },
                {
                    name: "receivedDate",
                    width: "10%",
                    hideOnMobile: true,
                    title: 
                        <TabHeader 
                            text="Tgl Terima" 
                            column={"receivedDate"} 
                            sort={sort} 
                            setSort={setSort} 
                            isSorted
                        />,
                },
                {
                    name: "returnDate",
                    width: "10%",
                    hideOnMobile: true,
                    title: 
                        <TabHeader 
                            text="Tgl Kembali" 
                            column={"returnDate"} 
                            sort={sort} 
                            setSort={setSort} 
                            isSorted
                        />,
                },
                {
                    name: "photoGoods",
                    width: "10%",
                    title: <TabHeader text="Foto" style={{justifyContent: "center", width: "100%"}} />,
                    align: "center",
                    hideOnMobile: true,
                    renderData: (row) => (
                        <div className={Styles.photoWrapper} onClick={() => {handleViewFile(row, 'photoGoods')}}>
                            <Thumbnail src={row?.photoGoods} alt={"thumbnail"} height={"44px"} width={"66px"}/>
                        </div>
                    )
                },
                {
                    name: "status",
                    width: "20%",
                    align: "center",
                    title: 
                        <TabHeader 
                            text="Status" 
                            column={"status"} 
                            sort={sort} 
                            setSort={setSort} 
                            isSorted
                            style={{justifyContent: "center", width: "100%"}}
                        />,
                    renderData: (row) => (
                        <div className={Styles.photoWrapper}>
                            <TagStatus text={row?.status} color={handleStatusType(row?.status)}/>
                        </div>
                    )
                },
            ]
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, sort])

    return (
        <div className={Styles.content}>
            <Table
                className={Styles.tableRadius}
                data={template.data}
                columns={template.columns}
                page={page}
                limit={limit}
                totalData={data?.length}
                totalPage={totalPage}
                setLimit={setLimit}
                setPage={setPage}
                withPagination
                withNumbering
                isLoading={isLoading}
                // expandComponent={(row) => (
                //     <>
                //         <span>Atasan Langsung</span>
                //         <div className={Styles.supervisorWrapper}>
                //             <Avatar imgUrl={row?.directSupervisor?.profilePicture} initial={row?.directSupervisor?.name[0]} />
                //             <div className={Styles.supervisorContent}>
                //                 <span>{row?.directSupervisor?.name}</span>
                //                 <span>{row?.directSupervisor?.position}</span>
                //             </div>
                //         </div>
                //     </>
                // )}
            />
            {error && (
                <div className={Styles.errorWrapper}>
                    {error}
                </div>
            )}
        </div>
    )

}