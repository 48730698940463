import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import SearchBar from '@Atom/SearchBar'
import Icon from '@Atom/Icon'
import Table from '@Molecule/Table'
import TabHeader from '@Atom/TabHeader'
import moment from 'moment'
import FeedbackFilterSlider from '@Molecule/_modal/FeedbackFilterSlider'
import AssignTicketModal from '@Molecule/_modal/AssignTicketModal'
import { useLocation, useNavigate } from 'react-router-dom'
import Images from '@Theme/Images'
import useQuery from '@Hooks/useQuery'
import { useDebounce } from '@Hooks/useDebounce'

export default function FeedbackListLayout({
  packageList
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()
  const [activeTab, setActiveTab] = useState('all')
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isChecked, setIsChecked] = useState([]);

  const [showFilter, setShowFilter] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState([])
  const [showAssign, setShowAssign] = useState(false)

  const role = useMemo(()=> {
    return 'ADMIN'
  }, [])

  const template = useMemo(() => {
    return {
      data: packageList?.data?.map(obj => {
        return {
          id: obj?.id,
          ticketID: obj?.id,
          user: {
            name: obj?.employeeName,
            profilePicture: Images.AVA_DEFAULT,
            clientName: obj?.clientName,
          },
          timestamp: new Date(obj?.createdAt),
          handler: null,
          firstResponseTime: '-',
          averageResponseTime: '-',
          sla: '-',
          status: [obj?.status === 'CLOSE' ? 'CLOSED' : 'OPEN', obj?.stage === 'ON-PROSESS' ?'ON-PROGRESS' : obj?.stage]
        }
      }),
      data1: [
        {
          id: 1,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: null,
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['OPEN', 'NEW', 'FORWARDED-FROM']
        },
        {
          id: 2,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: null,
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['OPEN', 'NEW', 'VIOLATED']
        },
        {
          id: 3,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['CLOSED', 'ON-PROGRESS', 'FORWARDED-TO']
        },
        {
          id: 4,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['CLOSED', 'ON-PROGRESS', 'VIOLATED', 'FORWARDED-TO', 'MESSAGE-2']
        },
        {
          id: 5,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['OPEN', 'ON-PROGRESS', 'FORWARDED-FROM', 'MESSAGE-2']
        },
        {
          id: 6,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['OPEN', 'ON-PROGRESS', 'VIOLATED']
        },
        {
          id: 7,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['CLOSED', 'FINISHED']
        },
        {
          id: 8,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['CLOSED', 'FINISHED', 'VIOLATED']
        },
        {
          id: 9,
          ticketID: 'S039203',
          user: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          timestamp: new Date(),
          handler: {
            name: 'Yon Safira',
            profilePicture: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
            clientName: 'PT. Tirta Mulia Abadi',
          },
          firstResponseTime: '25m',
          averageResponseTime: '25m',
          sla: '3 hari',
          status: ['CLOSED', 'FINISHED']
        },
      ],
      totalData: 10,
      columns: [
        {
          name: "ticketID",
          title: <TabHeader text="ID" />,

        },
        {
          name: "user",
          title: 'Nama Pelapor',
          renderData: (row) => (
            <div className={Styles.profile}>
              <img alt='' src={row?.user?.profilePicture} />
              <div>
                <span>{row?.user?.name}</span>
                <p>{row?.user?.clientName}</p>
              </div>
            </div>
          ),
        },
        {
          name: "date",
          title: 'Tgl Komplain',
          renderData: (row) => (
            <div className={Styles.timestamp}>
              <div>
                <Icon color={'#718198'} icon={'calendar'} size={12} />
                <span>{moment(row?.timestamp)?.format('ll')}</span>
              </div>
              <div>
                <Icon color={'#718198'} icon={'clock-solid'} size={12} />
                <span>{moment(row?.timestamp)?.format('LT')?.replace('.', ':')}</span>
              </div>
            </div>
          ),
        },
        {
          name: "date",
          title: 'Penanggung Jawab',
          renderData: (row) => (
            row?.handler
              ?
              <div className={Styles.profile}>
                <img alt='' src={row?.handler?.profilePicture} />
                <div>
                  <span>{row?.handler?.name}</span>
                  <p>{row?.handler?.clientName}</p>
                </div>
              </div>
              :
              <span>-</span>
          ),
        },
        {
          name: "date",
          title: 'First Resp. Time',
          renderData: (row) => (
            <div className={Styles.time}>
              <Icon color={'#718198'} icon={'clock-solid'} size={16} />
              <span>{row?.firstResponseTime}</span>
            </div>
          ),
        },
        {
          name: "date",
          title: 'Avg. Resp. Time',
          renderData: (row) => (
            <div className={Styles.time}>
              <Icon color={'#718198'} icon={'clock-solid'} size={16} />
              <span>{row?.averageResponseTime}</span>
            </div>
          ),
        },
        {
          name: "telephone",
          title: <TabHeader text="SLA" />,
          renderData: (row) => (
            <div className={Styles.time}>
              <Icon color={'#718198'} icon={'clock-solid'} size={16} />
              <span>{row?.sla}</span>
            </div>
          ),
        },
        {
          name: "",
          title: <TabHeader text="Status" />,
          renderData: (row) => {
            const status = row?.status[1]
            const text = status === 'NEW'
              ? 'Baru'
              : (
                status === 'ON-PROGRESS'
                  ?
                  'Dalam Proses'
                  :
                  'Selesai'
              )
            const textStyle = status === 'NEW'
              ? 'new'
              : (
                status === 'ON-PROGRESS'
                  ?
                  'onProgress'
                  :
                  'finished'
              )
            return (
              <div className={Styles.status}>
                <div
                  style={{ backgroundColor: row?.status?.find(obj => obj === 'OPEN') ? '#CEEAD3' : '#DEE2E7' }}
                  className={Styles.circle}
                />
                <span className={Styles[textStyle]}>{text}</span>
                <Icon color={row?.status?.find(obj => obj === 'FORWARDED-FROM') ? '#22CFBA' : '#DEE2E7'} icon={'document-previous'} size={20} />
                <Icon color={row?.status?.find(obj => obj === 'VIOLATED') ? '#D42701' : '#DEE2E7'} icon={'flag'} size={20} />
                <Icon color={row?.status?.find(obj => obj === 'FORWARDED-TO') ? '#2E3192' : '#DEE2E7'} icon={'document-forward'} size={20} />
                <div className={Styles.messageIcon}>
                  <Icon color={row?.status?.find(obj => obj?.includes('MESSAGE')) ? '#22970F' : '#DEE2E7'} icon={'message'} size={20} />
                  {
                    row?.status?.find(obj => obj?.includes('MESSAGE'))
                    &&
                    <span>
                      {row?.status?.find(obj => obj?.includes('MESSAGE'))?.split('-')[1]}
                    </span>
                  }
                </div>
              </div>
            )
          }

        },
      ],
    };
  }, [packageList?.data]);

  const debouncedSearch = useDebounce(search, 300)

  useEffect(()=> {
    if(page !== +query?.get('p') || limit !== +query?.get('l') || debouncedSearch !== +query?.get('s')){
      navigate(`${pathname}?p=${page}&l=${limit}&s=${debouncedSearch}`, {replace: true})
    }
  }, [debouncedSearch, limit, navigate, page, pathname, query])

  return (
    <div className={Styles.container}>
      <div className={Styles.containerWrapper}>
        <div className={Styles.container}>
          <div className={Styles.header}>
            <h3>Daftar Pengaduan</h3>
            <div className={Styles.tabs}>
              {
                [
                  {
                    title: 'Semua',
                    value: 'all'
                  },
                  {
                    title: 'Open',
                    value: 'open'
                  },
                  {
                    title: 'Closed',
                    value: 'closed'
                  },
                  {
                    title: 'Flagged',
                    value: 'flagged'
                  },
                  {
                    title: 'Butuh Dijawab',
                    value: 'need answer',
                    label: '22'
                  },
                ]
                  ?.map((tab, i) => (
                    <div key={i} className={`${Styles.tab} ${Styles[activeTab === tab?.value ? 'active' : '']}`} onClick={() => setActiveTab(tab?.value)}>
                      <span>{tab?.title}</span>
                      {
                        tab?.label &&
                        <div>{tab?.label}</div>
                      }
                    </div>
                  ))
              }
            </div>
            <div className={Styles.filter}>
              <div className={Styles.left}>
                <div className={Styles.searchWrapper}>
                  <SearchBar
                    value={search}
                    setValue={setSearch}
                    placeholder='Cari nama atau NRK'
                    backgroundColor='#fff'
                  />
                </div>
                <button onClick={() => setShowFilter(true)}>
                  <span>Filter</span>
                  <Icon icon={'setting-4'} size={16} />
                </button>
                {
                  role === "ADMIN"
                  &&
                  !!isChecked?.length
                  &&
                  <button onClick={() => setShowAssign(true)}>
                  <span>Assign</span>
                  <Icon icon={'send-2'} size={16} />
                </button>

                }
              </div>

              <div className={Styles.legends}>
                {
                  [
                    {
                      title: 'Ticket Dalam Status Closed',
                      icon: <div style={{ backgroundColor: '#DEE2E7' }} className={Styles.circle} />
                    },
                    {
                      title: 'Ticket Dalam Status Open',
                      icon: <div style={{ backgroundColor: '#CEEAD3' }} className={Styles.circle} />
                    },
                    {
                      title: 'Ticket Dari Dept. Lain',
                      icon: <Icon color={'#22CFBA'} icon={'document-previous'} size={20} />
                    },
                    {
                      title: 'Pesan Belum Dibalas',
                      icon: <Icon color={'#22970F'} icon={'message'} size={20} />
                    },
                    {
                      title: 'Terdapat Pelanggaran SLA',
                      icon: <Icon color={'#D42701'} icon={'flag'} size={20} />
                    },
                    {
                      title: 'Diteruskan ke Dept. Lain',
                      icon: <Icon color={'#2E3192'} icon={'document-forward'} size={20} />
                    },
                  ]
                    ?.map((legend, i) => (
                      <div key={i} className={Styles.legend}>
                        {legend?.icon}
                        <span>{legend?.title}</span>
                      </div>
                    ))
                }
              </div>
            </div>
          </div>
          <div className={Styles.content}>
            <Table
              className={Styles.table}
              data={template.data}
              columns={template.columns}
              page={page}
              limit={limit}
              totalData={template.totalData || 0}
              setLimit={setLimit}
              setPage={setPage}
              totalPage={Math.ceil(template.totalData / limit)}
              withPagination
              withNumbering
              widthNumber="10px"
              withCheckbox={role === 'ADMIN'}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              onClickRow={(row) => navigate(`${pathname}/${row?.ticketID}`)}
            />
          </div>
        </div>
      </div>
      <FeedbackFilterSlider
        show={showFilter}
        onClose={() => setShowFilter(false)}
      />
      <AssignTicketModal
        show={showAssign}
        data={selectedTickets}
        onClose={() => setShowAssign(false)}
        onSuccess={() => {
          setSelectedTickets([])
        }}
      />
    </div>
  )
}