import Styles from './style.module.scss'

export default function ProgressBar({
  percentage,
  height = '10px'
}) {
  return (
    <div style={{ height }} className={Styles.container}>
      <div style={{ width: `${percentage}%`, height }} className={Styles.fulfilled} />
    </div>
  )
}