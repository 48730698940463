import { API } from "configs";

export function getPKSByClientCode(
  clientCode
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/payroll/${clientCode}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}