import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useNavigate } from "react-router-dom";
export default function IsEmptyEmployee({ onClick }) {
  const navigate = useNavigate();
  return (
    <main className={Styles.containerIsEmpty}>
      <section className={Styles.wrapperEmpty}>
        <div className={Styles.headerText}>
          <h2>Belum Ada Data Karyawan</h2>
          <p>Silahkan pilih cara penambahan karyawan untuk perusahaan anda</p>
        </div>
        <div className={Styles.cardUploader}>
          <div className={Styles.uploadDataEmployee} onClick={onClick}>
            <Icon icon={"document-upload"} className={Styles.iconUpload} />
            <h2>Upload Data Karyawan</h2>
            <p>
              Upload data karyawan anda untuk dapat mengisi data karyawan secara
              otomatis
            </p>
          </div>
          <div
            className={Styles.uploadAddEmployee}
            onClick={() => navigate("add-employee")}
          >
            <Icon icon={"profile-add"} className={Styles.iconUpload} />
            <h2>Tambah Karyawan</h2>
            <p>
              Tambah karyawan secara manual untuk mengisi daftar karyawan anda
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}
