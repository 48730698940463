import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import ModalEpayslip from "@Atom/ModalEpayslip";
import ModalLoan from "@Atom/ModalLoan";
import TabHeader from "@Atom/TabHeader";
import TagStatus from "@Atom/TagStatus";
import Thumbnail from "@Atom/Thumbnail";
import TooltipTab from "@Atom/TooltipTab";
import Table from "@Molecule/Table";
import Tabs from "@Molecule/Tabs";
import { getPayslipByEmployee } from "@Services/employee/detailEmployee";
import { downloadExcel } from "helpers/downloadExcel";
import { rupiahFormat } from "helpers/rupiahFormat";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Styles from "./style.module.scss";

// eslint-disable-next-line no-unused-vars
const defaultDataFinance = {
    payroll: [
        {
            date: "12 Sep 2023",
            workDayTotal: 30,
            thp: "100.000.000",
            status: "Terbayar",
            details: [
                {
                    title: "Detail Income",
                    list: [
                        {
                            title: "Basic Salary",
                            value: 6000000
                        },
                        {
                            title: "TTUM",
                            value: 600000
                        },
                    ],
                    total: 7500000,
                },
                {
                    title: "Deduction",
                    list: [
                        {
                            title: "BPJS JTH TKO",
                            value: 150000
                        },
                        {
                            title: "BPJS JP TKO",
                            value: 75000
                        },
                        {
                            title: "BPJS Kesehatan TKO",
                            value: 75000
                        },
                        {
                            title: "PPH21 atas gaji",
                            value: 0
                        }
                    ],
                    total: 300000,
                },
            ],
            takeHomePay: 7200000
        },
        {
            date: "12 Sep 2023",
            workDayTotal: 30,
            thp: "100.000.000",
            status: "Hold",
            details: [
                {
                    title: "Detail Income",
                    list: [
                        {
                            title: "Basic Salary",
                            value: 6000000
                        },
                        {
                            title: "TTUM",
                            value: 700000
                        },
                    ],
                    total: 7500000,
                },
                {
                    title: "Deduction",
                    list: [
                        {
                            title: "BPJS JTH TKO",
                            value: 150000
                        },
                        {
                            title: "BPJS JP TKO",
                            value: 75000
                        },
                        {
                            title: "BPJS Kesehatan TKO",
                            value: 75000
                        },
                        {
                            title: "PPH21 atas gaji",
                            value: 0
                        }
                    ],
                    total: 300000,
                }
            ],
            takeHomePay: 7300000
        }
    ],
    loan: [
        {
            applicationDate: "12 Sep 2023",
            approvalDate: "12 Okt 2023",
            applicationTotal: "3.000.000",
            disbursement: "2.800.000",
            tenor: "3",
            status: "Berjalan",
            details: {
                list: [
                    {
                        title: "Total Pengajuan",
                        value: 4500000
                    },
                    {
                        title: "Biaya Admin dan Transfer",
                        value: 150000
                    },
                    {
                        title: "Cicilan perbulan",
                        value: 1500000
                    },
                    {
                        title: "Tenor",
                        value: "3 Bulan"
                    }
                ],
                total: 4350000,
            },
            history: [
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                }
            ]
        },
        {
            applicationDate: "12 Sep 2023",
            approvalDate: "12 Okt 2023",
            applicationTotal: "3.000.000",
            disbursement: "2.800.000",
            tenor: "12",
            status: "Lunas",
            details: {
                list: [
                    {
                        title: "Total Pengajuan",
                        value: 4500000
                    },
                    {
                        title: "Biaya Admin dan Transfer",
                        value: 150000
                    },
                    {
                        title: "Cicilan perbulan",
                        value: 1500000
                    },
                    {
                        title: "Tenor",
                        value: "3 Bulan"
                    }
                ],
                total: 4350000,
            },
            history: [
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: ""
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: ""
                },
            ]
        },
        {
            applicationDate: "12 Sep 2023",
            approvalDate: "12 Okt 2023",
            applicationTotal: "3.000.000",
            disbursement: "2.800.000",
            tenor: "3",
            status: "Berjalan",
            details: {
                list: [
                    {
                        title: "Total Pengajuan",
                        value: 4500000
                    },
                    {
                        title: "Biaya Admin dan Transfer",
                        value: 150000
                    },
                    {
                        title: "Cicilan perbulan",
                        value: 1500000
                    },
                    {
                        title: "Tenor",
                        value: "3 Bulan"
                    }
                ],
                total: 4350000,
            },
            history: [
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: "Lunas"
                }
            ]
        },
        {
            applicationDate: "12 Sep 2023",
            approvalDate: "12 Okt 2023",
            applicationTotal: "3.000.000",
            disbursement: "2.800.000",
            tenor: "12",
            status: "Lunas",
            details: {
                list: [
                    {
                        title: "Total Pengajuan",
                        value: 4500000
                    },
                    {
                        title: "Biaya Admin dan Transfer",
                        value: 150000
                    },
                    {
                        title: "Cicilan perbulan",
                        value: 1500000
                    },
                    {
                        title: "Tenor",
                        value: "3 Bulan"
                    }
                ],
                total: 4350000,
            },
            history: [
                {
                    date: "Oktober 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "November 2023",
                    value: 1500000,
                    status: "Lunas"
                },
                {
                    date: "Desember 2023",
                    value: 1500000,
                    status: "Lunas"
                }
            ]
        },
    ],
    reimbursement: [
        {
            applicationDate: "12 Sep 2023",
            category: "Transportasi Dinas",
            total: "100.000.000",
            information: "Dinas ke Jepang",
            photo: "",
            status: "Belum Terbayar"
        },
        {
            applicationDate: "12 Sep 2023",
            category: "Transportasi Dinas",
            total: "100.000.000",
            information: "Dinas ke Jepang",
            photo: "https://cdn.pixabay.com/photo/2022/12/08/23/11/ai-generated-7644202_960_720.jpghttps://cdn.pixabay.com/photo/2022/12/08/23/11/ai-generated-7644202_960_720.jpg",
            status: "Terbayar"
        },
        {
            applicationDate: "12 Sep 2023",
            category: "Transportasi Dinas",
            total: "100.000.000",
            information: "Dinas ke Swiss",
            photo: "https://cdn.pixabay.com/photo/2022/12/08/23/11/ai-generated-7644202_960_720.jpghttps://cdn.pixabay.com/photo/2022/12/08/23/11/ai-generated-7644202_960_720.jpg",
            status: "Terbayar"
        },
    ]
}

export default function DetailFinanceEmployee({
    dataPersonal,
    setViewFile,
    setShowFile    
}) {
    const {id} = useParams()
    const [data, setData] = useState();
    const [isActiveTabs, setIsActiveTabs] = useState(0);
    const [isChecked, setIsChecked] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(5)
    const [sort, setSort] = useState({})
    const [totalPage, setTotalPage] = useState(1)
    const [selectedEpayslip, setSelectedEpayslip] = useState(null)
    const [selectedLoan, setSelectedLoan] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const tabs = ["Payroll", "Pinjaman", "Reimbursement"]
    // eslint-disable-next-line no-unused-vars
    const tempObj = ["payroll", "loan", "reimbursement"]

    useEffect(() => {
        async function fetch() {
            try {
                setIsLoading(true)
                const {response} = await getPayslipByEmployee({
                    id: id,
                    page,
                    limit,
                    sort: sort?.type || '',
                    sortBy: sort?.column || '',
                });
                setTotalPage(response?.totalPage);
                setData(response?.data);
                setIsLoading(false)
            } catch (error) {
                console.log(error);
                setData([]);
                setError(error?.response?.data?.message || error?.message)
                setIsLoading(false)
            }
        }
        fetch();
    }, [page, limit, sort, id]);

    const downloadTable = () => {
        switch(isActiveTabs){
            case 0:
                const columns = [
                    {
                        header: 'Employee ID',
                        key: 'employeeID',
                        width: 25
                    },
                    {
                        header: 'Disbursed At',
                        key: 'disbursedAt',
                        width: 20
                    },
                    {
                        header: 'Work Days',
                        key: 'workDays',
                        width: 20
                    },
                    {
                        header: 'Total Take Home Pay',
                        key: 'totalTakeHomePay',
                        width: 30
                    },
                    {
                        header: 'Status',
                        key: 'status',
                        width: 20
                    },
                    {
                        header: 'Remark',
                        key: 'remark',
                        width: 20
                    },
                    {
                        header: 'Created At',
                        key: 'createdAt',
                        width: 20
                    },
                    {
                        header: 'Updated At',
                        key: 'updatedAt',
                        width: 20
                    },
                ]
                const row = isChecked?.map((value) => {
                    return {
                        ...value,
                        disbursedAt: moment(value?.disbursedAt).format('YYYY-MM-DD hh:mm:ss'),
                        createdAt: moment(value?.createdAt).format('YYYY-MM-DD hh:mm:ss'),
                        updatedAt: moment(value?.updatedAt).format('YYYY-MM-DD hh:mm:ss'),
                    }
                })

                downloadExcel(columns, row, `Payroll-${dataPersonal?.name}`, true)
                break;
            case 1:
                break;
            case 2:
                break;
            default:
                break;
        }
    }

    const handleViewFile = (data, key) => {
        if (data[key]){
            setViewFile({
                type: "image",
                url: data[key]
            })
            setShowFile(true)
        }
    }

    const handleShowEpayslip = (row) => {
        if (row){
            setSelectedEpayslip(row)   
        } else {
            setSelectedEpayslip(null)
        }
    }

    const handleShowLoan = (row) => {
        if (row){
            setSelectedLoan(row)   
        } else {
            setSelectedLoan(null)
        }
    }

    //Do every Tab changes
    // useEffect(() => {
    //     setIsChecked([])

    //     const mapData = defaultDataFinance?.[tempObj[isActiveTabs]]?.map((value, idx) => {
    //         return {
    //             ...value,
    //             id: idx
    //         }
    //     })
    //     setData(mapData)
    // }, [isActiveTabs])

    const handleStatusType = (status) => {
        if (status){
            switch(status){
                case "BERJALAN":
                    return "blue"
                case "BELUM TERBAYAR":
                    return "red"
                case "HOLD":
                    return "yellow"
                case "TERBAYAR":
                    return "green"
                case "LUNAS":
                    return "green"
                default : return ""
            }
        } 
        return ""
    }
    
    const payrollColumns = useMemo(() => [
        {
            name: 'disbursedAt',
            title: <TabHeader text="Tahun" column={'disbursedAt'} sort={sort} setSort={setSort} isSorted />,
            hideOnMobile: true,
            renderData: (row) => <span style={{cursor: "pointer"}} onClick={() => handleShowEpayslip(row)}>{moment(row?.disbursedAt).format('MMM yyyy') }</span>,
        },
        {
            name: 'workDays',
            align: "left",
            title: <TabHeader text="Total Hari Kerja" column={'workDays'} sort={sort} setSort={setSort} isSorted />,
            renderData: (row) => <span>{row?.workDays} Hari</span>,
        },
        {
            name: 'totalTakeHomePay',
            title: <TabHeader text="THP" column={'totalTakeHomePay'} sort={sort} setSort={setSort} isSorted />,
            renderData: (row) => <span>{rupiahFormat(row?.totalTakeHomePay)}</span>,
        },
        {
            name: "status",
            align: "center",
            width: "15%",
            title: <TabHeader text="Status" column={"status"} sort={sort} setSort={setSort} isSorted style={{width: "100%", justifyContent:"center"}}/>,
            renderData: (row) => (
                <div className={Styles.statusWrapper}>
                    <TagStatus text={row?.status} color={handleStatusType(row?.status)} />
                </div>
            ),
        },
        {
            name: 'action',
            width: '10%',
            align: "center",
            title: <TabHeader text="Aksi" style={{ justifyContent: "center", width: "100%" }} />,
            renderData: (row) => (
                <div className={Styles.actionMenu}>
                    <Icon icon={"edit-2"} color={"#2E3192"} size={"20"} className={Styles.icon} />
                    <Icon icon={"trash"} color={"red"} size={"20"} className={Styles.icon} />
                </div>
            ),
            hideOnMobile: true,
        }
    ], [sort]);

    const loanColumns = useMemo(() => [
        {
            name: 'applicationDate',
            title: (
                <TabHeader
                    text="Tgl Pengajuan"
                    column={'applicationDate'}
                    sort={sort}
                    setSort={setSort}
                    isSorted
                />
            ),
            hideOnMobile: true,
            renderData: (row) => (
                <div onClick={() => handleShowLoan(row)}>
                    <span>{row?.applicationDate}</span>
                </div>
            ),
        },
        {
            name: 'approvalDate',
            align: 'left',
            title: (
                <TabHeader
                    text="Tgl Disetujui"
                    column={'approvalDate'}
                    sort={sort}
                    setSort={setSort}
                    isSorted
                />
            ),
            renderData: (row) => (
                <div>
                    <span>{row?.approvalDate}</span>
                </div>
            ),
        },
        {
            name: 'applicationTotal',
            align: 'right',
            title: (
                <TabHeader
                    text="Total Pengajuan"
                    column={'applicationTotal'}
                    sort={sort}
                    setSort={setSort}
                    isSorted
                    style={{width: "100%", justifyContent: "right"}}
                />
            ),
            renderData: (row) => (
                <div>
                    <span>{rupiahFormat(row?.applicationTotal)}</span>
                </div>
            ),
        },
        {
            name: 'disbursement',
            align: 'right',
            title: (
                <TooltipTab 
                    content={'Total pencairan telah dikurangi dari biaya admin & transfer.'} 
                    noWhiteSpace 
                    direction={"left"}
                    backgroundColor={"#FFFFFF"}
                    color={"#3E4856"}
                    boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
                    >
                    <div className={Styles.titleWithTooltip}>
                        <Icon icon={'info-circle'} size={'16px'} color={'#556377'} />
                        <TabHeader
                            text="Total Pencairan"
                            column={'disbursement'}
                            sort={sort}
                            setSort={setSort}
                            isSorted
                        />
                    </div>
                </TooltipTab>
            ),
            hideOnMobile: true,
            renderData: (row) => (
                <div>
                    <span>{rupiahFormat(row?.disbursement)}</span>
                </div>
            ),
        },
        {
            name: 'tenor',
            align: 'left',
            title: <TabHeader text="Tenor" column={'tenor'} sort={sort} setSort={setSort} isSorted />,
            renderData: (row) => (
                <div>
                    <span>{row?.tenor} Bulan</span>
                </div>
            ),
        },
        {
            name: 'status',
            align: 'center',
            width: '15%',
            title: (
                <TabHeader
                    text="Status"
                    column={'status'}
                    sort={sort}
                    setSort={setSort}
                    isSorted
                    style={{ justifyContent: 'center', width: '100%' }}
                />
            ),
            renderData: (row) => (
                <div className={Styles.statusWrapper}>
                    <TagStatus text={row?.status} color={handleStatusType(row?.status)} />
                </div>
            ),
        },
    ], [sort]);
    
    const reimbursementColumns = useMemo(() => [
        {
            name: "applicationDate",
            width: "10%",
            title: 
                <TabHeader 
                    text="Tgl Pengajuan" 
                    column={"applicationDate"} 
                    sort={sort} 
                    setSort={setSort} 
                    isSorted
                />
        },
        {
            name: "category",
            width: "20%",
            hideOnMobile: true,
            title: 
                <TabHeader 
                    text="Kategori" 
                    column={"category"} 
                    sort={sort} 
                    setSort={setSort} 
                    isSorted
                />,
        },
        {
            name: "total",
            width: "20%",
            hideOnMobile: true,
            title: 
                <TabHeader 
                    text="Total" 
                    column={"total"} 
                    sort={sort} 
                    setSort={setSort} 
                    isSorted
                />,
        },
        {
            name: "information",
            width: "20%",
            hideOnMobile: true,
            title: 
                <TabHeader 
                    text="Keterangan" 
                    column={"information"} 
                    sort={sort} 
                    setSort={setSort} 
                    isSorted
                />,
        },
        {
            name: "photo",
            width: "10%",
            title: <TabHeader text="Foto" style={{justifyContent: "center", width: "100%"}} />,
            align: "center",
            hideOnMobile: true,
            renderData: (row) => (
                <div className={Styles.photoWrapper} onClick={() => {handleViewFile(row, 'photo')}}>
                    <Thumbnail src={row?.photo} alt={"goods_thumbnail"} height={"44px"} width={"66px"}/>
                </div>
            )
        },
        {
            name: "status",
            width: "20%",
            align: "center",
            title: 
                <TabHeader 
                    text="Status" 
                    column={"status"} 
                    sort={sort} 
                    setSort={setSort} 
                    isSorted
                    style={{justifyContent: "center", width: "100%"}}
                />,
            renderData: (row) => (
                <div className={Styles.photoWrapper}>
                    <TagStatus text={row?.status} color={handleStatusType(row?.status)}/>
                </div>
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [sort]);
    
    const handleColumns = useMemo(() => {
        switch(isActiveTabs){
            case 0:
                return payrollColumns
            case 1:
                return loanColumns
            case 2:
                return reimbursementColumns
            default:
                return payrollColumns

        }
    }, [isActiveTabs, payrollColumns, loanColumns, reimbursementColumns])

    return (
        <div className={Styles.content}>
            <div className={Styles.wrapperTabs}>
                <Tabs listTabs={tabs} isActive={isActiveTabs} setIsActive={setIsActiveTabs} />
                <div>
                    <Button
                        text="Unduh"
                        isOutlined
                        icon={'download'}
                        isLeftIcon
                        isDisabled={isChecked?.length > 0 ? false : true}
                        onClick={downloadTable}
                    />
                </div>
            </div>
            <div style={{ height: '90%' }}>
                <Table
                    className={Styles.tableRadius}
                    data={data}
                    columns={handleColumns}
                    page={page}
                    limit={limit}
                    totalData={data?.length}
                    totalPage={totalPage}
                    setLimit={setLimit}
                    setPage={setPage}
                    withPagination
                    withCheckbox
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    isLoading={isLoading}
                />
            </div>
            {error && (
                <div className={Styles.errorWrapper}>
                    {error}
                </div>
            )}
            {selectedEpayslip && (
                <ModalEpayslip
                    data={selectedEpayslip}
                    isOpen={selectedEpayslip}
                    onClose={() => handleShowEpayslip(null)}
                />
            )}
            {selectedLoan && (
                <ModalLoan
                    data={selectedLoan}
                    isOpen={selectedLoan}
                    onClose={() => handleShowLoan(null)}
                />
            )}
        </div>
    );
}