import Modal from "@Atom/Modal";
import { useDebounce } from "@Hooks/useDebounce";
import { SelectCard } from "@Organism/SelectClient";
import { getCorps } from "@Services/auth/getCorps";
import { useEffect, useState } from "react";

export default function SelectClientModal({
  show,
  onClose = () => { }
}) {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')


  const [isLoading, setIsLoading] = useState(true)

  const [packageClientList, setPackageClientList] = useState(null)
  const [error, setError] = useState('')

  const debouncedSearch= useDebounce(search, 500)


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const res = await getCorps(limit, page, debouncedSearch)
        setPackageClientList(res?.data?.response)
        setTimeout(()=> {
          setIsLoading(false)
        }, 500)
      } catch (err) {
        setError(err?.response?.data?.error || 'Something went wrong')
        setIsLoading(false)
      }
    }

    if(show){
      fetchData()
    }
  }, [limit, page, debouncedSearch, show])
  

  return (
    <Modal isOpen={show} onClose={onClose}>
      <SelectCard
        packageClientList={packageClientList}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        error={error}
        setError={setError}
        search={search}
        setSearch={setSearch}
        onSuccess={onClose}
        isModal
      />
    </Modal>
  )
}