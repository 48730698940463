import { API } from "configs";

export function getAllEmployee() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`/employee`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function getActiveEmployee(
  limit = 10,
  page = 1,
  search = "",
  corpID = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/employee/active?limit=${limit}&page=${page}&search=${search}&corpID=${corpID}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function getInActiveEmployee(
  limit = 10,
  page = 1,
  search = "",
  corpID = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(
        `/employee/inactive?limit=${limit}&page=${page}&search=${search}&corpID=${corpID}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

// for auto complete
export function getDataByNames(search = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.get(`/employee/fetch-by-name?name=${search}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
