/* eslint-disable no-unused-vars */
import { getMonths } from '@Assets/data/months'
import Icon from '@Atom/Icon'
import MonthButton from '@Atom/MonthButton'
import SelectDropdown from '@Atom/SelectDropdown'
import useQuery from '@Hooks/useQuery'
import PayrollProceedModal from '@Molecule/_modal/PayrollProceedModal'
import PayrollSelectDocNumberCard from '@Molecule/PayrollSelectDocNumberCard'
import { getPayrollScheme } from '@Services/payroll/getPayrollScheme'
import { getYearsArray } from 'helpers/getYearsArray'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BPJSSection from './BPJSSection'
import CalculationHistorySection from './CalculationHistorySection'
import IncomeSection from './IncomeSection'
import InfoSection from './InfoSection'
import Styles from './style.module.scss'

export default function PayrollSchemeLayout({
  pksList,
  isLoading = false
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [trigger, setTrigger] = useState('')
  const query = useQuery()
  const [year, setYear] = useState(query?.get('y') || new Date()?.getFullYear())
  const [isLoadingScheme, setIsLoadingScheme] = useState(false)
  const [schemeData, setSchemeData] = useState(null)

  const [proceedType, setProceedType] = useState('')


  const months = getMonths()

  const [month, setMonth] = useState(query?.get('m') || months[0])

  const monthIndex = useMemo(() => {
    return months?.indexOf(month)
  }, [month, months])

  const docOptions = useMemo(() => {

    return pksList?.map(obj => {
      return {
        id: obj?.pks_id,
        docType: 'PKS',
        // docType: obj?.pks_name,
        docNumber: obj?.pks_alias,
        flbNumber: obj?.pks_number,
      }
    })
  }, [pksList])


  const defaultSelectedDoc = useMemo(() => {
    if (query?.get('doc') && docOptions?.length) {
      // eslint-disable-next-line eqeqeq
      return docOptions?.find(obj => obj?.id == query?.get('doc'))
    }
  }, [docOptions, query])


  const [selectedDoc, setSelectedDoc] = useState(null)

  useEffect(() => {
    if (defaultSelectedDoc) {
      setSelectedDoc(defaultSelectedDoc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fixedMonth = useMemo(() => {
    return `${monthIndex + 1}`?.length === 1 ? `0${monthIndex + 1}` : monthIndex + 1
  }, [monthIndex])



  const fetchScheme = useCallback(async () => {
    try {
      setIsLoadingScheme(true)
      const { data } = await getPayrollScheme(selectedDoc?.id, year, fixedMonth)
      setSchemeData({
        ...data?.response,
        client: data?.response?.client_name,
        document: data?.response?.pks_number,
        payrollScheme: data?.response?.prm_schema_name,
        taxScheme: data?.response?.prm_tax_schema,
        taxPayer: data?.response?.prm_payer_tax
      })
      setIsLoadingScheme(false)
    } catch (err) {
      console.log(err, 'ini error')
      setIsLoadingScheme(false)
    }
  }, [fixedMonth, selectedDoc?.id, year])

  useEffect(() => {
    if (selectedDoc) {
      fetchScheme()
    }
  }, [fetchScheme, selectedDoc, trigger])
  const defaultBPJSData = useMemo(() => {
    return [
      {
        parameter: 'BPJS JKK - Company',
        description: schemeData?.prm_bpjs_jkk,
        rate: schemeData?.prm_rate_jkk,
        payer: schemeData?.prm_payer_bpjs_jkk,
        information: schemeData?.prm_bpjs_rules_jkk,
        multiplier: schemeData?.prm_multiplier_jkk,
        income: schemeData?.prm_income_plus_jkk_com,
        outcome: schemeData?.prm_income_min_jkk_com,
        thp: schemeData?.prm_thp_min_jkk_com
      },
      {
        parameter: 'BPJS JKM - Company',
        description: schemeData?.prm_bpjs_jkm,
        rate: schemeData?.prm_rate_jkm,
        payer: schemeData?.prm_payer_bpjs_jkm,
        information: schemeData?.prm_bpjs_rules_jkm,
        multiplier: schemeData?.prm_multiplier_jkm,
        income: schemeData?.prm_income_plus_jkm_com,
        outcome: schemeData?.prm_income_min_jkm_com,
        thp: schemeData?.prm_thp_min_jkm_com,
        backgroundColor: '#F0F0FF'
      },
      {
        parameter: 'BPJS JHT',
        description: schemeData?.prm_bpjs_jht,
        rate: '',
        payer: schemeData?.prm_payer_bpjs_jht,
        information: schemeData?.prm_bpjs_rules_jht,
        multiplier: schemeData?.prm_multiplier_jht,
        income: '',
        outcome: '',
        thp: '',
      },
      {
        parameter: '• Employee',
        description: '',
        rate: schemeData?.prm_rate_jht_employee,
        payer: '',
        information: '',
        multiplier: '',
        income: schemeData?.prm_income_plus_jht_tko,
        outcome: schemeData?.prm_income_min_jht_tko,
        thp: schemeData?.prm_thp_min_jht_tko,
        backgroundColor: '#FBFBFB'
      },
      {
        parameter: '• Company',
        description: '',
        rate: schemeData?.prm_rate_jht_com,
        payer: '',
        information: '',
        multiplier: '',
        income: schemeData?.prm_income_plus_jht_com,
        outcome: schemeData?.prm_income_min_jht_com,
        thp: schemeData?.prm_thp_min_jht_com,
        backgroundColor: '#FBFBFB'
      },
      {
        parameter: 'BPJS JP',
        description: schemeData?.prm_bpjs_jp,
        rate: '',
        payer: schemeData?.prm_payer_bpjs_jp,
        information: schemeData?.prm_bpjs_rules_jp,
        multiplier: schemeData?.prm_multiplier_jp,
        income: '',
        outcome: '',
        thp: '',
        backgroundColor: '#F0F0FF'
      },
      {
        parameter: '• Employee',
        description: '',
        rate: schemeData?.prm_rate_jp_employee,
        payer: '',
        information: '',
        multiplier: '',
        income: schemeData?.prm_income_plus_jp_tko,
        outcome: schemeData?.prm_income_min_jp_tko,
        thp: schemeData?.prm_thp_min_jp_tko,
        backgroundColor: '#F7F7FF'
      },
      {
        parameter: '• Company',
        description: '',
        rate: schemeData?.prm_rate_jp_com,
        payer: '',
        information: '',
        multiplier: '',
        income: schemeData?.prm_income_plus_jp_com,
        outcome: schemeData?.prm_income_min_jp_com,
        thp: schemeData?.prm_thp_min_jp_com,
        backgroundColor: '#F7F7FF'
      },
      {
        parameter: 'BPJS Kesehatan',
        description: schemeData?.prm_bpjs_ks,
        rate: '',
        payer: schemeData?.prm_payer_bpjs_ks,
        information: schemeData?.prm_bpjs_rules_ks,
        multiplier: schemeData?.prm_multiplier_ks,
        income: '',
        outcome: '',
        thp: ''
      },
      {
        parameter: '• Employee',
        description: '',
        rate: schemeData?.prm_rate_ks_employee,
        payer: '',
        information: '',
        multiplier: '',
        income: schemeData?.prm_income_plus_ks_tko,
        outcome: schemeData?.prm_income_min_ks_tko,
        thp: schemeData?.prm_thp_min_ks_tko,
        backgroundColor: '#FBFBFB'
      },
      {
        parameter: '• Company',
        description: '',
        rate: schemeData?.prm_rate_ks_com,
        payer: '',
        information: '',
        multiplier: '',
        income: schemeData?.prm_income_plus_ks_com,
        outcome: schemeData?.prm_income_min_ks_com,
        thp: schemeData?.prm_thp_min_ks_com,
        backgroundColor: '#FBFBFB'
      },
    ]
  }, [schemeData?.prm_bpjs_jht, schemeData?.prm_bpjs_jkk, schemeData?.prm_bpjs_jkm, schemeData?.prm_bpjs_jp, schemeData?.prm_bpjs_ks, schemeData?.prm_bpjs_rules_jht, schemeData?.prm_bpjs_rules_jkk, schemeData?.prm_bpjs_rules_jkm, schemeData?.prm_bpjs_rules_jp, schemeData?.prm_bpjs_rules_ks, schemeData?.prm_income_min_jht_com, schemeData?.prm_income_min_jht_tko, schemeData?.prm_income_min_jkk_com, schemeData?.prm_income_min_jkm_com, schemeData?.prm_income_min_jp_com, schemeData?.prm_income_min_jp_tko, schemeData?.prm_income_min_ks_com, schemeData?.prm_income_min_ks_tko, schemeData?.prm_income_plus_jht_com, schemeData?.prm_income_plus_jht_tko, schemeData?.prm_income_plus_jkk_com, schemeData?.prm_income_plus_jkm_com, schemeData?.prm_income_plus_jp_com, schemeData?.prm_income_plus_jp_tko, schemeData?.prm_income_plus_ks_com, schemeData?.prm_income_plus_ks_tko, schemeData?.prm_multiplier_jht, schemeData?.prm_multiplier_jkk, schemeData?.prm_multiplier_jkm, schemeData?.prm_multiplier_jp, schemeData?.prm_multiplier_ks, schemeData?.prm_payer_bpjs_jht, schemeData?.prm_payer_bpjs_jkk, schemeData?.prm_payer_bpjs_jkm, schemeData?.prm_payer_bpjs_jp, schemeData?.prm_payer_bpjs_ks, schemeData?.prm_rate_jht_com, schemeData?.prm_rate_jht_employee, schemeData?.prm_rate_jkk, schemeData?.prm_rate_jkm, schemeData?.prm_rate_jp_com, schemeData?.prm_rate_jp_employee, schemeData?.prm_rate_ks_com, schemeData?.prm_rate_ks_employee, schemeData?.prm_thp_min_jht_com, schemeData?.prm_thp_min_jht_tko, schemeData?.prm_thp_min_jkk_com, schemeData?.prm_thp_min_jkm_com, schemeData?.prm_thp_min_jp_com, schemeData?.prm_thp_min_jp_tko, schemeData?.prm_thp_min_ks_com, schemeData?.prm_thp_min_ks_tko])

  // const [bpjsData, setBPJSData] = useState(defaultBPJSData)



  useEffect(() => {
    // if (selectedDoc) {
    navigate(`${pathname}?doc=${selectedDoc?.id || ''}&y=${year}&m=${month}&t=${trigger}`, { replace: true })
    // }
  }, [month, navigate, pathname, selectedDoc, trigger, year])

  const defaultIncomes = useMemo(() => {
    return schemeData
      ?
      schemeData?.payroll_component?.map(obj => {
        return {
          ...obj,
          id: obj?.prm_id,
          parameter: obj?.component_name,
          description: obj?.component_function,
          information: obj?.prm_component_info,
          preventDelete: obj?.component_function === 'gapok',
          income: obj?.is_income_plus,
          outcome: obj?.is_income_min,
          thp: obj?.is_thp_min
        }
      })
      :
      []

  }, [schemeData])

  return (
    <div className={Styles.containerWrapper}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.top}>
            <h3>Payroll</h3>
            <SelectDropdown
              value={year}
              setValue={setYear}
              placeholder='Select year'
              options={getYearsArray(new Date()?.getFullYear(), 10)}
              height='auto'
            />
          </div>
          <div className={Styles.bottom}>
            <div className={`${Styles.buttonWrapper} ${Styles.left}`}>
              <button
                disabled={monthIndex === 0}
                onClick={() => setMonth(months[monthIndex - 1])}
              >
                <Icon icon={'arrow-down-4'} size={24} />
              </button>
            </div>
            <div className={Styles.months}>
              {
                months?.map((obj, i) => (
                  <MonthButton
                    key={i}
                    isActive={obj === month}
                    onClick={() => setMonth(obj)}
                  >
                    {obj} {year}
                  </MonthButton>
                ))
              }
            </div>

            <div className={`${Styles.buttonWrapper} ${Styles.right}`}>
              <button
                disabled={monthIndex === 11}
                onClick={() => setMonth(months[monthIndex + 1])}
              >
                <Icon icon={'arrow-down-4'} size={24} />
              </button>
            </div>

          </div>

        </div>

        {
          !selectedDoc
            ?
            <div className={Styles.empty}>
              <PayrollSelectDocNumberCard
                selectedDoc={selectedDoc}
                setSelectedDoc={setSelectedDoc}
                docOptions={docOptions}
                isLoading={isLoading}
              />
            </div>

            :
            <div className={Styles.content}>
              <div className={Styles.docSelector}>
                <SelectDropdown
                  value={selectedDoc?.docType + ' : ' + selectedDoc?.docNumber}
                  setValue={(newVal) => {
                    const found = docOptions?.find(obj => obj?.docType + ' : ' + obj?.docNumber === newVal)
                    setSelectedDoc(found)
                  }}
                  options={docOptions?.map(obj => obj?.docType + ' : ' + obj?.docNumber)}
                  placeholder='Select'
                  height='44px'
                />
                <div className={Styles.docNumber}>
                  <p>
                    <span>Nomor PKS</span>
                    <span>:</span>
                    <span onClick={() => window.open(`/flb/${selectedDoc?.flbNumber?.replaceAll('/', '::')?.replaceAll('\t', ':_:')}`, '_blank', 'rel=noopener noreferrer')}>{selectedDoc?.flbNumber}</span>
                  </p>
                  {/* <button onClick={() => window.open(`/flb/${selectedDoc?.flbNumber?.replaceAll('/', '::')?.replaceAll('\t', ':_:')}`, '_blank', 'rel=noopener noreferrer')}>Lihat Detail</button> */}
                </div>
              </div>

              <div className={Styles.sections}>
                <InfoSection schemeData={schemeData} isLoadingScheme={isLoadingScheme} />
                <BPJSSection bpjsData={defaultBPJSData} isLoadingScheme={isLoadingScheme} />
                <IncomeSection setTrigger={setTrigger} pksID={selectedDoc?.id} defaultIncomes={defaultIncomes} isLoadingScheme={isLoadingScheme} />
                {
                  !!schemeData?.batch?.length
                  &&
                  <CalculationHistorySection
                    history={schemeData?.batch}
                    fixedMonth={fixedMonth}
                    monthIndex={monthIndex}
                    year={year}
                    pksID={selectedDoc?.id}
                  />
                }
              </div>
              <div className={Styles.footer}>
                <div className={Styles.footerInnerWrapper}>
                  <span>{schemeData?.processed_data} Data diproses</span>
                  <div>
                    <button onClick={() => setProceedType('download')}>1. Download Template Payroll</button>
                    <button onClick={() => setProceedType('proceed')}>2. Kalkulasi Payroll</button>
                  </div>

                </div>
              </div>
            </div>

        }
      </div>
      {
        proceedType
        &&
        <PayrollProceedModal
          show={!!proceedType}
          type={proceedType}
          onClose={() => setProceedType('')}
          pksID={selectedDoc?.id}
          year={year}
          month={fixedMonth}
          monthIndex={monthIndex}
          pksAlias={selectedDoc?.docNumber}
          setTrigger={setTrigger}
        />
      }
    </div>
  )
}





