import CustomDatePicker from '@Atom/CustomDatePicker'
import Icon from '@Atom/Icon'
import BigCalendar from '@Molecule/BigCalendar'
import Images from '@Theme/Images'
import { fileBaseUrl } from 'configs'
import { get35DaysInMonth } from 'helpers/get35DaysInMonth'
import { isDateBetween } from 'helpers/isDateBetween'
import { typeAttendace } from 'helpers/typeAttendace'
import moment from 'moment'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function AttendanceDetailsLayout({data, dataPersonal, valueDate, setValueDate}) {
  const navigate = useNavigate()

  const attendanceData = useMemo(() => {
    const allDays = get35DaysInMonth(new Date(valueDate))
    
    const resultAttend = allDays?.map((obj) => {
      const currentAttend = data?.attendanceData?.find((d) => isDateBetween(obj, d?.startTime, d?.endTime || d?.startTime));
      if (currentAttend) {
          return {
              ...currentAttend,
              date: moment(obj)?.format('YYYY-MM-DD'),
              value: typeAttendace(currentAttend?.type || ''),
          };
      } else {
        return {
            date: moment(obj)?.format('YYYY-MM-DD'),
            value: '',
        };
      }
    });

    return resultAttend
  }, [data?.attendanceData, valueDate])

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Icon
          icon={'arrow-chevron-left'}
          style={{ cursor: 'pointer' }}
          size={20}
          onClick={() => navigate(-1)}
        />
        <h3>Detail Absensi -<CustomDatePicker type="month-year" value={valueDate} setValue={setValueDate} right='0' className={Styles.customDatePickerStyle} iconSize={24} padding='8px 1px'/>
        </h3>
      </div>
      <div className={Styles.content}>
        <div className={Styles.cHeader}>
          <div className={Styles.profile}>
            <img src={dataPersonal?.imageURL ? fileBaseUrl + dataPersonal.imageURL : Images.AVA_DEFAULT} alt='' />
            <div className={Styles.desc}>
              <span>{dataPersonal?.name}</span>
              <p>{dataPersonal?.contracts?.[0].position}</p>
              <div className={`${Styles.status} ${Styles[dataPersonal?.status?.toLowerCase()]}`}>
                {dataPersonal?.status}
              </div>
            </div>
          </div>
          <div className={Styles.totalAttendance}>
            <div className={Styles.titleWrapper}>
              <span>Total</span>
              <span>Kehadiran</span>
            </div>
            <div className={Styles.countWrapper}>
              {data?.attendanceCounts?.find((f) => f.type === "HADIR")?.count || 0}
            </div>
          </div>
          {/* <div className={Styles.divider} /> */}
          {/* <div className={Styles.legends}>
            {
              [
                {
                  title: 'Revisi Absen',
                  bgColor: '#fff',
                  borderColor: '#000',
                  value: data?.totalRevisions
                },
                {
                  title: 'Hadir',
                  bgColor: '#F4FFF2',
                  borderColor: '#22970F',
                  value: data?.totalAttendances
                },
                {
                  title: 'Sakit',
                  bgColor: '#FEEDFF',
                  borderColor: '#DB2BE5',
                  value: data?.totalSicks
                },
                {
                  title: 'Cuti',
                  bgColor: '#FFFCE6',
                  borderColor: '#EED202',
                  value: data?.totalLeaves
                },
                {
                  title: 'Izin',
                  bgColor: '#F0F0FF',
                  borderColor: '#2E3192',
                  value: data?.totalPermits
                },
                {
                  title: 'Absen',
                  bgColor: '#FFF4F2',
                  borderColor: '#D42701',
                  value: data?.totalAbsences
                },
              ]
                ?.map((t, tI) => (
                  <div key={tI} style={{ backgroundColor: t?.bgColor, borderColor: t?.borderColor }}>
                    <span>{t?.title}</span>
                    <span>{t?.value?.toString()?.length === 1 ? '0' : ''}{t?.value}</span>
                  </div>
                ))
            }
          </div> */}
        </div>

        <div className={Styles.cContent}>
          <BigCalendar attendances={attendanceData} />
        </div>
      </div>
    </div>
  )
}