import ListEmployee from "@Organism/Employee/ListEmployee";
import {
  getActiveEmployee,
  getInActiveEmployee,
} from "@Services/employee/getAllEmployee";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import SkeletonListEmployee from "@Molecule/Skeleton/Employee/ListEmployee";

export const loader = (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const limit = params?.get("limit") || 10;
  const page = params?.get("page") || 1;
  const searchQuery = params?.get("search") || "";
  const corpID = localStorage.getItem("corpID");
  const fetchDataActiveEmployee = getActiveEmployee(
    limit,
    page,
    searchQuery,
    corpID
  );
  const fetchDataInActiveEmployee = getInActiveEmployee(
    limit,
    page,
    searchQuery,
    corpID
  );

  return defer({
    getDataActiveEmployee: fetchDataActiveEmployee,
    getDataInActiveEmployee: fetchDataInActiveEmployee,
  });
};
export default function ListEmployeePage() {
  const { getDataActiveEmployee, getDataInActiveEmployee } = useLoaderData();
  return (
    <Suspense fallback={<SkeletonListEmployee />}>
      <Await resolve={getDataActiveEmployee} errorElement={<div>error</div>}>
        {(valueActiveEmployee) => (
          <Suspense fallback={<p>Loading....</p>}>
            <Await
              resolve={getDataInActiveEmployee}
              errorElement={<div>error</div>}
            >
              {(valueInActiveEmployee) => (
                <div style={{ width: "100%" }}>
                  <ListEmployee
                    dataActiveEmployee={valueActiveEmployee}
                    dataInActiveEmployee={valueInActiveEmployee}
                    isEmpty={
                      valueActiveEmployee?.data?.isEmpty &&
                      valueInActiveEmployee?.data?.isEmpty
                    }
                  />
                </div>
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
}
