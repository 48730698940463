export function typeAttendace(type) {
    switch (true) {
        case type?.includes('HADIR'):
            return 'attend';
        case type?.includes('SAKIT'):
            return 'sick';
        case type?.includes('CUTI'):
            return 'leave';
        case type?.includes('IZIN'):
            return 'permit';
        case type?.includes('ABSEN'):
            return 'absence';
        default:
            return '';
    }
}