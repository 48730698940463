import Icon from '@Atom/Icon'
import Modal from '@Atom/Modal'
import Styles from './style.module.scss'
import { useMemo, useState } from 'react'
import DatePicker from '@Atom/DatePicker'
import Input from '@Atom/Input'
import RadioButton from '@Atom/RadioButton'

export default function FeedbackFilterSlider({
  defaultDate,
  defaultHandler,
  defaultStatus,
  defaultStage,
  onSave = () => { },
  show = false,
  onClose = () => { }
}) {
  const [date, setDate] = useState('')
  const [handler, setHandler] = useState('')
  const dropdownOptions = useMemo(() => {
    return []
  }, [])

  const template = useMemo(() => {
    return [
      {
        title: 'Tanggal Ticket Diterima',
        component: <DatePicker
          value={date}
          setValue={setDate}
          placeholder='Pilih tanggal'
        />
      },
      {
        title: 'Penanggung Jawab',
        component: <Input
          value={handler}
          setValue={setHandler}
          isDropdown
          dropdownOptions={dropdownOptions}
          placeholder='Cari penanggung jawab'
        />
      },
      {
        title: 'Status',
        component: <div className={Styles.statuses}>
          {
            [
              {
                title: 'Status Open',
                value: 'OPEN'
              },
              {
                title: 'Status Closed',
                value: 'CLOSED'
              },
            ]
              ?.map((obj, i) => (
                <div key={i} className={Styles.status}>
                  <RadioButton />
                  <span>{obj?.title}</span>
                </div>
              ))
          }
        </div>
      },
      {
        title: 'Stage',
        component: <div className={Styles.stages}>
          {
            [

              {
                title: 'Ticket dari departemen lain',
                icon: <Icon color={'#22CFBA'} icon={'document-previous'} size={20} />
              },
              {
                title: 'Diteruskan ke departemen lain',
                icon: <Icon color={'#2E3192'} icon={'document-forward'} size={20} />
              },
              {
                title: 'Ticket dengan pelanggaran SLA',
                icon: <Icon color={'#D42701'} icon={'flag'} size={20} />
              },
              {
                title: 'Ticket perlu dibalas',
                icon: <Icon color={'#22970F'} icon={'message'} size={20} />
              },
            ]
              ?.map((stage, i) => (
                <div key={i} className={Styles.stage}>
                  <RadioButton />
                  {stage?.icon}
                  <span>{stage?.title}</span>
                </div>
              ))
          }
        </div>
      }
    ]
  }, [date, dropdownOptions, handler])

  return (
    <Modal isOpen={show} onClose={onClose} isSlider>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Filter</h3>
          <button onClick={onClose}>
            <Icon icon={'close-circle'} size={20} />
          </button>
        </div>
        <div className={Styles.content}>
          {
            template?.map((item, i) => (
              <div key={i}>
                <span>{item?.title}</span>
                {item?.component}
              </div>
            ))
          }
        </div>
        <div className={Styles.actions}>
          <button>Terapkan</button>
          <button>Reset</button>
        </div>
      </div>
    </Modal>
  )
}