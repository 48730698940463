import Styles from './style.module.scss'

export default function InputTitle({
  title = '',
  required = false,
  requiredLabel = '*'
}) {

  return (
    <div className={Styles.container}>
      <span>{title}</span>
      <p>{required ? requiredLabel : ''}</p>
    </div>
  )
}