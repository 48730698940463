import { API365 } from "configs";

export function getFLBDetail(docNumber, clientCode = localStorage?.getItem('clientCode')) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API365.get(`/order/flb-for-payroll/${clientCode}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: { docNumber }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}